import { betsSlipConstants } from './constants';
import { getBetsState } from '../selectors/betData';
//import { makeGetMatches } from "../selectors/betData";
import { cloneDeep } from 'lodash-es';

export const betsSlipLoad = () => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.LOAD,
    stateReady: bst.prematch.fullStateLoaded && bst.live.fullStateLoaded,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipAdd = (mType, idSport, idMatch, idBet, idMb, idBo, idMbo, match) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.ADD,
    mType,
    idSport,
    idMatch,
    idBet,
    idMb,
    idBo,
    idMbo,
    match,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipRemove = (mType, idMbo) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.REMOVE,
    mType,
    idMbo,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipToggle =
  (mType, idSport, idMatch, idBet, idMb, idBo, idMbo, brmId, shareTicketNumber) => (dispatch, getState) => {
    const state = getState();
    const bst = getBetsState(state);

    let m = null;

    if (mType === 'live') {
      if (idMatch in bst.live.matches) {
        m = bst.live.matches[idMatch];
      }
    } else {
      if (idMatch in bst.prematch.matches) {
        m = bst.prematch.matches[idMatch];
      }
    }

    if (m === null) {
      return;
    }

    const cm = cloneDeep(m);

    //console.log("match", match, "m", m, "cm", cm);

    dispatch({
      type: betsSlipConstants.TOGGLE,
      mType,
      idSport,
      idMatch,
      idBet,
      idMb,
      idBo,
      idMbo,
      brmId,
      shareTicketNumber,
      match: cm,
      matches: {
        live: bst.live.matches,
        prematch: bst.prematch.matches,
      },
      combiningAllowed: bst.nsoft.combiningAllowed,
      state,
    });
  };

export const betsSlipClear = () => ({
  type: betsSlipConstants.CLEAR,
});

export const betsSlipStakeInc = (stake) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.STAKE_INC,
    stake,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipStakeDec = (stake) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.STAKE_DEC,
    stake,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipStakeSet = (stake) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.STAKE_SET,
    stake,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipComputeWin = () => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.COMPUTE_WIN,
    dontResetError: true,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipToggleFixed = (mType, idMbo) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.TOGGLE_FIXED,
    mType,
    idMbo,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipToggleSystem = (mType, system, provider) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.TOGGLE_SYSTEM,
    mType,
    system,
    provider,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipAcceptOddChange = (mType, idMbo) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.ACCEPT_ODD_CHANGE,
    mType,
    idMbo,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

const dispatchWithMatches = (obj) => {
  return (dispatch, getState) => {
    const state = getState();
    const bst = getBetsState(state);

    obj.matches = {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    };
    obj.state = state;

    dispatch(obj);
  };
};

export const betsSlipToggleAutoAcceptOddChange = () =>
  dispatchWithMatches({
    type: betsSlipConstants.TOGGLE_AUTO_ACCEPT_ODD_CHANGE,
  });

export const betsSlipToggleAllowTicketPlaceWithInvalidEvents = () =>
  dispatchWithMatches({
    type: betsSlipConstants.TOGGLE_ALLOW_TICKET_PLACE_WITH_INVALID_EVENTS,
  });

export const betsSlipPrematchCreateTicketSuccess = (code) => ({
  type: betsSlipConstants.PREMATCH_CREATE_TICKET_SUCCESS,
  code,
});

export const betsSlipPrematchCreateTicketReoffer = (data) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.PREMATCH_CREATE_TICKET_REOFFER,
    data,
    mType: 'prematch',
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipPrematchCreateTicketError = (error, details) => ({
  type: betsSlipConstants.PREMATCH_CREATE_TICKET_ERROR,
  error,
  details,
});

export const betsSlipLiveCreateTicketSuccess = (code) => ({
  type: betsSlipConstants.LIVE_CREATE_TICKET_SUCCESS,
  code,
});

export const betsSlipLiveCreateTicketPending = (index, requestUuid) => ({
  type: betsSlipConstants.LIVE_CREATE_TICKET_PENDING,
  index,
  requestUuid,
});

export const betsSlipLiveCreateTicketSettle = (data) => ({
  type: betsSlipConstants.LIVE_CREATE_TICKET_SETTLE,
  data,
});

export const betsSlipLiveCreateTicketError = (error, details) => ({
  type: betsSlipConstants.LIVE_CREATE_TICKET_ERROR,
  error,
  details,
});

export const betsSlipSetTicketType = (ticketType) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.SET_TICKET_TYPE,
    ticketType,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipSetTicketOnline = (online) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.SET_TICKET_ONLINE,
    online,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipAddLottoTicket = (ticket) => ({
  type: betsSlipConstants.LOTTO_ADD_TICKET,
  ticket,
});

export const betsSlipClearLottoTicket = () => ({
  type: betsSlipConstants.LOTTO_CLEAR_TICKET,
});

export const betsSlipClearCreateStatus = () => ({
  type: betsSlipConstants.CLEAR_CREATE_STATUS,
});

export const betsSlipPrematchEvalTicket = (index) => ({
  type: betsSlipConstants.PREMATCH_EVAL_TICKET,
  index,
});

export const betsSlipPrematchEvalTicketStr = (evalStr, index) => ({
  type: betsSlipConstants.PREMATCH_EVAL_TICKET_STR,
  evalStr,
  index,
});

export const betsSlipPrematchEvalTicketData = (data, index) => ({
  type: betsSlipConstants.PREMATCH_EVAL_TICKET_DATA,
  data,
  index,
});

export const betsSlipSetCurrentTicket = (index) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.SET_CURRENT_TICKET,
    index,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipCopyCurrentTicket = (switchToTab) => ({
  type: betsSlipConstants.COPY_CURRENT_TICKET,
  switchToTab,
});

export const betsSlipClearCopyStatus = () => ({
  type: betsSlipConstants.CLEAR_COPY_STATUS,
});

export const betsBonusEvaluateResponse = (bonusEvaluate) => ({
  type: betsSlipConstants.BONUS_EVALUATE_RESPONSE,
  bonusEvaluate,
});

export const betsBonusRequestEvaluation = (force) => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: betsSlipConstants.REQUEST_BONUS_EVALUATION,
    wallet: state.wallet,
    profile: state.profile,
    authentication: state.authentication,
    force: force ? true : false,
    state,
  });
};

export const betsWinnerFunEvaluateResponse = (winnerFunEvaluate) => ({
  type: betsSlipConstants.WINNER_FUN_EVALUATE_RESPONSE,
  winnerFunEvaluate,
});

export const betsFreeBetsEvaluateResponse = (freeBetsEvaluate) => ({
  type: betsSlipConstants.FREE_BET_EVALUATE_RESPONSE,
  freeBetsEvaluate,
});

export const betsTournamentEvaluateResponse = (tournamentEvaluate) => ({
  type: betsSlipConstants.TOURNAMENT_EVALUATE_REQUEST,
  tournamentEvaluate,
});

export const betsSlipToggleWinnerFunBoost = (force) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.TOGGLE_WINNER_FUN_BOOST,
    force,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betSlipSetFreeBetIndex = (index, freeBet, subIndex) => ({
  type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
  index: index,
  freeBet: freeBet,
  subIndex: subIndex,
});

export const betSlipFreeBetClear = () => ({
  type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
  index: -1,
  freeBet: null,
  subIndex: -1,
});

export const betsSlipPrematchMatchesLoaded = (idRequest) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.PREMATCH_MATCHES_LOADED,
    idRequest,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsDigitainBonusEvaluateResponse = (bonusEvaluate) => ({
  type: betsSlipConstants.DIGITAIN_BONUS_EVALUATE_RESPONSE,
  bonusEvaluate,
});

export const betsSlipLoadMultiBetDay = (dmb) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.DIGITAIN_LOAD_MULTI_BET_DAY,
    dmb,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipSetMultiBetDayNumber = (dmbNumber) => ({
  type: betsSlipConstants.DIGITAIN_SET_MULTI_BET_DAY_NUMBER,
  dmbNumber,
});

export const betsSlipSetBetBuilderBets = (idMatch, mType, bets, bbInfo) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  let m = null;

  if (mType === 'live') {
    if (idMatch in bst.live.matches) {
      m = bst.live.matches[idMatch];
    }
  } else {
    if (idMatch in bst.prematch.matches) {
      m = bst.prematch.matches[idMatch];
    }
  }

  if (m === null) {
    return;
  }

  const cm = cloneDeep(m);

  //console.log("match", match, "m", m, "cm", cm);

  dispatch({
    type: betsSlipConstants.DIGITAIN_SET_BET_BUILDER_BETS,
    mType,
    idMatch,
    bets,
    bbInfo,
    match: cm,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipSetForceShow = (value) => ({
  type: betsSlipConstants.FORCE_SHOW_BETSLIP,
  value,
});

export const betsSlipComputeStakeTax = (totalAmount, taxableAmount) => ({
  type: betsSlipConstants.COMPUTE_STAKE_TAX,
  totalAmount,
  taxableAmount,
});

export const betsSlipSetStakeTax = (totalAmount, taxableAmount, taxedStake, stakeTax) => (dispatch, getState) => {
  const state = getState();
  const bst = getBetsState(state);

  dispatch({
    type: betsSlipConstants.SET_STAKE_TAX,
    totalAmount,
    taxableAmount,
    taxedStake,
    stakeTax,
    matches: {
      live: bst.live.matches,
      prematch: bst.prematch.matches,
    },
    state,
  });
};

export const betsSlipComputeWinTax = (maxAmount, nonTaxableMaxAmount, minAmount, nonTaxableMinAmount) => ({
  type: betsSlipConstants.COMPUTE_WIN_TAX,
  maxAmount,
  nonTaxableMaxAmount,
  minAmount,
  nonTaxableMinAmount,
});

export const betsSlipSetWinTax =
  (maxAmount, taxedMaxAmount, maxTax, minAmount, taxedMinAmount, minTax) => (dispatch, getState) => {
    const state = getState();
    const bst = getBetsState(state);

    dispatch({
      type: betsSlipConstants.SET_WIN_TAX,
      maxAmount,
      taxedMaxAmount,
      maxTax,
      minAmount,
      taxedMinAmount,
      minTax,
      matches: {
        live: bst.live.matches,
        prematch: bst.prematch.matches,
      },
      state,
    });
  };
