import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import moment from 'moment';
import './index.scss';

import {
  clearLoadedStates,
  requestCancelVivaWithdrawal,
  requestCancelWithdrawal,
  requestVerificationCode,
} from '../../../modules/casino/store/actions';
import { shallowEqual } from 'react-redux';
import { getTax, requestAllWithdrawals, requestCancelWithdrawalNew } from '@/modules/casino/store/actions/withdrawals';
import { useTranslation } from 'react-i18next';
import { TAX_TYPE_WITHDRAW } from '@/constants/paymentProvider';

type PendingWithdrawalsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type Withdrawal = {
  id: number;
  transaction_id: string;
  status_id: number;
  amount: number;
  date: number;
  location_id: number;
  cancelled: any[];
  src: string;
  layoutType: string;
  withdrawType: string;
};

type VerificationData = {
  remainingMinutes: number;
  remainingSeconds: number;
  validation_code: number;
  validation_code_expire: number;
};

type PendingWithdrawalState = {
  pendingWithdrawals: Withdrawal[];
  approvedWithdrawals: Withdrawal[];
  historyWithdrawals: Withdrawal[];
  verificationData: VerificationData[];
  locations: Agency[];
  loaded: boolean;
  loadedViva: boolean;
  loadedHistory: boolean;
  loadingVerification: boolean;
  loadingTax: boolean;
  taxes: {
    [withdrawal_id: string]: {
      amount: number;
      tax: number;
    };
  };
};

const PENDING = '1';
const APPROVED = '2';
const PROCESSED = '4';
const MIN_WITHDRAWAL = 20;

const TAX_WITHDRAWAL = 1;
const TAX_DEPOSIT = 2;

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PendingWithdrawals = (componentProps: PendingWithdrawalsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const {
    pendingWithdrawals,
    approvedWithdrawals,
    historyWithdrawals,
    verificationData,
    locations,
    loaded,
    loadedViva,
    loadedHistory,
    loadingVerification,
    loadingTax,
    taxes,
  } = useAppSelector<PendingWithdrawalState>((state) => {
    return {
      pendingWithdrawals: state.withdrawals.pending,
      approvedWithdrawals: state.withdrawals.approved,
      historyWithdrawals: state.withdrawals.history,
      verificationData: state.withdrawals.verificationData,
      locations: state.withdrawals.locations,
      loaded: state.withdrawals.loaded,
      loadedViva: state.withdrawals.loadedViva,
      loadedHistory: state.withdrawals.loadedHistory,
      loadingVerification: state.withdrawals.loadingVerification,
      loadingTax: state.withdrawals.loadingTax,
      taxes: state.withdrawals.taxes.transactions,
    };
  }, shallowEqual);

  const currentCurrency = useAppSelector((state) => state.currencies.currentCurrency);

  const [confirmation, setConfirmation] = React.useState<{
    open: boolean;
    openPartial: boolean;
    id: any;
    amount: number;
    partialAmount: number;
    minWithdrawal: number;
    type: string;
    withdrawType: string;
    src: string;
  }>({
    open: false,
    openPartial: false,
    id: null,
    amount: 0,
    partialAmount: 0,
    minWithdrawal: MIN_WITHDRAWAL,
    type: '',
    withdrawType: '',
    src: '',
  });

  const [amountButton, setAmountButton] = React.useState(0);

  React.useEffect(() => {
    dispatch(requestAllWithdrawals({ cooldown: false }));
    // dispatch(requestLocations());
    moment.locale(i18n.language);

    return () => {
      dispatch(clearLoadedStates());
    };
  }, []);

  React.useEffect(() => {
    if (loaded) {
      taxHandler(approvedWithdrawals);
    }
  }, [loaded && approvedWithdrawals]);

  React.useEffect(() => {
    if (loadedViva) {
      taxHandler(pendingWithdrawals);
    }
  }, [loadedViva && pendingWithdrawals]);

  React.useEffect(() => {
    if (loadedHistory) {
      taxHandler(historyWithdrawals);
    }
  }, [loadedHistory && historyWithdrawals]);

  const taxHandler = (withdrawals: any) => {
    if (withdrawals?.length > 0) {
      const reqTax:any = [];
      withdrawals.map((w: Withdrawal) => {
        if (w.amount > 0 && (w.status_id.toString() === PENDING || w.status_id.toString() === APPROVED)) {
          if (!taxes[w.id] || taxes[w.id].amount !== w.amount) {
            reqTax.push({
              taxType:TAX_TYPE_WITHDRAW,
              totalAmount: w.amount * 100,
              key: w.id
            });
          }
        }
      });
      if (reqTax.length > 0) {
          dispatch(getTax(reqTax));
      }
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.id) {
      const id = parseInt(e.currentTarget.dataset.id);
      const status_id = e.currentTarget.dataset.status_id;
      let withdrawal: any = [];
      if (status_id === APPROVED || status_id === PENDING) {
        // @ts-ignore
        withdrawal = approvedWithdrawals.find((wd) => id === parseInt(wd.id));
        if (!withdrawal) {
          // @ts-ignore
          withdrawal = pendingWithdrawals.find((wd) => id === parseInt(wd.id));
          if (!withdrawal) {
            // @ts-ignore
            withdrawal = historyWithdrawals.find((wd) => id === parseInt(wd.id));
          }
        }
      }

      if (withdrawal) {
        setConfirmation({
          id: withdrawal.id,
          open: true,
          openPartial: false,
          amount: withdrawal.amount,
          partialAmount: 0,
          minWithdrawal: MIN_WITHDRAWAL,
          type: '',
          withdrawType: withdrawal.withdrawType ?? '',
          src: withdrawal.src,
        });
      }
    }
  };

  const onPartial = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.id) {
      const id = parseInt(e.currentTarget.dataset.id);
      const status_id = e.currentTarget.dataset.status_id;
      let withdrawal: any = [];
      if (status_id === APPROVED || status_id === PENDING) {
        // @ts-ignore
        withdrawal = approvedWithdrawals.find((wd) => id === parseInt(wd.id));
        if (!withdrawal) {
          // @ts-ignore
          withdrawal = pendingWithdrawals.find((wd) => id === parseInt(wd.id));
          if (!withdrawal) {
            // @ts-ignore
            withdrawal = historyWithdrawals.find((wd) => id === parseInt(wd.id));
          }
        }
      }

      if (withdrawal) {
        setConfirmation({
          id: withdrawal.id,
          open: false,
          openPartial: true,
          amount: withdrawal.amount,
          partialAmount: 0,
          minWithdrawal: MIN_WITHDRAWAL,
          type: 'partial',
          withdrawType: withdrawal.withdrawType ?? '',
          src: withdrawal.src,
        });
      }
    }
  };

  const handleApprovedConfirmation = () => {
    let amount = confirmation.amount;

    if (confirmation.type === 'partial' && confirmation.partialAmount >= MIN_WITHDRAWAL) {
      amount = confirmation.partialAmount;
    } else if (confirmation.type === 'partial' && confirmation.partialAmount < MIN_WITHDRAWAL) {
      return;
    }

    if (confirmation.src === 'pending-viva-withdrawals') {
      dispatch(requestCancelVivaWithdrawal(amount, confirmation.id));
    } else if (confirmation.withdrawType) {
      dispatch(requestCancelWithdrawalNew(amount * 100, confirmation.id, confirmation.withdrawType));
    } else {
      dispatch(requestCancelWithdrawal(amount, confirmation.id));
    }

    handleCloseConfirmation();
  };

  const handleCloseConfirmation = () => {
    setConfirmation({
      open: false,
      openPartial: false,
      id: null,
      amount: 0,
      partialAmount: 0,
      minWithdrawal: MIN_WITHDRAWAL,
      type: '',
      withdrawType: '',
      src: '',
    });
  };

  const onRequestVerificationCode = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.dataset.id;
    if (id) {
      dispatch(requestVerificationCode(id));
    }
  };

  const getCurrentLocation = (location_id: number | void) => {
    if (locations && location_id) {
      for (const location of locations) {
        if (location.id === location_id) {
          return location.locationName;
        }
      }
    }
    return 'Online Withdrawal';
  };

  const currencyFormatter = (value: number) => {
    return value.toFixed(2).replace('.', ',');
  };

  const onChangePartialAmount = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.target ? parseInt((e.target as HTMLInputElement).value, 10) : 0;
    const amount = value > 0 ? (value > confirmation.amount ? confirmation.amount : value) : 0;
    setConfirmation({
      ...confirmation,
      partialAmount: amount,
    });
  };

  const onAmountButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLButtonElement);
    // get data-value from button
    const value = el.getAttribute('data-value') ? Number(el.getAttribute('data-value')) : 0;
    if (value) {
      setAmountButton(value);
      setConfirmation((v: any) => {
        let amount: number = value > 0 ? (value > confirmation.amount ? confirmation.amount : value) : 0;
        if (window.config?.depositSettings?.presetsType === 'incremental') {
          amount =
            Number(v.partialAmount) + Number(value) > confirmation.amount
              ? confirmation.amount
              : Number(v.partialAmount) + Number(value);
        }
        return {
          ...confirmation,
          partialAmount: amount,
        };
      });
    }
  };

  const getAmountButtonValues = () => {
    const amountValues: number[] = window?.config?.depositSettings?.defaultPresets[currentCurrency] ?? [];
    const buttons: GenericAmountButton[] = [];
    if (amountValues.length > 0) {
      amountValues.map((value, index) => {
        if (value <= confirmation.amount) {
          buttons.push({
            value: value,
            valueRendered:
              window.config?.depositSettings?.presetsType === 'incremental'
                ? `+${value}`
                : `${value} ${currentCurrency}`,
            id: index,
            onClickHandler: onAmountButtonClick,
            active: amountButton === value,
          });
        }
      });
    }
    return buttons;
  };

  const withdrawalsHandler = (withdrawals: Withdrawal[]) => {
    if (withdrawals) {
      const formattedWithdrawals = withdrawals.map((fw, i) => {
        if (fw.amount > 1 && (fw.status_id.toString() === APPROVED || fw.status_id.toString() === PENDING)) {
          const withdrawal: any = {
            ...fw,
            id: fw.id.toString(),
            date: moment.unix(fw.date).format('DD MMM YYYY - HH:mm'),
            timestamp: fw.date,
            location: getCurrentLocation(fw.location_id),
            tax: taxes && taxes[fw.id] != null ? currencyFormatter(taxes[fw.id].tax) : 0,
            onPartial,
            onCancel,
            onRequestVerificationCode,
            loadingVerification: loadingVerification,
            loadingTax: loadingTax,
            minWithdrawal: MIN_WITHDRAWAL,
          };

          if (verificationData && verificationData[fw.id] != null) {
            withdrawal.verificationCode = verificationData[fw.id].validation_code;
            withdrawal.verificationCodeExpiration =
              verificationData[fw.id].remainingMinutes <= 0
                ? verificationData[fw.id].remainingSeconds
                : verificationData[fw.id].remainingMinutes;
            withdrawal.verificationCodeExpirationUnit = verificationData[fw.id].remainingMinutes <= 0 ? 'sec' : 'min';
          }
          withdrawal.currentCurrency = currentCurrency ?? (window.config.defaultCurrency ?? 'RON');
          return withdrawal;
        }
      });

      return formattedWithdrawals.filter(Boolean);
    }
    return [];
  };

  const generateAllWithdrawals = () => {
    const approved = withdrawalsHandler(approvedWithdrawals);
    const history = withdrawalsHandler(historyWithdrawals);
    const all: any[] = [];

    approved.forEach((item) => {
      all.push({
        ...item,
        layoutType: 'approved',
      });
    });

    history.forEach((item) => {
      all.push({
        ...item,
        layoutType: 'history',
      });
    });

    return all.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
  };

  const contextValue = {
    approvedWithdrawals: withdrawalsHandler(approvedWithdrawals),
    pendingWithdrawals: withdrawalsHandler(pendingWithdrawals),
    historyWithdrawals: withdrawalsHandler(historyWithdrawals),
    allWithdrawals: generateAllWithdrawals(),
    showConfirmation: confirmation.open,
    showPartialConfirmation: confirmation.openPartial,
    confirmationID: confirmation.id,
    handleCloseConfirmation: handleCloseConfirmation,
    handleApprovedConfirmation: handleApprovedConfirmation,
    confirmationAmount: confirmation.amount ? currencyFormatter(confirmation.amount) : 0,
    partialAmount: confirmation.partialAmount ? (confirmation.partialAmount + '').replace(/^0+/, '') : 0,
    loadedPending: loaded,
    loadedPendingViva: loadedViva,
    loadedPendingHistory: loadedHistory,
    onChangePartialAmount: (e: React.FormEvent<HTMLInputElement>) => onChangePartialAmount(e),
    amountButtonValues: getAmountButtonValues(),
    minWithdrawal: MIN_WITHDRAWAL,
    currentCurrency: currentCurrency ?? (window.config.defaultCurrency ?? 'RON'),
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PendingWithdrawals;
