import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import moment from 'moment';

import './index.scss';
import { cloneDeep } from 'lodash-es';

type ChangeProfileDetailsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ProfileState = {
  isOpen: boolean;
  hasChanges: boolean;
  loading: boolean;
  errorCode: string | boolean;
  oldState: {
    address: any;
    birthday: any;
    email: any;
    first_name: any;
    last_name: any;
    nin?: any;
    phone: any;
  };
  newState: {
    // isOpen: boolean;
    address: any;
    birthday: any;
    email: any;
    first_name: any;
    last_name: any;
    nin?: any;
    phone: any;
  };

  changedFields: {
    address: boolean;
    birthday: boolean;
    email: boolean;
    first_name: boolean;
    last_name: boolean;
    nin: boolean;
    phone: boolean;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ChangeProfileDetails = (componentProps: ChangeProfileDetailsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const { profile, authenticated } = useAppSelector((state) => ({
    authenticated: (state.authentication && state.authentication.auth_type === 'user') as boolean,
    profile: state.profile,
  }));
  const navigate = useNavigate();

  const initialState = {
    isOpen: false,
    hasChanges: false,
    errorCode: false,
    loading: profile.loading ?? false,
    oldState: {
      address: '',
      birthday: '',
      email: '',
      first_name: '',
      last_name: '',
      nin: '',
      phone: '',
    },

    newState: {
      address: '',
      birthday: '',
      email: '',
      first_name: '',
      last_name: '',
      nin: '',
      phone: '',
    },

    changedFields: {
      address: false,
      birthday: false,
      email: false,
      first_name: false,
      last_name: false,
      nin: false,
      phone: false,
    },
  };

  const [state, setState] = React.useState<ProfileState>(initialState);

  React.useEffect(() => {
    if (!profile.loading) {
      setState((prevState) => ({
        ...prevState,
        newState: {
          ...prevState.newState,
          address: profile.address ?? '',
          birthday: profile.birthday ? moment.unix(profile.birthday).format('DD/MM/YYYY') : '',
          email: profile.email ?? '',
          first_name: profile.first_name ?? '',
          last_name: profile.last_name ?? '',
          nin: profile.nin ?? '',
          phone: profile.phone ?? '',
        },
        oldState: {
          ...prevState.oldState,
          address: profile.address ?? '',
          birthday: profile.birthday ? moment.unix(profile.birthday).format('DD/MM/YYYY') : '',
          email: profile.email ?? '',
          first_name: profile.first_name ?? '',
          last_name: profile.last_name ?? '',
          nin: profile.nin ?? '',
          phone: profile.phone ?? '',
        },
      }));
    }
  }, [profile.loading]);

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.newState,
        first_name: event.target.value,
      },
    }));
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.newState,
        last_name: event.target.value,
      },
    }));
  };

  const onBirthdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.newState,
        birthday: event.target.value,
      },
    }));
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.newState,
        email: event.target.value,
      },
    }));
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('RUNNING PHONE CHANGE');
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.newState,
        address: event.target.value,
      },
    }));
  };

  const saveChanges = () => {
    // Convert birthday back to unix before saving
    const saveState = cloneDeep(state.newState);
    saveState.birthday = moment(saveState.birthday, 'DD/MM/YYYY').unix();

    // TODO: dispatch to save changes
    // dispatch(saveProfileChanges(saveState));

    // close modal
    setTimeout(() => {
      console.log('DEBUG TIMEOUT');
      setState((prevState) => ({ ...prevState, isOpen: false, loading: false }));
    }, 4000);

    // setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  const onSaveChanges = () => {
    const newState = Object.assign(state.oldState, state.newState);

    // console.log('SAVE CHANGES', newState);

    setState((prevState) => ({
      ...prevState,
      isOpen: true,
      // loading: true,
      // hasChanges: false,
      newState: {
        ...newState,
      },
    }));

    saveChanges();
  };

  const onCancelChanges = () => {
    setState((prevState) => ({
      ...prevState,
      newState: {
        ...prevState.oldState,
      },
    }));
  };

  React.useEffect(() => {
    // check if state.newState is different from state.oldState
    // if state.hasChanges is true, enable save/cancel buttons

    const keys = Object.keys(state.newState);
    let hasChanges = false;
    const profileKeys: object = Object.assign({}, state.changedFields);

    for (let i = 0; i < keys.length; i++) {
      if (state.newState[keys[i]] !== state.oldState[keys[i]]) {
        hasChanges = true;
        // @ts-ignore
        profileKeys[keys[i]] = true;
        break;
      }
    }

    setState((prevState) => ({
      ...prevState,
      hasChanges,
      changedFields: {
        ...prevState.changedFields,
        ...profileKeys,
      },
    }));
  }, [state.newState, profile]);

  const contextValue = {
    ...state,
    onSaveChanges,
    onCancelChanges,

    onFirstNameChange,
    onLastNameChange,
    onBirthdayChange,
    onEmailChange,
    onPhoneChange,
    onAddressChange,

    onToggle: (e: React.MouseEvent<HTMLElement>) => {
      setState((v) => ({
        ...initialState,
        isOpen: !v.isOpen,
      }));
    },

    onConfirm: (e: React.MouseEvent<HTMLElement>) => {
      console.log('RUNNING ON CONFIM');
    },
  };

  console.log('Debug Change Profile Details: ', {
    contextValue,
    state,
    profile,
    // m: moment(profile.birthday * 1000).format('YYYY-MM-DD'),
    // sm: moment(state.newState.birthday * 1000).format('YYYY-MM-DD'),
    // nSval: state.newState.birthday,
  });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ChangeProfileDetails;
