import moment from 'moment';
import localization from 'moment/locale/ro.js';
import localizationPt from 'moment/locale/pt.js';
import { getBetsState } from '../selectors/betData';
import { isArray } from 'lodash-es';
import { debug, pushUnique } from '../../utils';

const months = {
  1: 'Ian',
  2: 'Feb',
  3: 'Mart',
  4: 'Apr',
  5: 'Mai',
  6: 'Iun',
  7: 'Iul',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

const monthsPt = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Mai',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
};

const todayStr = {
  en: 'Today - ',
  ro: 'Azi - ',
  pt: 'Hoje - ',
};

const tomorrowStr = {
  en: 'Tomorrow - ',
  ro: 'Maine - ',
  pt: 'Amanhã - ',
};

export const formatDate = (date, lang, noHour) => {
  let refDate = moment();
  let tomorrow = moment().add(1, 'days');
  let dt = moment(date);
  if (lang === 'ro') {
    dt = moment(date).locale('ro', localization);
  } else if (lang === 'pt') {
    dt = moment(date).locale('pt', localizationPt);
  }

  if (refDate.isSame(dt, 'day')) {
    if (noHour) {
      return `${todayStr[lang]}`;
    }
    return `${todayStr[lang]} ${dt.format('HH:mm')}`;
  }
  if (tomorrow.isSame(dt, 'day')) {
    if (noHour) {
      return `${tomorrowStr[lang]}`;
    }
    return `${tomorrowStr[lang]} ${dt.format('HH:mm')}`;
  }

  if (lang === 'ro') {
    return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')}`;
  }
  if (lang === 'pt') {
    return `${dt.format('ddd, D ')}${monthsPt[dt.format('M')].toUpperCase().replace('.', '')}`;
  }
  return `${dt.format('ddd, ')}${dt.format('MMM').toUpperCase().replace('.', '')}${dt.format(' D')}`;
};

export const formatDateWithTime = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, 'days');
  let dt = moment(date);
  if (lang === 'ro') {
    dt = moment(date).locale('ro', localization);
  } else if (lang === 'pt') {
    dt = moment(date).locale('pt', localizationPt);
  }

  if (refDate.isSame(dt, 'day')) {
    return `${todayStr[lang]} ${dt.format('HH:mm')}`;
  }
  if (tomorrow.isSame(dt, 'day')) {
    return `${tomorrowStr[lang]} ${dt.format('HH:mm')}`;
  }

  if (lang === 'ro') {
    return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')} ${dt.format('HH:mm')}`;
  }
  if (lang === 'pt') {
    return `${dt.format('ddd, D ')}${monthsPt[dt.format('M')].toUpperCase().replace('.', '')} ${dt.format('HH:mm')}`;
  }
  return `${dt.format('ddd, ')}${dt
    .format('MMM')
    .toUpperCase()
    .replace('.', '')}${dt.format(' D')} ${dt.format('HH:mm')}`;
};

export const localizedMoment = (date, i18n, format) => {
  if (i18n.language === 'ro') {
    if (format) moment(date, format).locale('ro', localization);
    return moment(date).locale('ro', localization);
  } else if (i18n.language === 'pt') {
    if (format) moment(date, format).locale('pt', localizationPt);
    return moment(date).locale('pt', localizationPt);
  }
  if (format) moment(date, format).locale('en');
  return moment(date).locale('en');
};

export const formatDateShort = (date, i18n) => {
  let d = null;
  if (i18n.language === 'ro') {
    d = moment(date).locale('ro', localization);
  } else if (i18n.language === 'pt') {
    d = moment(date).locale('pt', localizationPt);
  } else {
    d = moment(date).locale('en');
  }
  if (i18n.language === 'ro') {
    return d.calendar(null, {
      sameDay: '[Azi]',
      nextDay: '[Maine]',
      nextWeek: 'DD MMM',
      lastDay: '[Ieri]',
      lastWeek: 'DD MMM',
      sameElse: 'DD MMM',
    });
  }
  if (i18n.language === 'pt') {
    return d.calendar(null, {
      sameDay: '[Hoje]',
      nextDay: '[Amanhã]',
      nextWeek: 'DD MMM',
      lastDay: '[Ontem]',
      lastWeek: 'DD MMM',
      sameElse: 'DD MMM',
    });
  }
  return d.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'DD MMM',
    lastDay: '[Yesterday]',
    lastWeek: 'DD MMM',
    sameElse: 'DD MMM',
  });
};

export const formatDateShort2 = (date, i18n) => {
  let d = null;
  if (i18n.language === 'ro') {
    d = moment(date).locale('ro', localization);
  } else if (i18n.language === 'pt') {
    d = moment(date).locale('pt', localizationPt);
  } else {
    d = moment(date).locale('en');
  }
  if (i18n.language === 'ro') {
    return d.calendar(null, {
      sameDay: '[Azi] HH:mm',
      nextDay: '[Maine] HH:mm',
      nextWeek: 'DD MMM HH:mm',
      lastDay: '[Ieri] HH:mm',
      lastWeek: 'DD MMM HH:mm',
      sameElse: 'DD MMM HH:mm',
    });
  }
  return d.calendar(null, {
    sameDay: '[Today] HH:mm',
    nextDay: '[Tomorrow] HH:mm',
    nextWeek: 'DD MMM HH:mm',
    lastDay: '[Yesterday] HH:mm',
    lastWeek: 'DD MMM HH:mm',
    sameElse: 'DD MMM HH:mm',
  });
};

export const formatTimeShort = (date) => {
  return moment(date).format('HH:mm');
};

export const formatOddValue = (state, v) => {
  const bst = getBetsState(state);

  const f = parseFloat(v);

  if (isNaN(f)) return 0;

  if (f === 0) return '-';

  if (bst.config?.settings?.twoDecimals != null && f < bst.config.settings.twoDecimals) {
    return f.toFixed(2);
  } else if (bst.config?.settings?.oneDecimal != null && f < bst.config.settings.oneDecimal) {
    return f.toFixed(1);
  }

  return f.toFixed(0);
};

export const formatCurrency = (currency) => {
  if (currency) {
    switch (currency.toLowerCase()) {
      case 'eur':
        return '€';
      case 'usd':
        return '$';
      case 'ron':
      case 'lei':
        return 'Lei';
      case 'wincoins':
        return 'W';
      case 'freespins':
      case 'fs':
        return 'Free Spins';
      default:
        return currency;
    }
  }

  return currency;
};

export const formatAmount = (amount, decimals = false, currency = null, forceDecimals = false) => {
  let value = Number(amount).toFixed(2);
  let locales = 'ro-RO';
  let type = {};
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 2;
  let formattedCurrency = null;

  if (!decimals) {
    value = Math.floor(value);
    maximumFractionDigits = 0;
  }

  if (forceDecimals && value.length < 6) {
    minimumFractionDigits = 2;
  }

  if (currency) {
    formattedCurrency = formatCurrency(currency);
    type.style = 'currency';
    type.currency = formattedCurrency;
  }

  type.minimumFractionDigits = minimumFractionDigits;
  type.maximumFractionDigits = maximumFractionDigits;

  let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
  if (formattedCurrency) {
    formattedAmount = formattedAmount.replace(formattedCurrency.toUpperCase(), formattedCurrency);
  }

  return formattedAmount;
};

export const formatAmountWithDecimals = (amount, decimals = false, forceDecimals = false) => {
  let value = Number(amount).toFixed(2);
  let locales = 'ro-RO';
  let type = {};
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 2;

  if (!decimals) {
    value = Math.floor(value);
    maximumFractionDigits = 0;
  }

  if (forceDecimals) {
    minimumFractionDigits = 2;
  }

  type.minimumFractionDigits = minimumFractionDigits;
  type.maximumFractionDigits = maximumFractionDigits;

  let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
  return formattedAmount;
};

export const formatTotalOddsValue = (value, noZeros) => {
  let v = parseFloat(value);

  if (isNaN(v)) {
    return value == null ? value : '';
  }

  let suffix = '';

  if (value < 1000) {
    v = v.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 10000) {
    v = v.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 100000) {
    v = v.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else if (value < 1000000) {
    v = (v / 1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'k';
  } else if (value < 1000000000) {
    v = (v / 1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'm';
  } else if (value < 1000000000000) {
    v = (v / 1000000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 'g';
  } else {
    v = (v / 1000000000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    suffix = 't';
  }

  v = v.toString();

  if (noZeros) {
    v = v.replace(/(\.0+)$/, '');
  }

  return v + suffix;
};

export const formatAmount2 = (value) => {
  if (!value) return '0.00';

  let v = parseFloat(value);

  if (value < 1000) {
    v = v.toFixed(2);
  } else if (value < 10000) {
    v = v.toFixed(1);
  } else {
    v = v.toFixed(0);
  }

  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatBetTitle = (state, mb, match, bets) => {
  //console.log("1 formatBetTitle mb", mb, "match", match, "bets", bets);

  if (typeof mb === 'undefined' || mb === null || typeof match === 'undefined' || match === null) {
    return '';
  }

  let name = '';

  if (match.periodShortName) {
    name = match.periodShortName + ' - ';
  }

  if (mb.mbDisplayName) {
    return name + mb.mbDisplayName;
  }

  let t = '';

  const bst = getBetsState(state);
  const betAbbr = bst.config.betAbbr;

  if (!bets) {
    bets = bst[match.mType].bets;
  }

  if (mb.idBet in betAbbr[match.mType]) {
    t = betAbbr[match.mType][mb.idBet];
  } else if (bets && typeof bets[match.idSport] !== 'undefined' && mb.idBet in bets[match.idSport]) {
    //console.log(mb.idBet, "not found in", bets[match.idSport]);
    t = bets[match.idSport][mb.idBet].betName;
  } else {
    return '';
  }

  let DEBUG = false;
  //let DEBUG = t.toLowerCase().indexOf("ajunge prima la x cornere") !== -1;

  DEBUG &&
    debug(
      'match',
      match,
      'match.idSport',
      match.idSport,
      'bets[match.idSport]',
      bets[match.idSport],
      'mb',
      mb,
      'mb.idBet',
      mb.idBet,
      'bets[match.idSport][mb.idBet]',
      bets[match.idSport][mb.idBet],
      'mb.mbSpecialValue',
      mb.mbSpecialValue,
      isArray(mb.mbSpecialValue),
    );

  let specialValue = '';

  if (isArray(mb.mbSpecialValue)) {
    if (mb.mbSpecialValue.length > 0) {
      specialValue = mb.mbSpecialValue[0];
    }
  } else if (mb.mbSpecialValue) {
    DEBUG && debug(t, 'mb.mbSpecialValue', mb.mbSpecialValue, isArray(mb.mbSpecialValue));

    specialValue = mb.mbSpecialValue;
  }

  if (specialValue !== '' && specialValue !== '*') {
    DEBUG && debug('specialValue', specialValue);

    const mbsv = specialValue.split('|');
    const phs = 'XYZ';

    let changed = false;

    mbsv.forEach((v, i) => {
      const ph = phs.charAt(i);

      DEBUG && debug('mbsv', mbsv, ph, v);

      if (t.indexOf(`[${ph}]`) > -1) {
        //t = t.replace(`[${ph}]`, v);
        t = t.split(`[${ph}]`).join(v);
        changed = true;
      }
    });

    if (!changed) {
      t += ' (' + specialValue + ')';
    }
  }

  if (mb.competitors && mb.competitors.length > 0) {
    t +=
      ' - ' +
      mb.competitors
        .map((c) => {
          let player = c.name;

          if (c.teamId && match.teamId1 && match.teamId1 === c.teamId.toString()) {
            player += ` (${match.team1Name})`;
          } else if (c.teamId && match.teamId2 && match.teamId2 === c.teamId.toString()) {
            player += ` (${match.team2Name})`;
          }

          return player;
        })
        .join(', ');
  }

  DEBUG && debug('result', t);

  if (typeof window.config.nsoftTheme !== 'undefined' && window.config.nsoftTheme) {
    if (t === 'Winner Avantaj') {
      t = t.replace('Winner', window.config.nsoftTheme);
    } else if (t === 'Winner Plus') {
      t = t.replace('Winner', window.config.nsoftTheme);
    }
  }

  return t;
};

export const formatOddName = (state, idBet, odd, match, bets) => {
  const bst = getBetsState(state);
  const outcomeAbbr = bst.config.outcomeAbbr;

  //console.log(match, idBet, odd, bets);

  let outcome = odd.mboDisplayName;
  if (!outcome) {
    if (outcomeAbbr[match.mType] && odd.idBo in outcomeAbbr[match.mType]) {
      outcome = outcomeAbbr[match.mType][odd.idBo];
    } else if (bets[match.idSport] && idBet in bets[match.idSport]) {
      if (odd.idBo in bets[match.idSport][idBet]['betOutcomes']) {
        outcome = bets[match.idSport][idBet]['betOutcomes'][odd.idBo]['betOutcomeName'];
      } else {
        outcome = 'n/a';
      }
    }
  }

  if (odd.argument) {
    outcome += ' ' + odd.argument;
  }

  // if (odd.isCashoutEnabled) {
  //   outcome += " (C)";
  // }

  return outcome;
};

export const formatTicketBetTitle = (b) => {
  if (!('specialValue' in b)) {
    return b.betDisplayName;
  }

  if (
    typeof b.specialValue === 'undefined' ||
    b.specialValue === null ||
    b.specialValue === '' ||
    b.specialValue === '*'
  ) {
    return b.betDisplayName;
  }

  /*
  const vs = b.specialValue.split("|");

  const ms = b.betDisplayName.match(/\[([^\]]+)\]/g);

  if (ms === null) {
    return b.betDisplayName + " (" + b.specialValue + ")";
  }

  let rs = b.betDisplayName;

  ms.forEach((m, i) => {
    if (i < vs.length) {
      rs = rs.replace(m + "", vs[i]);
    }
  });
  */

  let t = b.betDisplayName;

  if (b.specialValue !== '' && b.specialValue !== '*') {
    const mbsv = b.specialValue.split('|');
    const phs = 'XYZ';

    let changed = false;

    mbsv.forEach((v, i) => {
      const ph = phs.charAt(i);

      if (t.indexOf(`[${ph}]`) > -1) {
        //t = t.replace(`[${ph}]`, v);
        t = t.split(`[${ph}]`).join(v);
        changed = true;
      }
    });

    if (!changed) {
      t += ' (' + b.specialValue + ')';
    }
  }

  return t;
};

export const formatValue = (v, strip) => {
  const f = parseFloat(v);
  if (isNaN(f)) return 0;
  let r = f.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (strip) r = r.replace(/\.00$/, '');
  return r;
};

export const forceRoundIfAny = (value) => {
  value = +value;
  const exp = 2;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
};

export const normalizeBetsForEvaluation = (state, bets) => {
  const bst = getBetsState(state);
  if (!bst) {
    debug('no bets state');
    return [];
  }

  let ro = bets.map((b) => {
    if (!bst[b.mType]) {
      debug(`no bets stae for ${b.mType}`);
      return null;
    }

    const st = bst[b.mType];

    if (!(st.matches && st.matches[b.idMatch])) {
      debug('no match');
      return null;
    }
    const m = st.matches[b.idMatch];

    let s = null;
    if (st.sports && st.sports[b.idSport]) {
      s = st.sports[b.idSport];
    } else {
      debug('no sport', b, st.sports);
    }

    let c = null;
    if (st.categories && st.categories[m.idCategory]) {
      c = st.categories[m.idCategory];
    } else {
      debug('no category', m, st.categories);
    }

    let t = null;
    if (st.tournaments && st.tournaments[m.idTournament]) {
      t = st.tournaments[m.idTournament];
    } else {
      debug('no tournament', m, st.tournaments);
    }

    debug(m, s, c, t);

    const mb = m.matchBets.find((mb) => mb.idMb === b.idMb);
    let mo = null;
    if (mb) {
      mo = mb.mbOutcomes.find((mo) => mo.idMbo === b.idMbo);
    }

    return {
      idSport: b.idSport,
      sportName: s ? s.sportName : 'unknown sport',
      idCategory: m.idCategory,
      categoryName: c ? c.categoryName : 'unknown category',
      idTournament: m.idTournament,
      tournamentName: t ? t.tournamentName : 'unknown tournament',
      idMatch: b.idMatch,
      team1Name: m.team1Name,
      team2Name: m.team2Name,
      idBet: b.idBet,
      betName: mb ? formatBetTitle(mb, m, st.bets) : 'unknown bet',
      idMb: b.idMb,
      idBo: b.idBo,
      idMbo: b.idMbo,
      outcomeName: mo ? formatOddName(b.idBet, mo, m, st.bets) : 'unknown odd',
      value: b.odd,
      brId: m.brId,
      betWinnerPlus: mb ? mb.winnerPlus : false,
      betWinnerAdv: mb ? mb.winnerAdv : false,
      betWinnerSpecial: mb ? mb.winnerSpecial : false,
      matchWinnerPlus: m.winnerPlus,
      matchWinnerAdv: m.winnerAdv,
      matchWinnerSpecial: m.winnerSpecial,
      mType: b.mType,
      betType: b.betType,
    };
  });

  return ro.filter((o) => o !== null);
};
