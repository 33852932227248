import React from 'react';

import { requestDocuments, requestDocumentsMaxFileSize } from '@/modules/casino/store/actions';
import { useAppDispatch, useAppSelector } from '@/store';

import { FileExtensions, GENERIC_FILE_MAX_SIZE } from './constants';
import { FilesUploaderProperties } from './types';
import { normalizeExtensions } from './utils/formatters';

export function useDocumentsState() {
  const dispatch = useAppDispatch();
  const documents = useAppSelector((state) => state.documents);

  React.useEffect(() => {
    if (!documents.documentsMaxFileSize || documents.documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }

    if (!documents.loadingDocuments && !documents.loadingDocumentsUpload) {
      dispatch(requestDocuments());
    }
  }, [dispatch, documents.documentsMaxFileSize, documents.loadingDocumentsUpload]);

  return React.useMemo(
    () => ({
      documentsMaxSize: documents.documentsMaxFileSize ? String(documents.documentsMaxFileSize) : GENERIC_FILE_MAX_SIZE,
      documentsLoading: documents.loadingDocumentsUpload,
    }),
    [documents.documentsMaxFileSize, documents.loadingDocumentsUpload],
  );
}

export function useFilesUploader(properties?: FilesUploaderProperties) {
  const {
    onSelectCallback,
    onClearCallback,
    onSaveCallback,
    onCancelCallback,
    onCloseCallback,
    isOpen = false,
    isLoading = false,
    dialogTitle = '',
    dialogDescription = '',
  } = properties ?? {};

  const extensions = React.useMemo(() => {
    if (properties?.extensions !== undefined) {
      return normalizeExtensions(properties.extensions);
    } else {
      return FileExtensions;
    }
  }, [properties?.extensions]);

  return React.useMemo(() => {
    const context = {
      onSelectCallback,
      onClearCallback,
      onSaveCallback,
      onCancelCallback,
      onCloseCallback,
      isOpen,
      isLoading,
      extensions,
      dialogTitle,
      dialogDescription,
    };

    return {
      context,
    };
  }, [
    onSelectCallback,
    onClearCallback,
    onSaveCallback,
    onCancelCallback,
    onCloseCallback,
    isOpen,
    isLoading,
    extensions,
    dialogTitle,
    dialogDescription,
  ]);
}
