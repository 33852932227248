import { paymentProviderNameById } from '@/constants/paymentProvider';
import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';
import store from '@/store';

export const logDepositEvent = (featureUsed: string, state: any, error: any = null) => {
  try {
    const localStorageBonusData = localStorage.getItem('deposit');
    const depositData = localStorageBonusData ? JSON.parse(localStorageBonusData) : null;
    const params = new URLSearchParams(window.location.search);
    const paymentProvider = depositData?.paymentProvider || state.paymentProvider;

    // is Skrill if paymentProvider === 5 or if msid and transaction_id are in the URL
    const isSkrill = paymentProvider === 5 || (Boolean(params.get('msid')) && Boolean(params.get('transaction_id')));

    const profileState = store.getState().profile;

    logEvent(
      null,
      {
        feature_used: featureUsed,
        feature_category: FeatureCategory.Wallet,
        feature_subcategory: FeatureSubcategory.Deposit,
        feature_location: FeatureLocation.MainContent,
      },
      {
        deposit_details: {
          bonus_used: {
            bonus_id: depositData?.bonusId || state.selectedBonusInfo?.bonusId,
          },
          deposit_amount: state.depositValue,
          deposit_payment_provider: isSkrill ? 5 : paymentProvider,
          deposit_payment_provider_name: paymentProviderNameById[isSkrill ? 5 : paymentProvider],
          game_url: getGameUrl(),
          ftd: profileState?.ftd,

          // Fields only for paymentProvider === 5(Skrill)
          ...((paymentProvider === 5 || isSkrill) && {
            transactionId: params.get('transaction_id'),
            msId: params.get('msid'),
          }),
          more_transaction_details: {
            ...getMoreTransactionDetails(isSkrill ? 5 : paymentProvider),
            ...(error && { frontend_error: error }),
          },
        },
      },
      undefined,
      undefined,
      profileState,
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

// A mapping is needed to identify more details about the transaction
const getMoreTransactionDetails = (paymentProvider: number) => {
  const depositState = store.getState().deposit;

  switch (paymentProvider) {
    case 3:
      return depositState.viva;
    case 5:
      return depositState.paysafe;
    case 7:
      return depositState.bridger;
    case 8:
      return depositState.betLion;
    case 800001:
    case 800002:
    case 800003:
    case 800004:
    case 800005:
    case 800006:
    case 800007:
    case 800008:
    case 800009:
      return depositState.betLion;

    default:
      return null;
  }
};

const getGameUrl = () => {
  const urlParams = new URLSearchParams(location.search);

  if (urlParams.has('from_game')) {
    return urlParams.get('from_game');
  }

  const gameUrl = localStorage.getItem('fgm');

  if (gameUrl) {
    return gameUrl;
  }

  return undefined;
};
