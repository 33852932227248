// copy values from .env to window.config (if they exist)
if (import.meta.env.VITE_REMOTE_URL) window.config.front_url = import.meta.env.VITE_REMOTE_URL;
if (import.meta.env.VITE_TENANT_ID) window.config.clientId = import.meta.env.VITE_TENANT_ID;
if (import.meta.env.VITE_SITE_ID) window.config.siteId = import.meta.env.VITE_SITE_ID;

if (import.meta.env.VITE_ENABLE_LOCAL_JACKPOTS) window.config.enableLocalJackpots = import.meta.env.VITE_ENABLE_LOCAL_JACKPOTS === 'true' ? '1' : '';

if (import.meta.env.VITE_APP_PLATFORM) {
  if (import.meta.env.VITE_LOADER_URL) window.config.loaderImage = import.meta.env.VITE_LOADER_URL;
  if (import.meta.env.VITE_LOADER_TEXT) window.config.loaderText = import.meta.env.VITE_LOADER_TEXT;

  window.config.cordova = true;
  window.config.native_platform = import.meta.env.VITE_APP_PLATFORM;
}

if (import.meta.env.VITE_DEFAULT_LANGUAGE) window.config.defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE;

//DO NOT REMOVE - This is related to the popup switch
// check if newsite cookie is set
if (!document.cookie.includes('newsite=yes; expires')) {
  // get domain without www and subdomains
  // let domain = window.location.hostname.replace('www.', '');
  // domain = domain.split('.');
  // domain = domain[domain.length - 2] + '.' + domain[domain.length - 1];
  document.cookie = 'newsite=yes; expires=Tue, 01 Jan 2069 12:00:00 UTC;path=/';
}

const removeMeta = () => {
  try {
    document.querySelector('head>title').remove();
  } catch (e) { }

  const metas = [
    'description',
    'og:description',
    'twitter:description',
    'keywords',
    'og:keywords',
    'twitter:keywords',
    'og:site_name',
    'twitter:site',
    'twitter:creator',
    'og:image',
    'twitter:image',
    'og:image:width',
    'og:image:height',
    'og:image:alt',
    'twitter:image:alt',
    'og:type',
    'og:locale',
    'og:locale:alternate',
    'og:video',
    'og:video:width',
    'og:video:height',
    'og:video:type',
    'og:video:alt',
    'twitter:card',
    'thumbnail',
    'og:title',
  ];

  metas.forEach((meta) => {
    try {
      document.querySelector(`meta[property="${meta}"]`).remove();
    } catch (e) { }
  });

  const links = ['canonical', 'og:url', 'twitter:url'];

  links.forEach((meta) => {
    try {
      document.querySelector(`link[rel="${meta}"]`).remove();
    } catch (e) { }
  });
};
removeMeta();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if (urlParams.has('appEnv') && urlParams.get('appEnv') === 'flutter') {
  window.config.appEnv = 'flutter';
  window.config.disableCookies = '1';
  document.documentElement.classList.add('flutter');

  let originalHeight = document.scrollingElement.scrollHeight;
  window.parent.postMessage({ type: 'resize', data: { height: originalHeight } }, '*');

  const resizeObserver = () => {
    if (originalHeight === document.scrollingElement.scrollHeight) return;
    //console.log('Body height changed:', document.scrollingElement.scrollHeight);

    originalHeight = document.scrollingElement.scrollHeight;
    window.parent.postMessage({ type: 'resize', data: { height: originalHeight } }, '*');
  };
  if (urlParams.has('platformType')) {
    window.config.platformType = urlParams.get('platformType');
  }
  setInterval(resizeObserver, 1000);
}

if (urlParams.has('jwt')) {
  let jwt = urlParams.get('jwt');
  if (jwt) {
    try {
      jwt = JSON.parse(jwt);
      if (jwt.accessToken && jwt.tokenType && jwt.expiresIn && jwt.refreshToken && jwt.authType && jwt.authDate) {
        localStorage.setItem('access_token', jwt.accessToken);
        localStorage.setItem('token_type', jwt.tokenType);
        localStorage.setItem('expires_in', jwt.expiresIn);
        localStorage.setItem('refresh_token', jwt.refreshToken);
        localStorage.setItem('auth_type', jwt.authType);
        localStorage.setItem('auth_date', jwt.authDate);
      }
    } catch (e) { }
  }
}

if (urlParams.has('lng')) {
  let lng = urlParams.get('lng');
  if (lng && window.config.languages.includes(lng)) {
    try {
      localStorage.setItem('i18nextLng', lng);
    } catch (e) { }
  }
}

const jsonDecodeConfig = () => {
  const toDecode = ['depositSettings', 'withdrawSettings'];
  toDecode.forEach((key) => {
    try {
      if (window?.config?.[key]) {
        window.config[key] = JSON.parse(window.config[key]);
      }
    } catch (e) {
      // do nothing;
    }
  });
};
jsonDecodeConfig();

if (navigator?.userAgent?.includes?.('https://github.com/prerender/prerender')) {
  window.config.disableCookies = '1';
}
