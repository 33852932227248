// ** Redux Imports
import { createSlice, current } from '@reduxjs/toolkit';
import { isEqual } from 'lodash-es';

import pragmaticLive from '../sagas/pragmaticLiveWS';

// import { RootState } from '../index';

const DEBUG = true;

export const subscribeToTable = (tableId: string, currency: string) => {
  pragmaticLive.subscribe(tableId, currency);
};

export interface PragmaticLiveReducer {
  tables: any;
  tableKey: string[];
}

// mainEventBus.emit("NOTIFICATION", {message: `You have bought {{VALUE}}`, placeholders: {VALUE: 'Small pack'}, progressBar:false, type: 'success'})

export const pragmaticLiveSlice = createSlice({
  name: 'pragmaticLive',
  initialState: <PragmaticLiveReducer>{
    tables: {},
  },
  reducers: {
    clearState: (state, action) => {
      state.tables = {};
    },
    updateTables: (state, action) => {
      if (action?.payload?.tableId) {
        let table = null;
        if (state.tables[action.payload.tableId]) {
          table = current(state.tables[action.payload.tableId]);
        }

        if (!isEqual(table, action.payload)) {
          state.tables[action.payload.tableId] = action.payload;
        }
      }
    },
    updateTablesKeys: (state, action) => {
      if (action?.payload?.tableKey) {
        state.tableKey = action?.payload?.tableKey;
      }
    },
  },
  extraReducers: (builder) => {},
});

export const { clearState, updateTables, updateTablesKeys } = pragmaticLiveSlice.actions;

export default pragmaticLiveSlice.reducer;
