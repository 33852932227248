import { ifPixelAnalyticsEnabled } from '../utils/analyticsUtils';

declare global {
  interface Window {
    fbq: any;
  }
}

export const trackCustomMetaEvent = ifPixelAnalyticsEnabled((eventName: string, params: any) => {
  if (!window.fbq) return;
  window.fbq('trackCustom', eventName, {
    ...params,
  });
});

export const trackMetaEvent = ifPixelAnalyticsEnabled((eventName: string, params: any) => {
  if (!window.fbq) return;
  window.fbq('track', eventName, {
    ...params,
  });
});
