import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash-es';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { getNumberInfo } from '@/page-components/utils/functions';

//import './index.scss';

type JackpotValueProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const JackpotValue = (componentProps: JackpotValueProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  let isVisible = true;
  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [id] = React.useState(uniqueId('jackpot-value-'));
  const oldValue = React.useRef<number | null>(null);

  const jackpot_groups = useAppSelector(
    (state) => state.dataSources.items?.[window.config.dataSourceAllJackpots]?.data,
  );
  const groups = props.properties.group ?? '';

  /*
  const [{ jackpot_value, jackpot_group_id }, setJackpotValue] = React.useState({
    jackpot_value: 0,
    jackpot_group_id: 'egt',
  });
  */

  /*
  React.useEffect(() => {
    const tid = setInterval(() => {
      setJackpotValue((v) => {
        const newV = { ...v };
        newV.jackpot_value += Math.random() * 5;
        newV.jackpot_value = parseFloat(newV.jackpot_value.toFixed(2));
        return newV;
      });
    }, 10000);

    return () => clearInterval(tid);
  }, []);
  */

  const [jackpot_value, jackpot_group_id] = React.useMemo(() => {
    let value: any = null;

    if (!isVisible) return [null, null];

    const jackpot_group_id = typeof groups === 'string' ? groups : groups?.[0];

    if (jackpot_groups && jackpot_groups.length && jackpot_group_id) {
      const group = jackpot_groups.find((j: any) => j.group === jackpot_group_id);
      if (group) {
        group.jackpots?.data?.forEach?.((level: any) => {
          const parsed = parseFloat(level.accumulator_states.data.value);
          if (!isNaN(parsed)) {
            value += parsed;
          }
        });
      }
    }

    if (oldValue.current == null) {
      let tmp = value;
      if (tmp != null) {
        tmp -= 5;
        if (tmp < 0) tmp = 0;
        oldValue.current = tmp;
      }
    }

    return [value, jackpot_group_id];
  }, [jackpot_groups, groups, isVisible]);

  React.useEffect(() => {
    let tid: NodeJS.Timeout;
    if (isVisible && jackpot_value != null && jackpot_value > 0) {
      const obj = document.getElementById(id);

      const end = jackpot_value;
      let start = oldValue.current ?? 0;
      if (end - start > 5) {
        start = end - 5;
      }

      let current = start;
      const steps = 100;
      const increment = (end - start) / steps;
      const time = 30000 / 100;

      const step = () => {
        current += increment;
        if (current > end) {
          current = end;
        }

        const number = getNumberInfo({ locale: '', amount: current, digits: 2 });

        if (obj) obj.innerText = number?.amount ?? current.toFixed(2);

        oldValue.current = current;

        if (current < end) {
          tid = setTimeout(step, time);
        }
      };

      tid = setTimeout(step, time);
    }
    return () => clearInterval(tid);
  }, [jackpot_value, id, isVisible]);

  const contextValue = React.useMemo(() => {
    const end = jackpot_value;
    let start = oldValue.current ?? 0;
    if (end - start > 5) {
      start = end - 5;
    }

    if (start) {
      const number = getNumberInfo({ locale: '', amount: start, digits: 2 });

      start = number?.amount ?? start.toFixed(2);
    }

    return {
      value: jackpot_value != null ? start : null,
      jackpot_value: jackpot_value,
      jackpot_group_id: jackpot_group_id,
      id: id,
    };
  }, [dataElementContext, componentProps, jackpot_value, jackpot_group_id, id]);

  if (!isVisible) return null;

  //if (contextValue.value != null) console.log('JackpotValue[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default JackpotValue;
