import { cloneDeep } from 'lodash-es';
import { debug, sortArrayByKey, pushUnique } from './';
import { getTr } from './nsoft';

const DEBUG = false;
const STAKE_DEBUG = false;
const MAX_POSITION = 2147483647;

const excludeType = {
  None: 0,
  BetShopLive: 1,
  BetShopPreMatch: 2,
  BetShopBoth: 3,
  InternetLive: 4,
  InternetPreMatch: 5,
  InternetBoth: 6,
  TerminalLive: 7,
  TerminalPreMatch: 8,
  TerminalBoth: 9,
};

const objectType = {
  All: 0,
  Sport: 2,
  Country: 3,
  Tournament: 4,
  Match: 5,
  Stake: 8,
  Period: 16,
};

const reportUpdate = (...args) => {
  debug(args);
};

const matchBetSortStakes = (mb) => {
  mb.mbOutcomes.sort((s1, s2) => {
    if (s1.mboEventPosition !== s2.mboEventPosition) return s1.mboEventPosition - s2.mboEventPosition;
    if (s1.idBet !== s2.idBet) return parseInt(s1.idBet, 10) - parseInt(s2.idBet, 10);
    if (s1.mboOrder !== s2.mboOrder) return s1.mboOrder - s2.mboOrder;
    return s1.mboCode - s2.mboCode;
  });
};

export const applyUpdate = (draft, addMatch, removeMatch, updateState, queue) => {
  if (!queue) {
    queue = draft.updatesQueue;
  }

  //debug(`applying ${queue.length} updates`);

  for (const u of queue) {
    switch (u.type) {
      case 'sport_created': {
        DEBUG && reportUpdate(`sport ${u.data.idSport}/${u.data.sportName} created`);
        const tmp = { ...u.data };
        tmp.sportName = getTr(tmp.sportName);
        tmp.sportShortName = getTr(tmp.sportShortName);
        draft.sports[u.data.idSport] = tmp;

        break;
      }
      case 'sport_updated': {
        DEBUG && reportUpdate(`sport ${u.data.idSport}/${u.data.sportName} updated`);
        if (u.data.noCreate) {
          if (u.data.idSport in draft.sports) {
            const s = { ...draft.sports[u.data.idSport] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              s[k] = u.data[k];
            }

            draft.sports[u.data.idSport] = s;
          }

          if (draft.sportsMeta && u.data.idSport in draft.sportsMeta) {
            const sm = { ...draft.sportsMeta[u.data.idSport] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              sm[k] = u.data[k];
            }

            draft.sportsMeta[u.data.idSport] = sm;
          }
        } else {
          const tmp = { ...u.data };
          tmp.sportName = getTr(tmp.sportName);
          tmp.sportShortName = getTr(tmp.sportShortName);
          draft.sports[u.data.idSport] = tmp;
        }

        break;
      }
      case 'sport_deleted': {
        // Sport identifier, int (32-bit signed integer) for entity
        DEBUG && reportUpdate(`sport ${u.data} deleted`);
        delete draft.sports[u.data];

        break;
      }
      case 'category_created': {
        DEBUG && reportUpdate(`category ${u.data.idCategory}/${u.data.categoryName} created`);
        const tmp = { ...u.data };
        tmp.categoryName = getTr(tmp.categoryName);
        tmp.categoryShortName = getTr(tmp.categoryShortName);
        draft.categories[u.data.idCategory] = tmp;

        break;
      }
      case 'category_updated': {
        DEBUG && reportUpdate(`category ${u.data.idCategory}/${u.data.categoryName} updated`);
        if (u.data.noCreate) {
          if (u.data.idCategory in draft.categories) {
            const c = { ...draft.categories[u.data.idCategory] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              c[k] = u.data[k];
            }

            draft.categories[u.data.idCategory] = c;
          }

          if (draft.categoriesMeta && u.data.idCategory in draft.categoriesMeta) {
            const cm = { ...draft.categoriesMeta[u.data.idCategory] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              cm[k] = u.data[k];
            }

            draft.categoriesMeta[u.data.idCategory] = cm;
          }
        } else {
          const tmp = { ...u.data };
          tmp.categoryName = getTr(tmp.categoryName);
          tmp.categoryShortName = getTr(tmp.categoryShortName);
          draft.categories[u.data.idCategory] = tmp;
        }

        break;
      }
      case 'category_deleted': {
        // Championship identifier, int (32-bit signed integer) for entity
        DEBUG && reportUpdate(`category ${u.data} deleted`);
        delete draft.categories[u.data];

        break;
      }
      case 'tournament_created': {
        DEBUG && reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} created`);
        const tmp = { ...u.data };
        tmp.tournamentName = getTr(tmp.tournamentName);
        tmp.tournamentShortName = getTr(tmp.tournamentShortName);
        draft.tournaments[u.data.idTournament] = tmp;

        break;
      }
      case 'tournament_updated': {
        DEBUG && reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} updated`);
        if (u.data.noCreate) {
          if (u.data.idTournament in draft.tournaments) {
            const t = { ...draft.tournaments[u.data.idTournament] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              t[k] = u.data[k];
            }

            draft.tournaments[u.data.idTournament] = t;
          }

          if (draft.tournamentsMeta && u.data.idTournament in draft.tournamentsMeta) {
            const tm = { ...draft.tournamentsMeta[u.data.idTournament] };

            for (const k in u.data) {
              if (k === 'noCreate') continue;
              tm[k] = u.data[k];
            }

            draft.tournamentsMeta[u.data.idTournament] = tm;
          }
        } else {
          const tmp = { ...u.data };
          tmp.tournamentName = getTr(tmp.tournamentName);
          tmp.tournamentShortName = getTr(tmp.tournamentShortName);
          draft.tournaments[u.data.idTournament] = tmp;
        }

        break;
      }
      case 'tournament_deleted': {
        // Tournament identifier, int (32-bit signed integer) for entity
        DEBUG && reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} deleted`);
        delete draft.tournaments[u.data];

        break;
      }
      case 'period_created':
      case 'match_created': {
        // if (u.data.sport) {
        //   DEBUG && reportUpdate(`sport ${u.data.sport.idSport}/${u.data.sport.sportName} created`);
        //   draft.sports[u.data.sport.idSport] = u.data.sport;
        // }
        // if (u.data.category) {
        //   DEBUG && reportUpdate(`category ${u.data.category.idCategory}/${u.data.category.categoryName} created`);
        //   draft.categories[u.data.category.idCategory] = u.data.category;
        // }
        // if (u.data.tournament) {
        //   DEBUG && reportUpdate(`tournament ${u.data.tournament.idTournament}/${u.data.tournament.tournamentName} created`);
        //   draft.tournaments[u.data.tournament.idTournament] = u.data.tournament;
        // }
        // if (u.data.match) {
        //   DEBUG && reportUpdate(`match ${u.data.match.idMatch} created`);
        //   draft.matches[u.data.match.idMatch] = u.data.match;
        // }
        addMatch(draft, cloneDeep(u.data));

        break;
      }
      case 'period_updated':
      case 'match_updated': {
        const nm = cloneDeep(u.data.match);

        let mid = nm.idMatch;
        let pid = nm.parentIdMatch;
        if (!pid) {
          pid = mid;
        }

        let m = null;

        if (pid === mid) {
          if (mid in draft.matches) {
            m = { ...draft.matches[mid] };
          }
        } else {
          if (!(pid in draft.matches)) {
            //debug(`parent match ${pid} not found`);
            break;
          }

          const pm = draft.matches[pid];

          if (!('periods' in pm)) {
            pm.periods = [];
          } else if (pm.periods == null) {
            pm.periods = [];
          }

          for (let p of pm.periods) {
            if (p.idMatch === mid) {
              m = p;
              break;
            }
          }
        }

        DEBUG && reportUpdate(`match ${nm.idMatch} updated, pid ${pid}`);

        if (!m) {
          DEBUG && debug(`match ${nm.idMatch} not found. Creating...`);

          if (!nm.noCreate) {
            addMatch(draft, cloneDeep(u.data));
          }
        } else {
          // console.log('match updated', nm.idMatch, nm);

          if (pid === mid) {
            if (!(nm.idSport in draft.sports)) {
              DEBUG && debug(`sport ${nm.idSport} not found. Creating...`);
              const sp = { ...u.data.sport };
              sp.sportName = getTr(sp.sportName);
              sp.sportShortName = getTr(sp.sportShortName);
              draft.sports[nm.idSport] = sp;
            }

            if (!(nm.idCategory in draft.categories)) {
              DEBUG && debug(`category ${nm.idCategory} not found. Creating...`);
              const ct = { ...u.data.category };
              ct.categoryName = getTr(ct.categoryName);
              ct.categoryShortName = getTr(ct.categoryShortName);
              draft.categories[nm.idCategory] = ct;
            }

            if (!(nm.idTournament in draft.tournaments)) {
              DEBUG && debug(`tournament ${nm.idTournament} not found. Creating...`);
              const tr = { ...u.data.tournament };
              tr.tournamentName = getTr(tr.tournamentName);
              tr.tournamentShortName = getTr(tr.tournamentShortName);
              draft.tournaments[nm.idTournament] = tr;
            }
          }

          if ('team1Name' in nm) {
            nm.team1Name = getTr(nm.team1Name);
          }
          if ('team1ShortName' in nm) {
            nm.team1ShortName = getTr(nm.team1ShortName);
          }
          if ('team2Name' in nm) {
            nm.team2Name = getTr(nm.team2Name);
          }
          if ('team2ShortName' in nm) {
            nm.team2ShortName = getTr(nm.team2ShortName);
          }

          if ('sportName' in nm) {
            nm.sportName = getTr(nm.sportName);
          }
          if ('sportShortName' in nm) {
            nm.sportShortName = getTr(nm.sportShortName);
          }

          if ('categoryName' in nm) {
            nm.categoryName = getTr(nm.categoryName);
          }
          if ('categoryShortName' in nm) {
            nm.categoryShortName = getTr(nm.categoryShortName);
          }

          if ('tournamentName' in nm) {
            nm.tournamentName = getTr(nm.tournamentName);
          }
          if ('tournamentShortName' in nm) {
            nm.tournamentShortName = getTr(nm.tournamentShortName);
          }

          if ('periodShortName' in nm) {
            nm.periodShortName = getTr(nm.periodShortName);
          }

          if (nm.matchBets && Array.isArray(nm.matchBets)) {
            nm.matchBets.forEach((mb) => {
              mb.mbDisplayName = getTr(mb.mbDisplayName);

              if (mb.mbOutcomes && Array.isArray(mb.mbOutcomes)) {
                mb.mbOutcomes.forEach((mbo) => {
                  mbo.mboType = getTr(mbo.mboType);
                  mbo.mboDisplayName = getTr(mbo.mboDisplayName);
                  mbo.betName = getTr(mbo.betName);
                });
              }
            });
          }

          for (const k in nm) {
            if (k === 'noCreate' || k === 'periods') continue;
            if (k !== 'matchBets' && k !== 'matchBetsToDelete') {
              m[k] = nm[k];
              continue;
            }

            if (nm.matchBets && nm.matchBets.length) {
              // console.log(`m`, _.cloneDeep(m), _.cloneDeep(nm));

              for (const umb of nm.matchBets) {
                const idx = m.matchBets.findIndex((mb) => mb.idBet === umb.idBet);

                // console.log("idx", idx, umb);

                if (idx === -1) {
                  m.matchBets.push(umb);
                } else {
                  m.matchBets[idx] = umb;
                }
              }

              m.matchBets.sort((a, b) => {
                if (a.mbPosition === b.mbPosition) {
                  if (a.isDuplicate && !b.isDuplicate) return -1;
                  if (!a.isDuplicate && b.isDuplicate) return 1;
                  return 0;
                }

                return a.mbPosition - b.mbPosition;
              });
            }

            if (nm.matchBetsToDelete && Array.isArray(nm.matchBetsToDelete) && nm.matchBetsToDelete.length) {
              for (const idBet of nm.matchBetsToDelete) {
                const idx = m.matchBets.findIndex((mb) => mb.idBet === idBet);

                if (idx !== -1) {
                  m.matchBets.splice(idx, 1);
                }
              }
            }

            let isWA = false;

            for (const mb of m.matchBets) {
              if (mb.isDuplicate) {
                isWA = true;
                mb.winnerAdv = true;
              }
            }

            m.winnerAdv = isWA;
          }

          if (pid === mid) {
            updateState(draft, m);
          }
        }

        break;
      }
      case 'match_replace': {
        DEBUG && reportUpdate(`match ${u.data.idMatch} replaced`);
        // draft.matches[u.data.idMatch] = u.data;
        addMatch(draft, cloneDeep(u.data));

        break;
      }
      case 'match_deleted': {
        // MatchDeletedData
        for (const id of u.data) {
          DEBUG && reportUpdate(`match ${id} deleted`);
          removeMatch(draft, id);
        }

        break;
      }
      case 'period_deleted': {
        for (const pd of u.data) {
          removeMatch(draft, pd.idMatch, pd.idPeriod);
        }

        break;
      }
      case 'stake_created': // List Stake of entities
      case 'stake_updated': {
        // List Stake of entities
        const valid = [];

        if (!(u.data && Array.isArray(u.data))) {
          debug('invalid stake update', u.data);
          break;
        }

        for (let sd of u.data) {
          let s = { ...sd };
          // if (s.idMbo in draft.odds) {
          //   const so = draft.odds[s.idMbo];

          //   for (const k in s) {
          //     if (k === "mboOddValue") {
          //       if (s.mboOddValue < so.mboOddValue) {
          //         debug(`odd ${s.idMbo} decreased from ${so.mboOddValue} to ${s.mboOddValue}`);

          //         so.changeDir = "down";
          //       } else if (s.mboOddValue > so.mboOddValue) {
          //         debug(`odd ${s.idMbo} increased from ${so.mboOddValue} to ${s.mboOddValue}`);

          //         so.changeDir = "up";
          //       }
          //     }

          //     so[k] = s[k];
          //   }

          //   valid.push(s);
          // } else {
          //   draft.odds[s.idMbo] = s;

          // {
          //   "idMatch": "14130099",
          //   "idBet": "702",
          //   "idMbo": "84970282",
          //   "idBo": "702/1",
          //   "mboActive": true,
          //   "mboType": "Win1",
          //   "mboDisplayName": "Win1",
          //   "mboOddValue": 8.35,
          //   "mboPosition": 1005
          // }

          s.mboType = getTr(s.mboType);
          s.mboDisplayName = getTr(s.mboDisplayName);
          s.betName = getTr(s.betName);

          let mid = s.idMatch;
          let pid = s.parentIdMatch;
          if (!pid) {
            pid = mid;
          }

          let m = null;

          if (pid === mid) {
            if (pid in draft.matches) {
              m = draft.matches[pid];
            }
          } else {
            if (!(pid in draft.matches)) {
              //debug(`parent match ${pid} not found`);
              continue;
            }

            const pm = draft.matches[pid];

            if ('periods' in pm) {
              for (let p of pm.periods) {
                if (p.idMatch === mid) {
                  m = p;
                  break;
                }
              }
            }
          }

          if (m) {
            let found = false;
            let inserted = null;

            const mbs = [...m.matchBets];

            for (const mb of mbs) {
              if (mb.idBet !== s.idBet) {
                // next if not the searched odd group
                continue;
              }

              // odd group found, now search for the odd
              for (let i = 0; i < mb.mbOutcomes.length; i++) {
                if (mb.mbOutcomes[i].idMbo !== s.idMbo) {
                  // next if not the searched odd
                  continue;
                }

                const so = mb.mbOutcomes[i];

                if (s.mboOddValue < so.mboOddValue) {
                  DEBUG && debug(`odd ${s.idMbo} decreased from ${so.mboOddValue} to ${s.mboOddValue}`);

                  s.changeDir = 'down';
                } else if (s.mboOddValue > so.mboOddValue) {
                  DEBUG && debug(`odd ${s.idMbo} increased from ${so.mboOddValue} to ${s.mboOddValue}`);

                  s.changeDir = 'up';
                }

                mb.mbOutcomes[i] = s;

                STAKE_DEBUG &&
                  DEBUG &&
                  reportUpdate(`updated odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} on position ${i}`);

                found = true;
                break;
              }

              if (!found) {
                // odd not found, insert it
                for (let i = 0; i < mb.mbOutcomes.length; i++) {
                  if (mb.mbOutcomes[i].mboPosition > s.mboPosition) {
                    mb.mbOutcomes.splice(i, 0, s);
                    inserted = mb;

                    STAKE_DEBUG &&
                      DEBUG &&
                      reportUpdate(`inserted odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} on position ${i}`);

                    found = true;
                    break;
                  }
                }
              }

              if (!found) {
                mb.mbOutcomes.push(s);
                found = true;
                inserted = mb;
              }

              break;
            }

            if (inserted) {
              matchBetSortStakes(inserted);
            }

            if (!found) {
              // get the bet fron state

              let betName = s.betName;
              if (!betName) {
                if (m.idSport in draft.bets && s.idBet in draft.bets[m.idSport]) {
                  betName = draft.bets[m.idSport][s.idBet].betName;
                } else {
                  DEBUG && console.error(`cannot find market ${s.idBet} for stake update/create`);
                }
              }
              if (!betName) {
                betName = 'N/A';
              }

              mbs.push({
                idBet: s.idBet,
                idMb: m.idMatch + '/' + s.idBet,
                mbChanged: false,
                mostBalanced: false,
                mbPosition: s.mboPosition,
                mbActive: true,
                mbDisplayName: betName,
                mbOutcomes: [s],
              });

              found = true;

              mbs.sort((a, b) => a.mbPosition - b.mbPosition);
            }

            m.matchBets = mbs;

            if (draft.data?.[m.idSport]?.[m.idCategory]?.[m.idTournament]?.[m.idMatch]) {
              // Cata[2024-04-17]: the above updates matches only in draft.matches; we need the update in draft.data as well
              draft.data[m.idSport][m.idCategory][m.idTournament][m.idMatch] = m;
            }

            STAKE_DEBUG &&
              !found &&
              DEBUG &&
              reportUpdate(`could not insert odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} not found`);
          } else {
            STAKE_DEBUG && DEBUG && reportUpdate(`event ${s.idMatch} not found`);
          }
        }
        // }

        DEBUG && reportUpdate(`${u.type}, total ${u.data.length}, valid ${valid.length}`);

        break;
      }
      case 'stake_deleted': {
        // StakeRemove
        // const valid = [];
        let errors = 0;

        for (const sr of u.data) {
          // if (sr.ID in draft.odds) {
          //   delete draft.odds[sr.ID];

          //   valid.push(sr);
          // } else {
          //   STAKE_DEBUG && DEBUG && reportUpdate(`odd ${sr.ID} of event ${sr.EventId} not found`);
          //   errors++;
          // }

          if (sr.EventId in draft.matches) {
            const m = draft.matches[sr.EventId];

            let found = false;

            for (const mb of m.matchBets) {
              for (let i = 0; i < mb.mbOutcomes.length; i++) {
                if (mb.mbOutcomes[i].idMbo === sr.ID + '') {
                  found = true;
                  mb.mbOutcomes.splice(i, 1);
                  break;
                }
              }

              if (found) break;
            }

            if (!found) {
              STAKE_DEBUG && DEBUG && reportUpdate(`odd ${sr.ID} not found in event ${sr.EventId}`);
              errors++;
            }
          } else {
            STAKE_DEBUG && DEBUG && reportUpdate(`failed to delete odd ${sr.ID}, event ${sr.EventId} not found`);
            errors++;
          }
        }

        DEBUG && reportUpdate(`${u.type}, total ${u.data.length}, errors ${errors}`);

        // if (valid.length) {
        //   u.data = valid;
        // } else {
        //   u.data = null;
        // }

        break;
      }
      case 'exclude_updated': {
        DEBUG && reportUpdate('exclude updated', u.data);

        if (!(u.data && Array.isArray(u.data))) {
          debug('invalid exclude update', u.data);
          break;
        }

        for (const et of u.data) {
          DEBUG && reportUpdate('processing exclude', et);

          if (et.excludeType !== excludeType.InternetBoth) {
            if (et.excludeType === excludeType.InternetPreMatch && draft.stateType !== 'prematch') {
              DEBUG && reportUpdate("excludeType doesn't match stateType, skipping", et.excludeType, draft.stateType);
              continue;
            }
            if (et.excludeType === excludeType.InternetLive && draft.stateType !== 'live') {
              DEBUG && reportUpdate("excludeType doesn't match stateType, skipping", et.excludeType, draft.stateType);
              continue;
            }
          }

          // type ExcludeItem struct {
          //   PeriodId    int32       `json:"periodId"`
          //   StakeTypeId int32       `json:"stakeTypeId"`
          //   CountryCode string      `json:"countryCode"`
          //   ExcludeType ExcludeType `json:"excludeType"`
          //   IgnoreType  byte        `json:"ignoreType"`
          //   ObjectType  ObjectType  `json:"objectType"`
          //   ObjectId    int32       `json:"objectId"`
          // }

          const oid = '' + et.objectId;

          if (et.excludeType === excludeType.None) {
            // we need to reset _loaded flag so we refetch excluded matches
            for (const m of Object.values(draft.matches)) {
              switch (et.objectType) {
                case objectType.Sport: {
                  if (m.idSport !== oid) {
                    continue;
                  }
                  break;
                }
                case objectType.Country: {
                  if (m.idCategory !== oid) {
                    continue;
                  }
                  break;
                }
                case objectType.Tournament: {
                  if (m.idTournament !== oid) {
                    continue;
                  }
                  break;
                }
                case objectType.Match: {
                  if (m.idMatch !== oid) {
                    continue;
                  }
                  break;
                }
                case objectType.Period: {
                  break;
                }
                default: {
                  console.error(`unhandled exclude object type ${et.objectType}`);
                  continue;
                }
              }

              if (m._loaded) {
                DEBUG && reportUpdate(`resetting match ${m.idMatch} _loaded flag`);
                m._loaded = false;
              }
            }
          } else {
            if (et.periodId) {
              let periodsDeleted = 0;

              for (const m of Object.values(draft.matches)) {
                // ignore periods - just to be sure - we store periods inside main events
                if (m.parentIdMatch) {
                  continue;
                }

                switch (et.objectType) {
                  case objectType.Sport: {
                    if (m.idSport !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Country: {
                    if (m.idCategory !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Tournament: {
                    if (m.idTournament !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Match: {
                    if (m.idMatch !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Period: {
                    break;
                  }
                  default: {
                    console.error(`unhandled exclude object type ${et.objectType}`);
                    continue;
                  }
                }

                if (m.periods && Array.isArray(m.periods)) {
                  const periods = m.periods.filter((p) => `${p.periodId}${p.partTypeId}` !== `${et.periodId}`);
                  if (periods.length !== m.periods.length) {
                    periodsDeleted += m.periods.length - periods.length;
                    m.periods = periods;
                  }
                }
              }

              console.log(`deleted ${periodsDeleted} periods for ${et.objectType} ${oid}`);
            } else if (et.stakeTypeId) {
              let eventsMatched = 0;
              let betsDeleted = 0;

              for (const m of Object.values(draft.matches)) {
                // ignore periods - just to be sure - we store periods inside main events
                if (m.parentIdMatch) {
                  continue;
                }

                switch (et.objectType) {
                  case objectType.Sport: {
                    if (m.idSport !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Country: {
                    if (m.idCategory !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Tournament: {
                    if (m.idTournament !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Match: {
                    if (m.idMatch !== oid) {
                      continue;
                    }
                    break;
                  }
                  case objectType.Period: {
                    break;
                  }
                  default: {
                    console.error(`unhandled exclude object type ${et.objectType}`);
                    continue;
                  }
                }

                let stId = '' + et.stakeTypeId;

                if (et.objectType !== objectType.Period) {
                  if (m.matchBets && Array.isArray(m.matchBets)) {
                    const mbs = m.matchBets.filter((mb) => mb.idBet !== stId);
                    if (mbs.length !== m.matchBets.length) {
                      betsDeleted += m.matchBets.length - mbs.length;
                      m.matchBets = mbs;
                    }
                  }
                }

                if (m.periods && Array.isArray(m.periods)) {
                  for (const p of m.periods) {
                    if (p.matchBets && Array.isArray(p.matchBets)) {
                      const mbs = p.matchBets.filter((mb) => mb.idBet !== stId);
                      if (mbs.length !== p.matchBets.length) {
                        betsDeleted += p.matchBets.length - mbs.length;
                        p.matchBets = mbs;
                      }
                    }
                  }
                }

                eventsMatched++;
              }

              console.log(`deleted ${betsDeleted} bets for ${et.objectType} ${oid}, matched ${eventsMatched} events`);
            } else {
              DEBUG && reportUpdate('invalid exclude update', et);
            }
          }
        }

        break;
      }
      default: {
        console.error(`unhandled update type ${u.type}`);
        DEBUG && reportUpdate(`unhandled update type ${u.type}`);
      }
    }
  }

  draft.updatesQueue = [];
};

export const isLive = (match) => {
  if (match.matchType === 0) {
    return false;
  }

  if (match.matchType === 1) {
    return true;
  }

  if (match.currentStatus.IsLiveStarted) {
    return true;
  }

  return false;
};
