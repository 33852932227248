import React, { useContext } from 'react';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const Preload = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);
  if (!isVisible) return null;

  let { rel, href, as } = props.properties;

  return (
    <link
      rel={rel}
      href={href}
      as={as}
    />
  );
};

export default React.memo(Preload, equalWithoutChildren);
