import React from 'react';

// @ts-ignore
import CraftJsUserComponents from '../../page-components';
import { PageDataContext } from './PageDataProvider';
import store, { RootState } from '../../store';

type Map = {
  ['id']: any;
};

function iOS() {
  return (
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const isDesktop = !isMobile;
const isIos = isMobile && iOS() && !window.config?.cordova ? true : false;
const isAndroid = isMobile && !isIos && !window.config?.cordova ? true : false;
const isNativeApp = window.config?.cordova ? true : false;
const isNativeIosApp = window.config?.native_platform === 'ios';
const isNativeAndroidApp = window.config?.native_platform === 'android';

type craftJsParserProps = {
  craftState: string | Map;
  rootNodeId: string;
  pageId?: string;
  pageLayoutId?: string;
  pageType: string;
  dsItem?: any;
  options?: {
    authenticated: boolean;
  };
  extendProps?: any;
  withProvider?: boolean;
  rootKey?: string;
  children?: any;
  parentPageDataContext?: any;
};

function getErrorObject() {
  try {
    throw Error('');
  } catch (err) {
    return err;
  }
}

const craftJsParser = (props: craftJsParserProps) => {
  const {
    craftState,
    rootNodeId,
    pageId,
    pageLayoutId,
    pageType,
    options,
    extendProps,
    withProvider,
    rootKey,
    children,
    parentPageDataContext,
  } = props;

  /*
  const err: any = getErrorObject();
  const caller_line = err.stack.split('\n')[3];
  const index = caller_line.indexOf('at ');
  const clean = caller_line.slice(index + 2, caller_line.length);

  console.log({ pageId, pageLayoutId, pageType, objectType: typeof craftState, withProvider }, clean);
  */

  const craftStateJson = typeof craftState === 'string' ? JSON.parse(craftState) : craftState;

  const state: RootState = store.getState();

  //console.log('craftStateJson', craftStateJson);
  //console.log('props', props);
  const now = Math.floor(+new Date() / 1000);

  const parse = (nodeId: string, parentNodeId?: string, key?: number) => {
    if (nodeId === null || nodeId === '') return null;
    if (craftStateJson[nodeId] == null) return null;

    const childNodeNames = craftStateJson[nodeId]?.nodes || [];
    const hasLinkedNodes =
      craftStateJson[nodeId]?.linkedNodes && Object.keys(craftStateJson[nodeId]?.linkedNodes).length;

    if (craftStateJson[nodeId].props) {
      const visibility = craftStateJson[nodeId].props.visibility ?? {};

      if (visibility.active === false) return null;
      if (
        visibility.startTime != null &&
        visibility.endTime != null &&
        !(visibility.startTime < now && now < visibility.endTime)
      ) {
        return null;
      }

      if (visibility.dsId != null && visibility.dsId && state.dataSources.items[visibility.dsId] == null) return null;
      if (visibility.deId != null && visibility.deId && state.dataElements.items[visibility.deId] == null) return null;

      if (visibility) {
        let where = ['all'];
        if (visibility.where != null) {
          where = visibility.where;
        }
        let thisPlatform = false;

        where.forEach((platform: string) => {
          switch (platform) {
            case 'all':
              thisPlatform = true;
              break;
            case 'desktop':
              if (isDesktop) thisPlatform = true;
              break;
            case 'mobile':
              if (isMobile) thisPlatform = true;
              break;
            case 'mobile_android':
              if (isAndroid) thisPlatform = true;
              break;
            case 'mobile_ios':
              if (isIos) thisPlatform = true;
              break;
            case 'native':
              if (isNativeApp) thisPlatform = true;
              break;
            case 'native_ios':
              if (isNativeIosApp) thisPlatform = true;
              break;
            case 'native_android':
              if (isNativeAndroidApp) thisPlatform = true;
              break;
            default:
            /* noop */
          }
        });

        if (!thisPlatform) return null;
      }

      if (options && typeof options.authenticated !== 'undefined') {
        if (options.authenticated && visibility.authenticated) {
          return null;
        }
        if (!options.authenticated && visibility.notAuthenticated) {
          return null;
        }
      }
    }

    if (
      craftStateJson[nodeId].props &&
      craftStateJson[nodeId].props.hide != null &&
      craftStateJson[nodeId].props.hide
    ) {
      return null;
    }

    if (
      craftStateJson[nodeId].props &&
      craftStateJson[nodeId].props.show != null &&
      !craftStateJson[nodeId].props.show
    ) {
      return null;
    }

    const extendedProps = {
      properties: {},
      visibility: {},
      ...craftStateJson[nodeId].props,
      parentNodeId,
      nodeId,
      id: nodeId,
      key: nodeId,
      uniqueId: `${pageId}-${nodeId}`,
      refId: pageType === 'layout' ? pageLayoutId : pageId,
      refType: pageType,
    };
    if (key !== undefined) extendedProps.key = key;

    if (hasLinkedNodes) {
      extendedProps['linkedNodes'] = craftStateJson[nodeId]?.linkedNodes;
    }

    if (extendProps && rootNodeId === nodeId) {
      if (extendProps && extendProps.className != null)
        extendedProps.className = `${extendedProps.className ? extendedProps.className + ' ' : ''}${
          extendProps.className
        }`;
      if (extendProps && extendProps.onClick != null) extendedProps.properties.onClick = extendProps.onClick;
    }

    if (extendedProps.properties && window !== window.parent) {
      extendedProps.properties['data-cjs-n-id'] = nodeId;
    }

    const resolvedName =
      typeof craftStateJson[nodeId].type === 'string'
        ? craftStateJson[nodeId].type
        : craftStateJson[nodeId].type.resolvedName;
    let ReactComponent = null;

    if (typeof CraftJsUserComponents[resolvedName] !== 'undefined') {
      ReactComponent = CraftJsUserComponents[resolvedName];
    }
    if (resolvedName === 'PageContent') {
      extendedProps.id = pageId;
    }

    if (!ReactComponent) {
      if (typeof CraftJsUserComponents[resolvedName] !== 'undefined') {
        console.error(`Component ${resolvedName} missing`);
      }
      return null;
    }

    if (childNodeNames.length === 0) return <ReactComponent {...extendedProps} />;

    const childNodes = childNodeNames.map((childNodeId: string, key: number) => {
      return parse(childNodeId, nodeId, key);
    });

    return <ReactComponent {...extendedProps}>{childNodes}</ReactComponent>;
  };

  const res = parse(rootNodeId);

  if (!withProvider) {
    return res;
  }

  return (
    <PageDataContext.Provider
      value={{
        pageId,
        pageLayoutId,
        pageType,
        children,
        parent: parentPageDataContext
      }}
      key={rootKey}
    >
      {res}
    </PageDataContext.Provider>
  );
};

export default craftJsParser;
