import store from '@/store';
import { betsSlipConstants } from '@/modules/bets/store/actions/constants';
import { betsSlipToggle } from '@/modules/bets/store/actions/betsSlip';
import { cloneDeep } from 'lodash-es';
import { logBetEvent } from '@/analytics';

export const onBetToggle = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();

  const target = e.currentTarget;

  if (target) {
    // TODO: to add tracking logic here
    const brmId = target.dataset.brmid;
    const mType = target.dataset.mtype;
    const idSport = target.dataset.idsport;
    const idMatch = target.dataset.idmatch;
    const idBet = target.dataset.idbet;
    const idMb = target.dataset.idmb;
    const idBo = target.dataset.idbo;
    const idMbo = target.dataset.idmbo;
    const shareTicketNumber = target.dataset.shareticketnumber;

    if (!idMatch) return;

    const bst: any = store.getState().bets;

    let m = null;

    if (mType === 'live') {
      if (idMatch in bst.live.matches) {
        m = bst.live.matches[idMatch];
      }
    } else {
      if (idMatch in bst.prematch.matches) {
        m = bst.prematch.matches[idMatch];
      }
    }

    if (m === null) {
      return;
    }

    const clonedMatch = cloneDeep(m);

    logBetEvent(e, target.dataset, bst);

    // store.dispatch({
    //   type: betsSlipConstants.TOGGLE,
    //   brmId,
    //   mType,
    //   idSport,
    //   idMatch,
    //   idBet,
    //   idMb,
    //   idBo,
    //   idMbo,
    //   shareTicketNumber,
    //   match: clonedMatch,
    //   matches: {
    //     live: bst.live.matches,
    //     prematch: bst.prematch.matches,
    //   },
    //   combiningAllowed: bst.nsoft.combiningAllowed,
    // });
    store.dispatch(betsSlipToggle(mType, idSport, idMatch, idBet, idMb, idBo, idMbo, brmId, shareTicketNumber));
  }
};
