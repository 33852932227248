import React from 'react';
import styled from 'styled-components';
import './Input.scss';
import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useTranslation } from 'react-i18next';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    inputType: 'text',
    placeholder: '',
    autoFocus: false,
  },
  visibility: {},
};

const StyledInput = styled.input((props) => props.$styleText);

const Input = (componentProps) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const dataElementContext = React.useContext(DataElementContext);

  let isVisible = true;
  let props = componentProps;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    if (props.properties.autoFocus && ref.current !== null) {
      ref.current.focus();
    }
  }, [props.properties.autoFocus]);

  // React does not support "cancel" event for inputs currently,
  // this is for "file" input when the user closes the file explorer without selecting a file
  React.useEffect(() => {
    if (props.properties.onCancel) {
      const fileInput = ref.current;

      fileInput.addEventListener('cancel', props.properties.onCancel);

      return () => {
        fileInput.removeEventListener('cancel', props.properties.onCancel);
      };
    }
  }, [props.properties.onCancel]);

  // Temporary solution for "file" input when we have a button to open the camera
  React.useEffect(() => {
    if (props.properties.inputType === 'file' && props.properties.capture && props.properties.capture.length) {
      ref.current.click();
    }
  }, [props.properties.capture, props.properties.inputType]);

  if (!isVisible) return null;

  const allInputProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        allInputProps[key] = props?.properties[key];
      }
    });
  }

  if (props.className != null) allInputProps.className = props.className;
  if (props.properties.id != null) allInputProps.id = props.properties.id;
  if (props.properties.disabled != null) allInputProps.disabled = props.properties.disabled;
  if (props.properties.name != null) allInputProps.name = props.properties.name;
  if (props.properties.value != null && props.properties.onChange != null)
    allInputProps.value =
      props.properties.inputType !== 'checkbox' || props.properties.inputType !== 'checkbox'
        ? props.properties.value
        : '';
  if (props.properties.onChange != null)
    allInputProps.checked =
      props.properties.inputType === 'checkbox' || props.properties.inputType === 'radio'
        ? props.properties.value
        : null;
  if (props.properties.required != null) allInputProps.required = props.properties.required;
  if (props.properties.onChange != null) allInputProps.onChange = props.properties.onChange;
  if (props.properties.onKeyUp != null) allInputProps.onKeyUp = props.properties.onKeyUp;
  if (props.properties.onKeyDown != null) allInputProps.onKeyDown = props.properties.onKeyDown;
  if (props.properties.onFocus != null) allInputProps.onFocus = props.properties.onFocus;
  if (props.properties.onBlur != null) allInputProps.onBlur = props.properties.onBlur;
  if (props.properties.onCancel != null) allInputProps.onCancel = props.properties.onCancel;
  if (props.properties.onClick != null) allInputProps.onClick = props.properties.onClick;
  if (props.properties.placeholder != null) allInputProps.placeholder = t(props.properties.placeholder);
  if (props.properties.inputType != null) allInputProps.type = props.properties.inputType;
  if (props.properties.autoFocus != null) allInputProps.autoFocus = props.properties.autoFocus;
  if (props.properties.checked != null) allInputProps.checked = props.properties.checked;
  if (props.properties.defaultChecked != null) allInputProps.defaultChecked = props.properties.defaultChecked;
  if (props.properties.min != null) allInputProps.min = props.properties.min;
  if (props.properties.max != null) allInputProps.max = props.properties.max;
  if (props.properties.pattern != null) allInputProps.pattern = props.properties.pattern;
  if (props.properties.readOnly != null) allInputProps.readOnly = props.properties.readOnly;
  if (props.properties.accept != null) allInputProps.accept = props.properties.accept;
  if (props.properties.capture != null) allInputProps.capture = props.properties.capture;

  if (props.properties.inputType === 'checkbox' || props.properties.inputType === 'radio') {
    if (typeof allInputProps.checked !== 'boolean') {
      allInputProps.className = `${allInputProps.className ?? ''} indeterminate`;
      allInputProps.checked = false;
    }
  }

  //console.log('Input[allInputProps]', allInputProps);

  return (
    <StyledInput {...allInputProps} ref={ref} data-is-node="true" $styleText={props.styleText} style={props.style} />
  );
};

export default React.memo(Input, equalWithoutChildren);
