import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

//import './index.scss';

type CookiesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const COOKIES_KEY = 'cookieAgreementV2';
const GTM_KEY = 'GTMAgreement';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Cookies = (componentProps: CookiesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const [showCookies, setShowCookies] = React.useState<boolean | null>(null);
  const [cookiesSettings, setCookiesSettings] = React.useState<boolean>(false);
  const [GTMData, setGTMData] = React.useState<any>({
    strictly_necessary: {
      checked: true,
    },
    preferences_functionality: {
      checked: true,
    },
    statistics_analytics: {
      checked: true,
    },
    marketing_advertising: {
      checked: true,
    },
  });

  React.useEffect(() => {
    checkCookies();
    setCookieIfCampaign();

    // Remove old cookie agreement
    if (ExpiringLocalStorage.get('cookieAgreement')) {
      ExpiringLocalStorage.remove('cookieAgreement');
    }
  }, []);

  const acceptHandler = (e: React.MouseEvent<HTMLElement>) => {
    setShowCookies(false);
    const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();

    // @ts-ignore
    ExpiringLocalStorage.set(COOKIES_KEY, 'true', expireDate);

    if (window?.config?.isGTMConsentV2Enabled === '1') {
      if (e?.currentTarget?.dataset?.refusebutton === 'true') {
        ExpiringLocalStorage.set(
          GTM_KEY,
          {
            strictly_necessary: {
              checked: true,
            },
            preferences_functionality: {
              checked: false,
            },
            statistics_analytics: {
              checked: false,
            },
            marketing_advertising: {
              checked: false,
            },
          },
          expireDate,
        );
      } else {
        ExpiringLocalStorage.set(GTM_KEY, GTMData, expireDate);
      }
      // @ts-ignore
    }
  };

  const checkCookies = () => {
    const cookies = ExpiringLocalStorage.get(COOKIES_KEY);
    const gtm = ExpiringLocalStorage.get(GTM_KEY);

    if (gtm && window?.config?.isGTMConsentV2Enabled === '1') {
      setGTMData({
        preferences_functionality: {
          checked: gtm?.preferences_functionality === 1,
        },
        statistics_analytics: {
          checked: gtm?.statistics_analytics === 1,
        },
        marketing_advertising: {
          checked: gtm?.marketing_advertising === 1,
        },
      });
    }

    if (cookies) {
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
  };

  const setCookieIfCampaign = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affId = urlParams.get('aff_id');

    if (affId) {
      // @ts-ignore
      ExpiringLocalStorage.set('aff_id', affId, 2);
    }

    const gclid = urlParams.get('gclid');
    if (gclid) {
      // @ts-ignore
      ExpiringLocalStorage.set('gclid', gclid, 2);
    }

    const sub_id = urlParams.get('sub_id');
    if (sub_id) {
      // @ts-ignore
      ExpiringLocalStorage.set('sub_id', sub_id, 2);
    }
  };

  const toggleSwitch = (e: React.MouseEvent<HTMLElement>) => {
    const newGTMData: any = { ...GTMData };
    const target = e.target as HTMLInputElement;

    newGTMData[target.name] = {
      checked: target.checked,
    };

    setGTMData(newGTMData);
  };

  const contextValue = React.useMemo(() => {
    return {
      showCookies: window?.config?.disableCookies === '0' && showCookies,
      cookiesSettings: cookiesSettings,
      GTMData: GTMData,
      acceptHandler: (e: React.MouseEvent<HTMLButtonElement>) => acceptHandler(e),
      settingsHandler: () => setCookiesSettings(!cookiesSettings),
      toggleSwitch: toggleSwitch,
    };
  }, [showCookies, acceptHandler]);

  if(window?.config?.disableCookies === '1') return null;
  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Cookies;
