import moment from 'moment';
import { isArray } from 'lodash-es';
import { sortArrayByKey, sortArrayByKey2, debug } from './';
import evBus from './evbus';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';


const emptyArray = [];

export const betUtilsConstants = {
	MATCH_NULL: -1,
	MATCH_BLOCKED: -2,
	BET_NULL: -3,
	BET_BLOCKED: -4,
	ODD_NULL: -5,
	ODD_BLOCKED: -6,
	ODD_CHANGED: -7,
	ODD_INCOMPATIBLE: -8
};

export const betUtilsErrors = {
	'-1': 'Evenimentul nu mai face parte din oferta',
	'-2': 'Evenimentul este blocat',
	'-3': 'Pariul nu mai face parte din oferta',
	'-4': 'Pariul este blocat',
	'-5': 'Cota nu mai face parte din oferta',
	'-6': 'Cota este blocata',
	'-7': 'Cota s-a schimbat',
	'-8': 'Cota incompatibila'
};

const _getOutcomeValue = (m, b, bets) => {
	const { idMb, idMbo } = b;

	const now = moment().utc().valueOf();

	if (!m) {
		return betUtilsConstants.MATCH_NULL;
	}

	if (!m.active || !m.bettingStatus) {
		return betUtilsConstants.MATCH_BLOCKED;
	}

	if (m.mType === 'live' && m.currentStatus && (!m.currentStatus.IsLiveStarted || m.currentStatus.IsLiveFinished)) {
		return betUtilsConstants.MATCH_BLOCKED;
	}

	if (m.mType === 'prematch') {
		if (m.matchDateTime < now - 2 * 60 * 1000) {
			return betUtilsConstants.MATCH_BLOCKED;
		}

		// check special deactivation time
		if (m.DDTTCKS && m.DDTTCKS < now) {
			return betUtilsConstants.MATCH_BLOCKED;
		}
	}

	let bet = m.matchBets ? m.matchBets.find(mb => mb.idMb === idMb) : null;

	if (!bet) {
		if (m.periods && Array.isArray(m.periods)) {
			for (const p of m.periods) {
				bet = p.matchBets ? p.matchBets.find(mb => mb.idMb === idMb) : null;
				if (bet) {
					if (!p.active || !p.bettingStatus) {
						return betUtilsConstants.MATCH_BLOCKED;
					}

					if (p.mType === 'live' && p.currentStatus && (!p.currentStatus.IsLiveStarted || p.currentStatus.IsLiveFinished)) {
						return betUtilsConstants.MATCH_BLOCKED;
					}

					if (p.mType === 'prematch') {
						if (p.matchDateTime && p.matchDateTime < now - 2 * 60 * 1000) {
							return betUtilsConstants.MATCH_BLOCKED;
						}

						// check special deactivation time
						if (p.DDTTCKS && p.DDTTCKS < now) {
							return betUtilsConstants.MATCH_BLOCKED;
						}
					}

					break;
				}
			}
		}

		if (!bet) {
			return betUtilsConstants.BET_NULL;
		}
	}

	if (!bet.mbActive) {
		return betUtilsConstants.BET_BLOCKED;
	}

	const bo = bet.mbOutcomes ? bet.mbOutcomes.find(mbo => mbo.idMbo === idMbo) : null;

	if (!bo) {
		return betUtilsConstants.ODD_NULL;
	}

	if (!bo.mboActive) {
		return betUtilsConstants.ODD_BLOCKED;
	}

  if (bo.deactivateTime && bo.deactivateTime < now) {
    return betUtilsConstants.ODD_BLOCKED;
  }

	if (bets && Array.isArray(bets) && bets.length > 1 && bo.isExpressDenied) {
		return betUtilsConstants.ODD_INCOMPATIBLE;
	}

	return bo.mboOddValue;
};

export const getOutcomeValue = (m, b, bets) => {
	if (b.betType !== 'betBuilder') {
		return _getOutcomeValue(m, b, bets);
	}

	// for bet builder we return the bb factor
	return b.odd;
};

export const getBetBuilderOutcomeValue = (m, b, bets) => {
	// we need to evaluate bet builder odds
	// for this we will check each odd inside and check if it is still available or the value has changed
	let vs = [];

	for (const sb of b.bb.bets) {
		const cv = _getOutcomeValue(m, sb, bets);
		if (cv < 0) {
			return cv;
		}

		vs.push(cv);
	}

	return vs.join(',');
};

export const checkOutcome = (cm, bm, idMb, idMbo) => {
	const cv = getOutcomeValue(cm, { idMb, idMbo });
	if (cv < 0) {
		return cv;
	}

	const bv = getOutcomeValue(bm, { idMb, idMbo });
	if (bv < 0) {
		return bv;
	}

	if (cv !== bv) {
		return betUtilsConstants.ODD_CHANGED;
	}

	return cv;
};

export const showLeague = m => e => {
	if (e && typeof e.stopPropagation === 'function') e.stopPropagation();
	const scrollPosition =
		window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	window.location.hash = `#league-details-${m.mType}-${m.idSport}-${m.idCategory}-${m.idTournament}-${scrollPosition}`;
};
export const showLeague2 = (mType, idSport, idCategory, idTournament) => e => {
	if (e && typeof e.stopPropagation === 'function') e.stopPropagation();
	const scrollPosition =
		window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	window.location.hash = `#league-details-${mType}-${idSport}-${idCategory}-${idTournament}-${scrollPosition}`;
};

export const showLeagueWithDataSet = (e) => {
	if (e && typeof e.stopPropagation === 'function') e.stopPropagation();

	const target = e.currentTarget;

	if (target) {
		const mType = target.dataset.mtype;
		const idSport = target.dataset.idsport;
		const idCategory = target.dataset.idcategory;
		const idTournament = target.dataset.idtournament;

		if (mType && idSport && idCategory && idTournament) {
			const scrollPosition =
				window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			window.location.hash = `#league-details-${mType}-${idSport}-${idCategory}-${idTournament}-${scrollPosition}`;
		}
	}
};

export const noop = () => { };

export const showMatchWithDataSet = (e) => {
	if (e && typeof e.stopPropagation === 'function') e.stopPropagation();

	const target = e.currentTarget;

	if (target) {
		const mType = target.dataset.mtype;
		const idMatch = target.dataset.idmatch;
		const statScore = target.dataset.statscore;

		const w = document.body.clientWidth;

		if (w > 1600) {
			evBus.emit('SHOW_MATCH_DETAILS', { mType: mType, idMatch: idMatch, statScore: statScore ? true : false });
			return;
		}
		const scrollPosition =
			window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

		window.location.hash = `#match-details-${mType}-${idMatch}-${scrollPosition}-${statScore ? 1 : 0}`;
	}
};

export const showMatchPopupWithDataSet = (e) => {
	if (e && typeof e.stopPropagation === 'function') e.stopPropagation();

	const target = e.currentTarget;

	if (target) {
		const mType = target.dataset.mtype;
		const idMatch = target.dataset.idmatch;

		if (mType && idMatch) {
			const scrollPosition =
				window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

			window.location.hash = ''; // we need to clear my thickets
			setTimeout(() => {
				window.location.hash = `#match-details-${mType}-${idMatch}-${scrollPosition}-0`;
			}, 10);
		}
	}
};

export const showMatch = (m) => () => {
	const w = document.body.clientWidth;

	if (w > 1600) {
		evBus.emit('SHOW_MATCH_DETAILS', { mType: m.mType, idMatch: m.idMatch, statScore: m.statScore ? true : false });
		return;
	}
	const scrollPosition =
		window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

	window.location.hash = `#match-details-${m.mType}-${m.idMatch}-${scrollPosition}-${m.statScore ? 1 : 0}`;
};

export const groupMatchesByDay = matches => {
	let ml = Object.values(matches);
	ml = ml.filter(m => m.active);

	return ml.reduce((acc, m) => {
		const d = moment(m.matchDateTime)
			.startOf('day')
			.valueOf();

		d in acc || (acc[d] = []);
		acc[d].push(m);

		return acc;
	}, {});
};

const MARKETS_THRESHOLD = 1;

export const getCommonMarkets = (matches, bets = null) => {
	//console.log("bets", bets);

	if (typeof matches === 'undefined' || matches === null || matches.length === 0) {
		return emptyArray;
	}

	let markets = {};

	for (let i = 0; i < matches.length; i++) {
		const m = matches[i];

		for (let j = 0; j < m.matchBets.length; j++) {
			let midBet = buildBetId(m.matchBets[j]);

			if (midBet in markets) {
				markets[midBet].count++;
			} else {
				markets[midBet] = {
					count: 1,
					mType: m.mType,
					idSport: m.idSport,
					bet: m.matchBets[j],
					name: m.team1Name + ' : ' + m.team2Name
				};
			}
		}
	}

	let me = Object.entries(markets);

	// filter markets on threshold
	if (MARKETS_THRESHOLD > 0) {
		me = me.filter(([midBet, m]) => m.count >= MARKETS_THRESHOLD);
	}

	// sort by apparitions number
	me.sort((a, b) => {
		if (bets !== null) {
			const ba = bets[a[1].idSport][a[1].bet.idBet];
			const bb = bets[b[1].idSport][b[1].bet.idBet];

			//console.log("b1", b1, "b2", b2);

			const diff = ba.betPosition - bb.betPosition;

			if (diff !== 0) {
				return diff;
			}
		}

		if (a[1].count !== b[1].count) {
			return b[1].count - a[1].count;
		}

		const ap = a[1].bet.mbPosition !== null ? a[1].bet.mbPosition : 9999;
		const bp = b[1].bet.mbPosition !== null ? b[1].bet.mbPosition : 9999;
		return ap - bp;
	});

	// put winner adv and winner plus in front
	let idx;

	//if ((idx = me.findIndex(m => m[1].bet.winnerPlus || m[1].bet.winnerAdv)) !== -1) {
	if ((idx = me.findIndex(m => m[1].bet.winnerAdv)) !== -1) {
		const m = me.splice(idx, 1);
		me.unshift(m[0]);
	}

	return me;
};

export const getHeaderMarkets = (markets, matchGroup) => {
	//console.log("header markets", markets);

	const bst = getBetsState(getStore().getState());

	// look for markets that have 3 outcomes
	let ms = markets.filter(m => {
		const oldWay = false;

		if (oldWay) {
			if (m?.[1]?.bet?.mbOutcomes?.length <= 3) {
				return true;
			}

			/*
			if (matchGroup.indexOf("promoted") !== -1) {
				debug(
					"bet",
					bst[m[1].mType].bets[m[1].idSport][m[1].bet.idBet],
					bst[m[1].mType].bets[m[1].idSport][m[1].bet.idBet].headerBet
				);
				return bst[m[1].mType].bets[m[1].idSport][m[1].bet.idBet].headerBet;
			}
			*/
			if (
				m[1].mType in bst &&
				bst[m[1].mType].bets &&
				m[1].idSport in bst[m[1].mType].bets &&
				bst[m[1].mType].bets[m[1].idSport] &&
				m[1].bet.idBet in bst[m[1].mType].bets[m[1].idSport]
			) {
				return bst[m[1].mType].bets[m[1].idSport][m[1].bet.idBet].headerBet;
			}

			return false;
		}
		return true;
	});

	if (matchGroup.indexOf('_wa') !== -1) {
		const wamb = markets.find(m => m[1].bet.winnerAdv);
		if (wamb) {
			ms = ms.filter(m => m[0] !== wamb[0]);
			ms.unshift(wamb);
		} else {
			//console.log("WA group but no WA bets");
		}
	}

	if (matchGroup.indexOf('_wp') !== -1) {
		const wpmb = markets.find(m => m[1].bet.winnerPlus);
		if (wpmb) {
			ms = ms.filter(m => m[0] !== wpmb[0]);
			ms.unshift(wpmb);
		} else {
			//console.log("WP groups but no WP bets");
		}
	}

	//console.log("ms", ms);

	return ms;
};

export const getHeaderMarketsClassic = (markets, matchGroup) => {
	let ms = [...markets];

	if (matchGroup && matchGroup.indexOf('_wa') !== -1) {
		const wamb = markets.find(m => m.winnerAdv);
		if (wamb) {
			ms = ms.filter(m => m !== wamb);
			ms.unshift(wamb);
		} else {
			//console.log("WA group but no WA bets");
		}
		return ms;
	} else if (matchGroup && matchGroup.indexOf('_wp') !== -1) {
		const wpmb = markets.find(m => m.winnerPlus);
		if (wpmb) {
			ms = ms.filter(m => m !== wpmb);
			ms.unshift(wpmb);
		} else {
			//console.log("WP groups but no WP bets");
		}
		return ms;
	}
	return markets;
};

export const buildBetId = mb => {
	let midBet = mb.idBet;

	let sp = '';

	if (isArray(mb.mbSpecialValue)) {
		if (mb.mbSpecialValue.length > 0) {
			sp = mb.mbSpecialValue.join('/');
		}
	} else if (mb.mbSpecialValue && mb.mbSpecialValue !== '' && mb.mbSpecialValue !== '*') {
		sp = mb.mbSpecialValue;
	}

	if (sp !== '') {
		midBet += '/' + sp;
	}

	if (mb.competitors && mb.competitors.length) {
		midBet += '/' + mb.competitors[0].id;
	}

	return midBet;
};

export const buildMatchGroups = (matches, sortkey, props = {}) => {
	// requirements
	if (!isArray(matches)) {
		console.error('Expecting matches to be an array');
		return emptyArray;
	}

	if (matches.length === 0) {
		return emptyArray;
	}

	// sort matches by sport
	sortArrayByKey2(matches, 'idSport', sortkey);

	// get the type of the first match
	const mType = matches[0].mType;

	// check valid
	if (!(mType === 'live' || mType === 'prematch')) {
		console.error('invalid match type', matches[0]);
		return emptyArray;
	}

	// make sure all the matches have the same type
	for (let i = 1; i < matches.length; i++) {
		if (matches[i].mType !== mType) {
			console.error(`match ${i} doesn't have type ${mType}`, matches[i]);
			return emptyArray;
		}
	}

	// results
	const hmg = [];

	// start with an empty group
	let cgn = '';
	let cgm = [];

	for (let i = 0; i < matches.length; i++) {
		// group by sport/group/category
		//let mg = "mg_" + mType + "_" + matches[i].idSport + "_" + matches[i].idCategory;

		// group by sport only
		let mg = 'mg_' + mType + '_' + matches[i].idSport;

		// add custom suffix if specified
		if (props.suffix) {
			mg += '_' + props.suffix;
		}

		// set Winner Adv/Plus groups
		if (props.winnerPlus) mg = mg + '_wp';
		if (props.winnerAdv) mg = mg + '_wa';

		// if group changed
		if (cgn != mg) {
			// push whatever accumulated (if anything)
			if (cgm.length > 0) {
				hmg.push({
					group: cgn,
					idSport: cgm[0].idSport,
					mType: cgm[0].mType,
					matches: [...cgm]
				});
			}

			// set new group
			cgn = mg;

			// reset matches
			cgm = [];
		}

		// push match to group
		cgm.push(matches[i]);
	}

	// push last group if anything in it
	if (cgm.length > 0) {
		if (hmg.length === 0 && props.makeWinnerAdvIfAll) {
			let allWA = true;

			cgm.forEach(m => (allWA = allWA && m.winnerAdv));

			if (allWA && cgn.indexOf('_wa') === -1) {
				cgn += '_wa';
			}
		}

		hmg.push({
			group: cgn,
			idSport: cgm[0].idSport,
			mType: cgm[0].mType,
			matches: cgm
		});
	}

	return hmg;
};

// const ungroupableMarkets = ["103"]; // staging
const ungroupableMarkets = ['103', '3162', '3165', '3180', '3297', '3546', '3543', '4010'];

/* special markets groupping */
export const specialMarketsGrouping = (match, bets, mb) => {
	//debug("sMGdebug match", match); // debug
	//debug("sMGdebug mb (raw matchbets)", mb); // debug

	const bst = getBetsState(getStore().getState());
	const outcomeAbbr = bst.config.outcomeAbbr;

	// fill in appropriate mboDisplayName for special markets + additional prep
	let mbnames = [];
	let smlist = [];

	const mbres = [];
	const smhash = {};

	const counts = {};

	// count special markets
	for (let i = 0; i < mb.length; i++) {
		const bet = mb[i];

		if (bet.idBet in counts) {
			counts[bet.idBet]++;
		} else {
			counts[bet.idBet] = 1;
		}
	}
	//console.log("counts", counts);

	// replace
	for (let i = 0; i < mb.length; i++) {
		const bet = mb[i];

		// if there is a single special market let it as is
		if (counts[bet.idBet] === 1 || ungroupableMarkets.indexOf(bet.idBet) > -1) {
			mbres.push(bet);

			// next
			continue;
		}

		// check if market is groupable
		if (match.mType === 'live' && bets[match.idSport] && bet.idBet in bets[match.idSport] && !bets[match.idSport][bet.idBet].betGroupingEnabled) {
			mbres.push(bet);

			// next
			continue;
		}

		// if the market has no special values, store it as is
		if (!bet.mbSpecialValue || bet.mbSpecialValue === '*' || bet.mbSpecialValue.length === 0) {
			mbres.push(bet);

			// next
			continue;
		}

		let specialValue;

		if (Array.isArray(bet.mbSpecialValue)) {
			specialValue = bet.mbSpecialValue[0];
		} else {
			specialValue = bet.mbSpecialValue;
		}

		//console.log("specialValue", specialValue);

		// current market to proces
		let betres;

		if (bet.idBet in smhash) {
			// already have a structure for this special market
			betres = smhash[bet.idBet];
		} else {
			// copy bet structure since we'll probably modify it by adding other outcomes
			betres = { ...bet };

			// signal that it is groupped
			betres['isGrouped'] = true;

			// delete the special value - we'll include it in the outcome
			betres.mbSpecialValue = [];

			// reset oucomes since we'll add them below
			betres.mbOutcomes = [];

			// store the market so we'll append outcomes if the ID appear again
			smhash[bet.idBet] = betres;

			// append the market
			mbres.push(betres);
		}

		// process market outcomes
		const mbores = bet.mbOutcomes.map(odd => {
			let oddres = { ...odd };
			let outcome = odd.mboDisplayName; // preserve previous match bet outcome name to display, if any

			// if not, generate base name
			if (!outcome) {
				// modified copypasta from formatters/formatOddName
				if (outcomeAbbr[match.mType] && odd.idBo in outcomeAbbr[match.mType]) {
					outcome = outcomeAbbr[match.mType][odd.idBo];
				} else if (bets[match.idSport] && bet.idBet in bets[match.idSport]) {
					if (odd.idBo in bets[match.idSport][bet.idBet]['betOutcomes']) {
						outcome =
							bets[match.idSport][bet.idBet]['betOutcomes'][odd.idBo][
							'betOutcomeName'
							];
					} else {
						outcome = 'n/a';
					}
				}
			}

			// modify name for outcomes that belong to special markets
			if (specialValue) {
				if (outcome.indexOf(specialValue) === -1 && outcome.indexOf('H2') === -1) {
					oddres.mboDisplayName = specialValue + ' - ' + outcome;
				} else {
					oddres.mboDisplayName = outcome;
				}
			} else {
				oddres.mboDisplayName = outcome;
			}

			oddres.idMb = bet.idMb; // copy in outcome to preserve it on mergers (not the best solution)

			return oddres;
		});

		// sort outcomes
		sortArrayByKey(mbores, 'mboPosition');

		// append outcomes
		betres.mbOutcomes.push(...mbores);
	}

	//debug("sMGdebug mb (mboDisplayName filled)", mbres); // debug

	// find and merge duplicate special markets
	//
	// WARNING!
	//   - the values of mb[i].idMb are different for each of the to-be-merged outcomes, unsure how to handle
	//   - current "solution" is to only keep first one there,
	//     and have the previous name-assigning loop store the original value in each mb[i].mbOutcomes[j].idMb
	//

	return mbres;
};

export const marketsGrouping = (mb, groupableMarkets) => {
	const mbres = [];
	let smhash = null;

	const counts = {};

	// count special markets
	for (let i = 0; i < mb.length; i++) {
		const bet = mb[i];

		if (groupableMarkets.indexOf(bet.idBet) > -1) {
			if (typeof counts[bet.idBet] === 'undefined') counts[bet.idBet] = 1;
			counts[bet.idBet]++;
		} else {
			counts[bet.idBet] = 1;
		}
	}
	//console.log("counts", counts);

	// replace
	for (let i = 0; i < mb.length; i++) {
		const bet = mb[i];

		// if there a market of no interest let it as is
		if (counts[bet.idBet] === 1) {
			mbres.push(bet);

			// next
			continue;
		}

		// current market to proces
		let betres;

		if (smhash) {
			// already have a structure for this special market
			betres = smhash;
		} else {
			// copy bet structure since we'll probably modify it by adding other outcomes
			betres = { ...bet };

			// reset oucomes since we'll add them below
			betres.mbOutcomes = [];

			// store the market so we'll append outcomes if the ID appear again
			smhash = betres;

			// append the market
			mbres.push(betres);
		}

		// process market outcomes
		const mbores = bet.mbOutcomes.map(odd => {
			let oddres = { ...odd };
			oddres.idMb = bet.idMb; // copy in outcome to preserve it on mergers (not the best solution)
			return oddres;
		});

		// sort outcomes
		sortArrayByKey(mbores, 'mboPosition');

		// append outcomes
		betres.mbOutcomes.push(...mbores);
	}

	return mbres;
};

// possible TODO ?: make it cache somehow?
const combineableMarketsSetGeneration = (rules) => {
	const res = new Set();
	rules.forEach(rule => {
		res.add(`${rule.marketId1}cmb${rule.marketId2}`);
		res.add(`${rule.marketId2}cmb${rule.marketId1}`);
	});
	return res;
};

export const highlightCompatibleMarketCombination = (thisMatchId, thisMarketId, ticketSelectedBets) => {
	//debug("highlighting debug thisMarketId - ", thisMarketId); // debug
	//debug("highlighting debug ticketSelectedBets - ", ticketSelectedBets); // debug

	const bst = getBetsState(getStore().getState());
	const combineableMarkets = bst.nsoft.combiningAllowed;

	// const combineableMarkets = ?store import? // modify and uncomment when API available
	//const combineableMarketsSet = combineableMarketsSetGeneration(combineableMarkets);

	if (Array.isArray(ticketSelectedBets) && ticketSelectedBets.length > 0) {
		let res = true;
		let count = 0;

		// highlight this market only if all other selected bets have compatible markets to combine with
		for (let i = 0; i < ticketSelectedBets.length; i++) {
			const sb = ticketSelectedBets[i];

			if (sb.idMatch !== thisMatchId) {
				continue;
			}

			count++;

			if (`${sb.idBet}` !== `${thisMarketId}`) {
				if (sb.idBet in combineableMarkets) {
					if (!combineableMarkets[sb.idBet].find(m => m + '' === thisMarketId)) {
						return false;
					}
				} else {
					return false;
				}

				if (thisMarketId in combineableMarkets) {
					if (!combineableMarkets[thisMarketId].find(m => m + '' === sb.idBet)) {
						return false;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}
		};

		return res && count > 0;
	}

	// if no selected bets exist, there's nothing to highlight
	return false;
};
