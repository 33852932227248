import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../store';
import craftJsParser from '../../../components/utils/craftJsParser';

import { DataListItems } from '../DataListItems/DataListItems';
import { DataElementContext } from '../../common/DataElementContext';
import { PageDataContext, getProjectDataFromContext } from '../../../components/utils/PageDataProvider';
import { sortSources } from '../../utils/sortSource';

import { processComponentProps } from '@/page-components/utils/processComponentProps';



const DataListDiv = styled.div((props) => props.$styleText);

export const DataList = (componentProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const components = useAppSelector((state) => state.templatesConfig.components);
  const dataSources = useAppSelector((state) => state.dataSources.items);
  const pageDataContext = React.useContext(PageDataContext);

  const { dsId, items, useCmpRepeater, cmId, repeater } = props.properties;

  let ds = dataSources[dsId] != null ? dataSources[dsId] : null;

  const pValue = React.useMemo(() => {
    if (!isVisible) return { content: null, hasError: false };

    let hasError = false;
    let content = null;

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const dsWrapperId = data[props.nodeId].nodes[0];
      const dsWrapperData = data[dsWrapperId];

      let maxItems = 0;
      if (!isNaN(parseInt(items))) maxItems = parseInt(items);

      const itemContent = craftJsParser({
        craftState: data,
        rootNodeId: dsWrapperData.nodes[0],
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
      });

      ds = ds && ds.data ? ds.data : [];

      if (
        props.properties.externalList &&
        Array.isArray(props.properties.externalList) &&
        props.properties.externalList.length
      ) {
        ds = props.properties.externalList;
      }

      if (repeater && Array.isArray(repeater.items) && repeater.items.length) {
        ds = sortSources(ds, repeater);
      }

      const rendered =
        ds?.slice(0, maxItems < 0 ? ds?.length : maxItems).map((orig, i) => {
          let item = orig;
          let tmp = itemContent;
          if (useCmpRepeater) {
            let renderCmId = cmId;
            let className = '';

            if (repeater && Array.isArray(repeater.items) && repeater.items.length) {
              let repeaterItemConfig = null;

              if (repeater.type === 'repeat_last') {
                // if in mode "repeat_last" so for every item in the list on a position bigger than the
                // repeater config list will have the config from teh last position
                if (i > repeater.items.length - 1) {
                  repeaterItemConfig = repeater.items[repeater.items.length - 1];
                } else {
                  repeaterItemConfig = repeater.items[i];
                }
              } else if (repeater.type === 'repeat_last_n') {
                const n = repeater.n;
                if (n && !isNaN(n) && n <= repeater.items.length) {
                  if (i > repeater.items.length - 1) {
                    const offset = repeater.items.length - n;
                    const pos = i % n;
                    repeaterItemConfig = repeater.items[pos + offset];
                  } else {
                    repeaterItemConfig = repeater.items[i];
                  }
                }
              } else {
                // is in mode "repeat_all", so for every item in the list on a position bigger than the
                // repeater config list will have the config from the position that will normally be when
                // repeating the entire list
                const pos = i % repeater.items.length;
                repeaterItemConfig = repeater.items[pos];
              }

              if (repeaterItemConfig && repeaterItemConfig.cmId) {
                renderCmId = repeaterItemConfig.cmId;
              }
              if (repeaterItemConfig && repeaterItemConfig.className) {
                className = repeaterItemConfig.className;
              }
            }

            if (renderCmId && components[renderCmId] != null) {
              tmp = React.cloneElement(itemContent, {
                cmId: renderCmId,
                rClassName: className,
              });
            }
          }

          if (Object.isFrozen(item)) {
            item = { ...item };
          }

          item.parentContext = dataElementContext;

          return (
            <DataElementContext.Provider key={i} value={item}>
              {tmp}
            </DataElementContext.Provider>
          );
        }) || [];

      content = <DataListItems {...dsWrapperData.props}>{rendered}</DataListItems>;
    } catch (err) {
      console.error(err);
      hasError = true;
    }
    return { content, hasError };
  }, [
    isVisible,
    pageDataContext,
    components,
    dsId,
    items,
    useCmpRepeater,
    cmId,
    repeater,
    ds,
    props.nodeId,
    props.properties.externalList,
    props.refType,
    props.refId,
  ]);

  if (!isVisible) return null;
  if (pValue.hasError) return null;
  if (!pValue.content) return null;

  return (
    <DataListDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      {pValue.content}
    </DataListDiv>
  );
};
