import React, { useContext } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import { applyLinksValues } from '../../../page-components/utils/applyLinksValues';
import { evaluatePropFunction } from '../../../page-components/utils/evaluatePropFunction';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

type ObserverProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Observer = (componentProps: ObserverProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dataElementContext = useContext(DataElementContext);
  if (dataElementContext && props?.properties['links'] && Object.values(props?.properties['links']).length) {
    applyLinksValues(props?.properties, dataElementContext);
  }

  if (props?.properties && props?.properties['propFunction'] != null && props?.properties['propFunction'])
    evaluatePropFunction(props, dataElementContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: props.properties.threshold ?? 0,
    // @ts-ignore
    root: document,
    rootMargin: props.properties.rootMargin ?? '0px 0px 0px 0px',
    triggerOnce: props.properties.triggerOnce ?? false,
    onChange: props.properties.onChange ?? undefined,
    delay: props.properties.delay ?? undefined,
    initialInView: props.properties.initialInView ?? false,
  });

  //console.log('Observer', inView, entry);

  const contextValue = React.useMemo(() => {
    const isIntersecting = entry?.isIntersecting ?? inView;
    let direction = 'in';
    if (!isIntersecting && entry?.boundingClientRect) {
      const { top, bottom } = entry.boundingClientRect;
      if (top > 0) {
        direction = 'above';
      } else if (bottom < 0) {
        direction = 'below';
      }
    }

    return {
      inView,
      direction,
      parentContext: dataElementContext,
    };
  }, [dataElementContext, inView, entry]);

  let content = null;
  if (props.properties.position === 'top') {
    content = (
      <React.Fragment>
        <div ref={ref} className="int-probe"></div>
        {children}
      </React.Fragment>
    );
  } else if (props.properties.position === 'bottom') {
    content = (
      <React.Fragment>
        {children}
        <div ref={ref} className="int-probe"></div>
      </React.Fragment>
    );
  } else if (props.properties.position === 'center') {
    content = (
      <div ref={ref} className="int-probe">
        {children}
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        {children}
        <div ref={ref} className="int-probe"></div>
      </React.Fragment>
    );
  }

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DataElementContext.Provider value={contextValue}>{content}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Observer;
