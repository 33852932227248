import Utils from '@/modules/casino/utils/Utils';
import { initiateBridgerApplePayDeposit, updateBridgerApplePayDeposit } from '@/modules/casino/store/actions/deposit';
import { BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY, PaymentProvider } from '@/constants/paymentProvider';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

const APPLE_PAY_PLACEHOLDER = 'bridger-apple-pay-placeholder';

class BridgerApplePay {
  private amount: any;
  private bonusId: any;
  private rewardId: any;
  private triggerSuccess: any;
  private triggerError: any;
  private dispatch: any;
  private cashierKey: any;
  private cashierToken: any;

  constructor(data: any) {
    this.init(data);
  }

  startSession() {
    const LS_KEY = 'apple_session_started_semaphor';
    if(ExpiringLocalStorage.get(LS_KEY)) {
        return;
    }
    ExpiringLocalStorage.set(LS_KEY,'1',1);
    if (!this.isApplePayAvailable()) return;
    if (this.amount > 0) {
      if (!(this.cashierKey && this.cashierToken)) {
        if(window.config.depositLogs === '1') {
          console.log('[DEPOSIT LOGS] startSession',this.isApplePayAvailable(),this.amount,!(this.cashierKey && this.cashierToken));
        }
        this.dispatch(
          initiateBridgerApplePayDeposit({
            amount: this.amount * 100,
            bonusId: this.bonusId || null,
            rewardId: this.rewardId || null,
          }),
        );
      }
    }
  }

  updateSession() {
      if (window.config.depositLogs) {
          console.log('[DEPOSIT LOGS] updateSession',this.isApplePayAvailable(),this.amount);
      }
    if (!this.isApplePayAvailable()) return;
    if (this.amount > 0) {
      this.dispatch(
        updateBridgerApplePayDeposit({
          amount: this.amount * 100,
          bonusId: this.bonusId || null,
          rewardId: this.rewardId || null,
          // cashierKey: this.cashierKey || null,
        }),
      );
    }
  }

  injectAppleScript(data: any): void {
    this.destroy();
      if(window.config.depositLogs === '1') {
          console.log('[DEPOSIT LOGS] Apple Pay start injectAppleScript');
      }
    Utils.injectScript(
      'https://ng-checkout.bridgerpay.com/v2/launcher',
      'bridger-apple-pay',
      () => {
        const { cashierKey, cashierToken } = data;
        this.cashierKey = cashierKey;
        this.cashierToken = cashierToken;
        if(window.config.depositLogs === '1') {
            console.log('[DEPOSIT LOGS] Apple Pay script injected', cashierKey, cashierToken);
        }
        //listen for message
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-result`,
          (e: any) => {
              if(window.config.depositLogs === '1') {
                  console.log('[DEPOSIT LOGS] Apple Pay wallet-result', e);
              }
            const result = e.detail;
            if (result.status === 'approved') {
              this.triggerSuccess();
            } else {
                this.triggerError({error: e});
            }
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-cancelled`,
          (e:any) => {
              if(window.config.depositLogs === '1') {
                  console.log('[DEPOSIT LOGS] Apple Pay wallet-canceled', e);
              }
              this.triggerError({error: e});
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:no-wallet-provider-found`,
          (e:any) => {
              if(window.config.depositLogs === '1') {
                  console.log('[DEPOSIT LOGS] Apple Pay no-wallet-provider-found', e);
              }
            this.triggerError({error: e.type, soft: true});
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-provider-cannot-be-used`,
            (e:any) => {
                if(window.config.depositLogs === '1') {
                    console.log('[DEPOSIT LOGS] Apple Pay wallet-provider-cannot-be-used', e);
                }
            this.triggerError({error:e, soft: true});
            ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-shipping-contact-update`,
          (e: any) => {
              if(window.config.depositLogs === '1') {
                  console.log('[DEPOSIT LOGS] Apple Pay wallet-shihpping-contact-update', e);
              }
            if (e.error || e.detail?.error) {
                this.triggerError({error: e.error ?? e.detail?.error});
              ExpiringLocalStorage.remove(BRIDGER_APPLE_PAY_LOCAL_STORAGE_KEY);
            }
          },
          false,
        );
        window.addEventListener(
          `[bp][checkout:${this.cashierKey}]:wallet-button-is-ready`,
          (e) => {
              if(window.config.depositLogs === '1') {
                  console.log('[DEPOSIT LOGS] Apple Pay wallet-button-is-ready', e);
              }
            // setTimeout(() => {
            //   this.sendAmountToApplePay(data.amount);
            // }, 200);
          },
          false,
        );
      },
      {
        data: {
          'cashier-key': data.cashierKey,
          'cashier-token': data.cashierToken,
          'button-mode': 'wallet',
        },
        container: APPLE_PAY_PLACEHOLDER,
      },
    );
  }

  destroy(): void {
    // remove apple pay script and apple pay button
    const applePayButton = document.getElementById(APPLE_PAY_PLACEHOLDER);
    if (applePayButton) {
      applePayButton.innerHTML = '';
    }
  }

  init(data: any): void {
    this.triggerSuccess = data.triggerSuccess;
    this.triggerError = data.triggerError;
    this.dispatch = data.dispatch;
    this.cashierKey = data.cashierKey;
    this.cashierToken = data.cashierToken;
  }

  setAmount(amount: number): void {
    this.amount = amount;
    // this.sendAmountToApplePay(); this was moved to saga!
  }

  // sendAmountToApplePay(amount: number = 0): void {
  //   let newAmount = amount;
  //   if (!amount) {
  //     newAmount = this.amount;
  //   }
  //
  //   if (newAmount) {
  //     window.dispatchEvent(
  //       new CustomEvent(`[bp][checkout:${this.cashierKey}]:wallet-update-amount`, {
  //         detail: {
  //           newAmount: newAmount,
  //         },
  //       }),
  //     );
  //   }
  // }

  setBonusId(bonusId: number | null): void {
    this.bonusId = bonusId;
  }

  setRewardId(rewardId: number | string | null): void {
      this.rewardId = rewardId;
  }

  setPaymentDetails(data: any): void {
    const oldBonus = this.bonusId && !isNaN(this.bonusId) ? parseInt(this.bonusId, 10) : 0;
    const newBonus = data.bonusId && !isNaN(data.bonusId) ? parseInt(data.bonusId, 10) : 0;
    const oldReward = this.rewardId && !isNaN(this.rewardId) ? parseInt(this.rewardId, 10) : 0;
    const newReward = data.rewardId && !isNaN(data.rewardId) ? parseInt(data.rewardId, 10) : 0;

    if (this.amount !== data.amount || oldBonus !== newBonus || oldReward !== newReward) {
      this.setAmount(data.amount);
      this.setBonusId(data.bonusId);
      this.setRewardId(data.rewardId);
      this.updateSession();
    }
  }

  isApplePayAvailable() {
    if (
      //@ts-ignore
      window.ApplePaySession &&
      window.config.depositSettings?.providers?.[PaymentProvider.bridger].isApplePayActive === '1'
    ) {
      //@ts-ignore
      if (window.ApplePaySession.canMakePayments()) {
        return true;
      }
    }
    return false;
  }

  onApplePayButtonClicked = () => {};

  // return (<div className="apple-pay-button apple-pay-button-white-with-line add-money" onClick={onApplePayButtonClicked}></div>);
}

export default BridgerApplePay;
