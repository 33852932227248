import { debug } from '../../utils';
import { getWinnerFunBoostNum } from '../../../winner-fun/store/selectors/winnerFunData';
import { formatBetTitle, formatOddName } from './formatters';
import { getBetsState } from '../../store/selectors/betData';

export const processBonusData = (state, data) => {
  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    return { authOk: false, data };
  }

  if (state.profile.client_player_id === null) {
    return { authOk: false, data };
  }

  const betsProductId = 2;

  data['category'] = 'bets';
  data['user_id'] = window.config.clientId + '_' + state.profile.client_player_id;
  data['free_money_balance'] = state.wallet.main;

  if (state.bets && state.bets.betsSlip && state.bets.betsSlip.selectedFreeBet > -1) {
    if (state.bets.betsSlip.selectedFreeBetData) {
      data['free_money_balance'] = state.bets.betsSlip.selectedFreeBetData.amount / 100;
    }
  }

  data['bonus'] = state.wallet.bonuses
    ? state.wallet.bonuses.filter((w) => w.eligibleProducts.find((ep) => ep === betsProductId))
    : [];
  data['ring_fence'] = state.wallet.ringFences
    ? state.wallet.ringFences.filter((w) => w.eligibleProducts.find((ep) => ep === betsProductId))
    : [];

  return { authOk: true, data };
};

export const processWinnerFunData = (state, data) => {
  debug('eval winner fun saga', data);

  //debug("auth", state.authentication, "profile", state.profile);

  if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
    debug('not authenticated');
    return { authOk: false, data };
  }

  if (state.profile.client_player_id === null) {
    return { authOk: false, data };
  }

  const betsProductId = 2;

  data['category'] = 'bets';
  data['user_id'] = window.config.clientId + '_' + state.profile.client_player_id;
  data['main_amount'] = state.winnerFun.wallet.main;
  data['secondary_amount'] = state.winnerFun.wallet.secondary;
  data['boost_num'] = getWinnerFunBoostNum(state);

  debug('winnerFun eval', data);

  return { authOk: true, data };
};

export const normalizeBets = (state, bets) => {
  const bst = getBetsState(state);
  if (!bst) {
    debug('no bets state');
    return [];
  }

  let ro = bets.map((b) => {
    if (!bst[b.mType]) {
      debug(`no bets state for ${b.mType}`);
      return null;
    }

    const st = bst[b.mType];

    if (!(st.matches && st.matches[b.idMatch])) {
      debug('no match');
      return null;
    }
    const m = st.matches[b.idMatch];

    let s = null;
    if (st.sports && st.sports[b.idSport]) {
      s = st.sports[b.idSport];
    } else {
      debug('no sport', b, st.sports);
    }

    let c = null;
    if (st.categories && st.categories[m.idCategory]) {
      c = st.categories[m.idCategory];
    } else {
      debug('no category', m, st.categories);
    }

    let t = null;
    if (st.tournaments && st.tournaments[m.idTournament]) {
      t = st.tournaments[m.idTournament];
    } else {
      debug('no tournament', m, st.tournaments);
    }

    debug(m, s, c, t);

    const mb = m.matchBets.find((mb) => mb.idMb === b.idMb);
    let mo = null;
    if (mb) {
      mo = mb.mbOutcomes.find((mo) => mo.idMbo === b.idMbo);
    }

    return {
      idSport: b.idSport,
      sportName: s ? s.sportName : 'unknown sport',
      idCategory: m.idCategory,
      categoryName: c ? c.categoryName : 'unknown category',
      idTournament: m.idTournament,
      tournamentName: t ? t.tournamentName : 'unknown tournament',
      idMatch: b.idMatch,
      team1Name: m.team1Name,
      team2Name: m.team2Name,
      idBet: b.idBet,
      betName: mb ? formatBetTitle(state, mb, m, st.bets) : 'unknown bet',
      idMb: b.idMb,
      idBo: b.idBo,
      idMbo: b.idMbo,
      outcomeName: mo ? formatOddName(state, b.idBet, mo, m, st.bets) : 'unknown odd',
      value: b.odd,
      brId: m.brId,
      betWinnerPlus: mb ? mb.winnerPlus : false,
      betWinnerAdv: mb ? mb.winnerAdv : false,
      betWinnerSpecial: mb ? mb.winnerSpecial : false,
      matchWinnerPlus: m.winnerPlus,
      matchWinnerAdv: m.winnerAdv,
      matchWinnerSpecial: m.winnerSpecial,
      betType: b.betType,
    };
  });

  return ro.filter((o) => o !== null);
};

export const formatMoneyValue = (ttype, v) => {
  let sv = null;

  if (ttype === 'core') {
    sv = Math.round(v * 100);
  } else {
    sv = parseFloat(v).toFixed(2);
    sv.replace(/(\.0+)$/, '');
  }

  return sv;
};

export const round2 = (v) => Math.round(v * 100) / 100;
