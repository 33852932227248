import React from 'react';

import './index.scss';

const PageLoader = () => {
  const style = {
    backgroundColor: window.config.loaderBackgroundColor ?? '#fff',
  };

  const p = React.useMemo(() => ({
    display: 'flex',
    width: window.config.progressWidth ? window.config.progressWidth : '250px',
    height: window.config.progressHeight ? window.config.progressHeight : '1rem',
    overflow: 'hidden',
    backgroundColor: window.config.progressBG ? window.config.progressBG : '#e9ecef',
    borderRadius: '0.25rem',
  }), []);

  const cp = window.config._cp ?? 8;
  const st = parseFloat(window.config.progressStep ?? '.2');
  const prg = Math.round(Math.atan(cp + st) / (Math.PI / 2) * 1e5) / 1e3;

  const pba = React.useMemo(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: prg + '%',
    backgroundColor: window.config.progressBarBG ? window.config.progressBarBG : '#007bff',
    backgroundImage: window.config.progressBarStriped ? window.config.progressBarStriped : 'linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)',
    backgroundSize: window.config.progressHeight ? `${window.config.progressHeight} ${window.config.progressHeight}` : '1rem 1rem',
  }), []);

  React.useEffect(() => {
    let tid = 0;
    let cp = window.config._cp ?? 80;
    let st = parseFloat(window.config.progressStep ?? '.2');
    if (window.config.progressShow) {
      tid = setInterval(() => {
        cp += st;
        let prg = Math.round(Math.atan(cp) / (Math.PI / 2) * 1e5) / 1e3;
        let t = document.getElementById('_pb2025');
        t ? (t.style.width = prg + '%', t.setAttribute('aria-valuenow', prg), prg >= 100 ? clearInterval(tid) : prg >= 70 && (st = .1)) : clearInterval(tid);
      }, 100);
    }

    return () => {
      clearInterval(tid);
    };

  }, []);

  return (
    <div className="page-loader w-100 h-100" style={style}>
      <img src={window.config.loaderImage} alt="loader" />
      {Boolean(window.config.loaderText) && <div className="text">{window.config.loaderText}</div>}
      {window.config.progressShow === '1' && (
        <div style={p}>
          <div id="_pb2025" className="pba" style={pba} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      )}
    </div>
  );
};

export default PageLoader;
