import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import * as actionTypes from '../actions/actionTypes';
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';
import Utils from '@/modules/casino/utils/Utils';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker.js';
import { receivedTax } from '@/modules/casino/store/actions/withdrawals.js';

export function* requestPendingWithdrawalsSaga(action) {
  const axios = ClientAPI.getInstance();
  let url = paths.withdrawals.PENDING_WITHDRAWALS;
  let type = 'approved';
  if (action.type === actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS) {
    url = paths.withdrawals.PENDING_VIVA_WITHDRAWALS;
    type = 'pending';
  }
  try {
    const response = yield axios({
      url: url,
      method: 'get',
    });
    if (!response) {
      throw new Error('[ERROR] Pending Withdrawals response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Pending Withdrawals response has no 'result' property");
    }

    yield put(actions.receivedPendingWithdrawals(response, type));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestLocationsSaga(action) {
  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.withdrawals.REQUEST_LOCATIONS,
      method: 'get',
    });

    if (!response) {
      throw new Error('[ERROR] Request Locations response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Locations response has no 'result' property");
    }

    yield put(actions.receivedLocations(response));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestTaxServiceSaga(action) {
  const axios = ClientAPI.getInstance();
  let amount = action.amount;
  let taxType = action.taxType;
  let id = action.id;

  let data = {
    type: taxType,
    amount: amount,
  };

  if (id) {
    data.id = id;
  }

  if (!(amount > 0)) {
    return;
  }

  if (!taxType) {
    taxType = 1;
  }

  amount = parseFloat(amount).toFixed(2);

  try {
    const response = yield axios({
      url: paths.withdrawals.REQUEST_TAX_SERVICE,
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request TaxService response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request TaxService response has no 'result' property");
    }

    if (id) {
      response.result.id = id;
    }

    if (taxType === 2) {
      response.result.type = taxType;
    }

    yield put(actions.receivedTaxService(response));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestCancelWithdrawalSaga(action) {
  const axios = ClientAPI.getInstance();
  let amount = action.amount;
  let withdrawId = action.withdrawId;
  let url = paths.withdrawals.REQUEST_CANCEL_WITHDRAWAL;
  let data = {
    amount: amount,
    withdrawId: withdrawId,
  };
  if (action.type === actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL) {
    url = paths.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL;
    data = {
      amount: amount,
      vivaWithdrawId: withdrawId,
    };
  } else if (action.type === actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL_NEW) {
    url = paths.withdrawals.REQUEST_CANCEL_WITHDRAWAL_NEW;
    data = {
      amount: amount,
      withdrawId: withdrawId,
      withdrawType: action.withdrawType,
    };
  }
  try {
    const response = yield axios({
      url: url,
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Request CancelWithdrawal response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request CancelWithdrawal response has no 'result' property");
    }
    yield put(actions.receivedCancelWithdrawal(response));
    yield put(actions.requestWallet());
    yield put(actions.requestAllWithdrawals());
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* downloadCSVSaga(action) {
  const axios = ClientAPI.getInstance();
  try {
    const response = yield axios({
      url: paths.withdrawals.DOWNLOAD_CSV,
      method: 'post',
      responseType: 'blob',
      data: {
        type: action.historyType,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        export: true,
      },
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestVerificationCodeSaga(action) {
  const axios = ClientAPI.getInstance();
  let withdrawID = action.withdrawID;

  try {
    const response = yield axios({
      url: paths.withdrawals.REQUEST_VERIFICATION_CODE,
      method: 'post',
      data: {
        withdrawID: withdrawID,
      },
    });

    if (!response) {
      throw new Error('[ERROR] Request Verification Code response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Verification Code response has no 'result' property");
    }

    yield put(actions.receivedVerificationCode(response, withdrawID));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* getPaymentMethodsSaga(action) {
  const axios = yield Utils.getAxiosWithToken();

  try {
    const response = yield axios({
      url: paths.withdrawals.GET_PAYMENT_METHODS,
      method: 'get',
    });
    if (!response) {
      throw new Error('[ERROR] Get Payment Methods response is empty!');
    }

    if (!response?.result) {
      throw new Error("[ERROR] Get Payment Methods response has no 'result' property");
    }
    yield put(actions.receivedPaymentMethods(response));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* linkIBANSaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  let data = {
    iban: action.iban,
    beneficiaryName: action.name,
  };
  if (action.friendlyName) {
    data = {
      ...data,
      friendlyName: action.friendlyName,
    };
  }

  try {
    const response = yield axios({
      url: paths.withdrawals.LINK_IBAN,
      method: 'post',
      data: data,
    });
    if (!response) {
      throw new Error('[ERROR] Link IBAN response is empty!');
    }

    if (!response?.result) {
      throw new Error("[ERROR] Link IBAN response has no 'result' property");
    }
    yield put(actions.receivedWithdraw(response.result));
    if (!response.result.ResponseCode && !response.result.http) {
      yield put(actions.getPaymentMethods());
    }
  } catch (error) {
    console.log(error);
    yield put(actions.receivedWithdraw({ ResponseCode: 1 }));
    return;
  }
}

export function* withdrawSaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  // get currency from store
  const currency = yield ClientAPI.getStore().getState().currencies.currentCurrency ?? window.config?.defaultCurrency;

  let data = {
    amount: action.amount * 100, // send in cents
    currencyCode: currency,
    withdrawType: action.withdrawType,
    withdrawTypeReference: action.withdrawTypeReference,
  };

  let pac = PlayerAbuseChecker.getInfo();
  data = {
    ...data,
    ...pac,
  };

  try {
    actions.receivedWithdraw({ reset: true });
    const response = yield axios({
      url: paths.withdrawals.WITHDRAW,
      method: 'post',
      data: data,
    });
    if (!response) {
      throw new Error('[ERROR] Withdraw response is empty!');
    }

    if (!response?.result) {
      throw new Error("[ERROR] Withdraw response has no 'result' property");
    }
    yield put(actions.requestWallet());
    yield put(actions.receivedWithdraw(response.result));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* requestWithdrawHistorySaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  let type = 'history';
  try {
    const response = yield axios({
      url: paths.withdrawals.GET_WITHDRAW_HISTORY,
      method: 'get',
    });
    if (!response) {
      throw new Error('[ERROR] Pending Withdrawals response is empty!');
    }

    if (!response?.result) {
      throw new Error("[ERROR] Pending Withdrawals response has no 'result' property");
    }

    yield put(actions.receivedPendingWithdrawals(response, type));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* betlionWithdrawDetailsSaga(action) {
  const axios = yield Utils.getAxiosWithToken();


  try {
    const response = yield axios({
      url: paths.withdrawals.BETLION_WITHDRAW_DETAILS,
      method: 'post',
      data: action.data,
    });
    if (!response) {
      throw new Error('[ERROR] betlionWithdrawDetails response is empty!');
    }

    if (!response.result) {
      throw new Error("[ERROR] betlionWithdrawDetails response has no 'result' property");
    }
    if(!response.result.withdraw_reference_id) {
        throw new Error("[ERROR] betlionWithdrawDetails response has no 'withdraw_reference_id' property");
    }

    yield put(actions.receivedBetLionWithdrawDetails(response.result)); 

  } catch (error) {
    console.log(error);
    yield put(actions.receivedWithdraw({ ResponseCode: 1 }));
    return;
  }
}

export function* getWithdrawTaxSaga(action) {
  const axios = ClientAPI.getInstance();
  try {
    const url = window.config.useLegacyTaxation === '1' ? '/api/profile/calculateTax' : '/api/profile/calculateTaxes';
    let data  = {};
    let result = {};
    if(window.config.useLegacyTaxation === '1') {
      data = {
        amount: action.data[0].totalAmount/100,
        type: action.data[0].taxType,
        key: action.data[0].key,
      };

    } else {
      data = {
        taxes: action.data
      };
    }
    const response = yield axios({
      url: url,
      method: 'POST',
      data: data,
    });
    if (!response.status) {
      throw new Error('[Deposit Tax] No status response');
    }

    if (!('OK' === response.status)) {
      throw new Error('[DepositTax] Status NOK');
    }
    if (!response.result) {
      throw new Error('[DepositTax] Empty result');
    }


    if (window.config.useLegacyTaxation === '1') {
      result = {
        [data.key]: {
          rawTaxAmount: response.result.tax*100,
          taxAmount: response.result.tax*100,
          originalAmount: data.amount*100,
          amountAfterTax: (response.result.amount - response.result.tax) * 100,

        }
      };

    } else {
      result = response.result;
    }

    yield put(receivedTax(result));
  } catch (e) {
    console.log('[DepositTax]', e);
    // yield put(betLionGetChannelsFailed());
  }
}