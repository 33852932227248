import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Axios, { AxiosResponse } from 'axios';
import { RootState } from '..';

export interface IdleGameRecommendationReducer {
  isOpen: boolean;
  recommendedGame: Array<any>;
  isLoading: boolean;
}

const apiUrl: string = window.config.betsApiUrl + '/api/search/v1/player/recommended-games/';
const IGR_KEY: string = 'recommendedGames';

export const openIGRPopupAsync = createAsyncThunk<
  { recommendedGame: any; isOpen: boolean; isLoading: boolean },
  void,
  { state: RootState }
>('idleGameRecommendation/openIGRPopup', async (_, { getState, rejectWithValue }) => {
  const expireDate: number = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
  let rGames: Array<string> | null = ExpiringLocalStorage.get(IGR_KEY);
  const state: RootState = getState();

  if (!rGames) {
    ExpiringLocalStorage.set(IGR_KEY, ['0'], expireDate);
    rGames = ['0'];
  }

  const responsePromise: Promise<AxiosResponse<any>> = Axios.post(
    apiUrl,
    { game_ids: rGames },
    {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
        'Content-Type': 'application/json',
      },
    },
  );

  const response: AxiosResponse<any> = await responsePromise;

  let currentGame: string = '';
  let recommendedGame: any | null = null;
  let gameList: any | null = null;

  if (response?.data?.data?.length > 0) {
    gameList = response.data.data;

    if (window.location.pathname.includes('slot-game')) {
      currentGame = window.location.pathname.split('/')[2];
    }

    if (currentGame) {
      recommendedGame = gameList.find(
        (game: any) => !rGames?.includes(game?.id.toString()) || !rGames?.includes(currentGame),
      );
    } else {
      recommendedGame = gameList.find((game: any) => !rGames?.includes(game?.id.toString()));
    }
  }

  if (!response.data) {
    return rejectWithValue({ error: 'No data returned from API' });
  }

  if (recommendedGame) {
    ExpiringLocalStorage.set(IGR_KEY, [...rGames, recommendedGame.id.toString()], expireDate);
    return { recommendedGame, isOpen: true, isLoading: false };
  } else {
    ExpiringLocalStorage.set(IGR_KEY, ['0'], expireDate);
    return { recommendedGame: gameList[0], isOpen: true, isLoading: false };
  }
});

export const idleGameRecommendationSlice = createSlice({
  name: 'idleGameRecommendation',
  initialState: <IdleGameRecommendationReducer>{
    isOpen: false,
    recommendedGame: [],
    isLoading: false,
  },
  reducers: {
    closeIGRPopup(state): void {
      state.isOpen = false;
    },
    setIsLoading(state, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(openIGRPopupAsync.pending, (state): void => {
        state.isLoading = true;
        state.isOpen = true;
      })
      .addCase(openIGRPopupAsync.fulfilled, (state, action): void => {
        state.isOpen = action.payload.isOpen;
        state.recommendedGame = action.payload.recommendedGame;
        state.isLoading = action.payload.isLoading;
      });
  },
});

export const { closeIGRPopup, setIsLoading } = idleGameRecommendationSlice.actions;

export default idleGameRecommendationSlice.reducer;
