import * as actionTypes from './../actions/actionTypes';
import * as LoginMethods from './../../utils/LoginMethods';

const initialState = {
  username: null,
  password: null,
  method: LoginMethods.IS_UNDEFINED,
  steps: ['username', 'password', 'result'],
  step: 0,
  errorUsername: false,
  errorPassword: false,
  loginError: false,
  allowUsernameButton: false,
  allowLoginButton: false,
  inRequest: false,
  allowSMS: true,
  etaSmsRequest: 0,
  needsResetPassword: false,
  usersMigrationInProgress: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.login.SET_USERNAME:
      return {
        ...state,
        username: action.username,
        errorUsername: action.error,
        method: action.method,
        allowUsernameButton: action.allowUsernameButton,
      };
    case actionTypes.login.ERROR_USERNAME:
      return {
        ...state,
        errorUsername: action.error,
        allowUsernameButton: true,
      };
    case actionTypes.login.ERROR_PASSWORD:
      return {
        ...state,
        errorPassword: action.error,
      };
    case actionTypes.login.ERROR_LOGIN:
      return {
        ...state,
        loginError: action.loginError,
      };
    case actionTypes.login.STEP:
      return {
        ...state,
        step: action.step,
      };
    case actionTypes.login.SET_PASSWORD:
      return {
        ...state,
        password: action.password,
        errorPassword: action.errorPassword,
        allowLoginButton: action.allowLoginButton,
      };
    case actionTypes.login.IN_REQUEST:
      let allowButton = false;
      if (!action.value) {
        allowButton = true;
      }
      return {
        ...state,
        inRequest: action.value,
        allowLoginButton: allowButton,
      };
    case actionTypes.login.ALLOW_SMS_REQUEST:
      return {
        ...state,
        allowSMS: action.value,
      };
    case actionTypes.login.SMS_ETA:
      return {
        ...state,
        etaSmsRequest: action.eta,
      };
    case actionTypes.login.NEEDS_RESET_PASSWORD:
      if (!action.data) {
        return {
          ...state,
          needsResetPassword: false,
        };
      } else {
        return {
          ...state,
          step: 2,
          inRequest: false,
          needsResetPassword: true,
          username: action.data,
        };
      }

    case actionTypes.login.USERS_MIGRATION_IN_PROGRESS:
      return {
        ...state,
        usersMigrationInProgress: action.data,
      };
    case actionTypes.login.METHOD:

        return {
            ...state,
            method: action.method,
        };
    default:
      return state;
  }
};

export default reducer;
