import React, { useContext } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash-es';
import { useControls, levaStore } from 'leva';
import { useMatches } from 'react-router-dom';

import { useAppSelector } from '@/store';
import { DataElementContext } from '../common/DataElementContext';
import icons from './lib/icons';
import resolveDataElement from '../../utils/data-element-api-cache';
import genericDataSource from '../../utils/generic-data-source-api-cache';

import { processComponentProps } from '@/page-components/utils/processComponentProps';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
  properties: {
    dsType: '',
    deId: '',
  },
};

interface CustomizerProps {
  className: string;
  $styleText: string;
  style?: any;
  properties?: {
    dsType: string;
    deId: string;
  };
  children?: JSX.Element;
}

const CustomizerDiv = styled.div<CustomizerProps>((props) => props.$styleText);

const calculateControls = (props: any, defaults: any) => {
  const data: any = {};

  let defaultValues: any = {};
  if (defaults) {
    try {
      const tmp = JSON.parse(defaults);
      if (tmp != null && typeof tmp === 'object') {
        defaultValues = tmp;
      }
    } catch (e) {}
  }

  (props ?? []).forEach((prop: any, index: number) => {
    if (prop.type === 'boolean') {
      data[prop.id] = {
        value: defaultValues[prop.id] ?? false,
        order: index,
      };
    } else if (prop.type === 'float' || prop.type === 'integer') {
      data[prop.id] = {
        value: defaultValues[prop.id] ?? 0,
        order: index,
      };
    } else if (prop.type === 'string') {
      if (prop.id.startsWith('on') && !prop.id.startsWith('onLabel')) return;

      data[prop.id] = {
        value: defaultValues[prop.id] ?? '',
        order: index,
      };

      if (prop.kind === 'one_of') {
        data[prop.id] = {
          value: defaultValues[prop.id] ?? '',
          options: ['', ...prop.values],
          order: index,
        };
      } else if (prop.id.toLowerCase()?.includes('bootstrap')) {
        data[prop.id] = {
          value: defaultValues[prop.id] ?? '',
          options: ['', ...icons],
          order: index,
        };
      }
    }
  });

  return data;
};

export const Customizer = (componentProps: any) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);
  const controlSpecs = calculateControls(props?.properties?.dsProperties, props?.properties?.dsDefaults);
  const controls = useControls(controlSpecs);

  React.useEffect(() => {
    return () => {
      levaStore?.dispose?.();
    };
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...controls,
    };
  }, [componentProps, controls]);

  if (!isVisible) return null;

  //console.log('Customizer[contextValue]', contextValue, props.properties, componentProps.children);

  return (
    <CustomizerDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </CustomizerDiv>
  );
};
