import { FileExtensionItem, FileSizeUnitItem, FileSizeUnits } from '../constants';
import { FileValidationErrorsMap } from '../types';

export function clearSpaces(text: string): string {
  return text.replaceAll(' ', '');
}

export function normalizeExtensions(extensions: string | string[]): FileExtensionItem[] {
  if (typeof extensions === 'string') {
    extensions = clearSpaces(extensions).split(',');
  }

  return extensions.map((extension) => {
    extension = extension.toLowerCase();

    if (extension.startsWith('.')) {
      return extension as FileExtensionItem;
    } else {
      return `.${extension}` as FileExtensionItem;
    }
  });
}

export function formatExtensionsForTooltip(extensions: FileExtensionItem[]): string {
  return extensions
    .map((extension) => {
      const cleanExtension = extension.split('.').pop() ?? '';

      return cleanExtension.toUpperCase();
    })
    .join(', ');
}

export function formatExtensionsForInputAttribute(extensions: FileExtensionItem[]): string {
  return extensions.join(',');
}

export function formatFileSize(size: string | number, unit?: FileSizeUnitItem): string {
  if (typeof size === 'string') {
    return clearSpaces(size).toUpperCase();
  }

  let index = -1;

  do {
    size / 1024;
    index++;
  } while (size > 1024);

  return `${size}${FileSizeUnits[index]}`;
}

export function formatErrorMessage(errorsMap: FileValidationErrorsMap): string {
  const errors = Object.entries(errorsMap);

  if (errors.length === 1) {
    return errors[0][1];
  } else {
    return errors.map(([file, message]) => `${file}: ${message}`).join('<br>');
  }
}
