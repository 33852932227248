import { rewardBundle } from '../actions/actionTypes';

const initialState = {
  rewardBundles: [],
  loading: false,
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case rewardBundle.FETCH:
            return {
                ...state,
                loading: true,
            };
        case rewardBundle.SET:
            return {
                ...state,
                rewardBundles: action.data,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
