import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { useAppSelector } from '@/store';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    iconName: '',
  },
  visibility: {},
};

const IconAnchor = styled.i((props) => props.$styleText);
const IconSVG = styled.svg((props) => props.$styleText);

const BootstrapIcon = (componentProps) => {
  let props = componentProps;

  const fontIconsSvg = useAppSelector((state) => state.templatesConfig.fontIconsSvg);
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  const { styleText, className } = props;
  let iconName = props.properties.iconName;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props.properties.asSvg && fontIconsSvg && iconName?.startsWith('fi-')) {

    const strippedIconName = iconName.replace('fi-', '');

    return <IconSVG
      className={`fi ${iconName} ${className ?? ''}`}
      $styleText={styleText}
      style={props.style}
      onClick={props.properties?.onClick}
      width={'1em'}
      height={'1em'}
      fill={'currentColor'}
      {...extraDataProps} >
      <use xlinkHref={`${fontIconsSvg}#${strippedIconName}`} />
    </IconSVG>;
  }

  return <IconAnchor
    className={`${iconName?.startsWith?.('bi') ? 'bi' : 'fi'} ${iconName} ${className ?? ''}`}
    $styleText={styleText}
    style={props.style}
    onClick={props.properties?.onClick}
    {...extraDataProps} />;
};

export default BootstrapIcon;
