import { FileExtensionItem, FileExtensions, FileMimeTypes } from '../constants';
import { FileListValidationResult, FileValidationResult, ValidationContext } from '../types';
import { fileListForEach, getFileContentMimeType, getFileDataUrl, getSizeByUnit } from './file';

export function getValidationContext(context: Partial<ValidationContext> = {}): ValidationContext {
  return {
    maxSize: context.maxSize ?? '2',
    mimeTypes: context.mimeTypes ?? FileMimeTypes,
    extensions: context.extensions ?? FileExtensions,
  };
}

export function isExtensionValid(name: string, extensions: FileExtensionItem[]): boolean {
  return extensions.some((extension) => name.toLowerCase().endsWith(extension));
}

export async function validateFile(file: File, validationContext: ValidationContext): Promise<FileValidationResult> {
  // Empty file
  if (file.size <= 0) {
    return { isValid: false, message: 'DOCUMENT_UPLOAD_FILE_TOO_BIG' };
  }

  // Max size
  if (file.size > getSizeByUnit(validationContext.maxSize)) {
    return { isValid: false, message: 'DOCUMENT_UPLOAD_FILE_TOO_BIG' };
  }

  // Extension
  if (!isExtensionValid(file.name, validationContext.extensions)) {
    return { isValid: false, message: 'DOCUMENT_UPLOAD_FILE_TYPE_NOT_SUPPORTED' };
  }

  // Mime type
  const contentMimeType = await getFileContentMimeType(file);

  if (contentMimeType !== file.type) {
    return { isValid: false, message: 'DOCUMENT_UPLOAD_FILE_TYPE_NOT_SUPPORTED' };
  }

  // If no error so far, file is valid
  return { isValid: true };
}

export async function validateFiles(
  files: FileList,
  validationContext?: Partial<ValidationContext>,
): Promise<FileListValidationResult> {
  const result: FileListValidationResult = {
    isValid: true,
    errors: {},
    contents: {},
  };

  const promises: Promise<void>[] = [];

  fileListForEach(files, (file) => {
    promises.push(
      new Promise((resolve) => {
        validateFile(file, getValidationContext(validationContext)).then((validationResult) => {
          if (!validationResult.isValid) {
            result.isValid = validationResult.isValid;
            result.errors[file.name] = validationResult.message;
          }

          getFileDataUrl(file).then((content) => {
            result.contents[file.name] = content;

            resolve();
          });
        });
      }),
    );
  });

  await Promise.all(promises);

  return result;
}
