import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import { ComponentLayout } from './constants';
import { useDocumentsState, useFilesUploader } from './hooks';
import { FilesUploaderProps, FilesUploaderState } from './types';
import { detectDeviceCamera, getInputAcceptAttribute, getInputCaptureAttribute } from './utils/browser';
import { formatErrorMessage, formatExtensionsForTooltip, formatFileSize } from './utils/formatters';
import { Loading, inProgress, setLoadingState } from './utils/loading';
import { initialFilesUploaderState } from './utils/state';
import { validateFiles } from './utils/validators';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const FilesUploader: React.FC<FilesUploaderProps> = (componentProps) => {
  let props = componentProps;
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const {
    context: {
      onSelectCallback,
      onClearCallback,
      onSaveCallback,
      onCancelCallback,
      onCloseCallback,
      isOpen,
      isLoading,
      extensions,
      dialogTitle,
      dialogDescription,
    },
  } = useFilesUploader(props.properties);

  const { documentsLoading, documentsMaxSize } = useDocumentsState();

  const [state, setState] = React.useState<FilesUploaderState>(initialFilesUploaderState());

  React.useEffect(() => {
    detectDeviceCamera().then((deviceHasCamera) => {
      setState((prevState) => ({
        ...prevState,
        deviceHasCamera,
      }));
    });
  }, []);

  React.useEffect(() => {
    if (isOpen === false) {
      setState(initialFilesUploaderState());
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (documentsLoading && !inProgress(state.loading)) {
      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.REQUEST),
      }));
    }

    if (!documentsLoading && inProgress(state.loading)) {
      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.SUCCESS),
      }));
    }
  }, [state.loading, documentsLoading]);

  const [inputAccept, inputCapture] = React.useMemo<[string | undefined, string | undefined]>(() => {
    return [
      getInputAcceptAttribute(state.deviceHasCamera, state.isCameraSelected, state.isLibrarySelected, extensions),
      getInputCaptureAttribute(state.deviceHasCamera, state.isCameraSelected, state.isLibrarySelected),
    ];
  }, [extensions, state.deviceHasCamera, state.isCameraSelected, state.isLibrarySelected]);

  const handleSelect = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const { files } = event.currentTarget;

      if (files === null || files.length === 0) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        loading: setLoadingState(Loading.REQUEST),
        layout: ComponentLayout.LOADING,
      }));

      validateFiles(files, { extensions, maxSize: documentsMaxSize })
        .then((validationResult) => {
          const loading = validationResult.isValid ? Loading.SUCCESS : Loading.FAILURE;
          const message = validationResult.isValid ? undefined : formatErrorMessage(validationResult.errors);
          const layout = validationResult.isValid ? ComponentLayout.CONFIRM : ComponentLayout.UPLOAD;

          setState((prevState) => ({
            ...prevState,
            ...validationResult,
            loading: setLoadingState(loading, message),
            layout,
            files,
            isLibrarySelected: false,
            isCameraSelected: false,
          }));

          if (!documentsLoading) {
            const firstFile = files.item(0);

            if (onSelectCallback && firstFile) {
              onSelectCallback(firstFile, event);
            }
          }
        })
        .catch((message) => {
          setState((prevState) => ({
            ...prevState,
            loading: setLoadingState(Loading.FAILURE, message),
            layout: ComponentLayout.UPLOAD,
            isLibrarySelected: false,
            isCameraSelected: false,
          }));
        });
    },
    [onSelectCallback, extensions, documentsLoading, documentsMaxSize],
  );

  const handleInputLibrary = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(() => {
    // Triggered when clicked/tapped on select file area
    setState((prevState) => ({
      ...prevState,
      // We check if camera was selected, because of the second click triggered from open camera button
      isLibrarySelected: prevState.isCameraSelected ? prevState.isLibrarySelected : true,
      isCameraSelected: prevState.isCameraSelected ? prevState.isCameraSelected : false,
    }));
  }, []);

  const handleInputCamera = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(() => {
    // Triggered when clicked/tapped on open camera button
    setState((prevState) => ({
      ...prevState,
      isLibrarySelected: false,
      isCameraSelected: true,
    }));
  }, []);

  const handleInputCancel = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isLibrarySelected: false,
      isCameraSelected: false,
    }));
  }, []);

  const handleClear = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onClearCallback) {
      onClearCallback();
    }
  }, [onClearCallback]);

  const handleSave = React.useCallback(() => {
    const { files } = state;

    if (files === undefined || files.length === 0) {
      return;
    }

    const firstFile = files.item(0);

    if (onSaveCallback && firstFile) {
      onSaveCallback(firstFile);
    }
  }, [onSaveCallback, state]);

  const handleCancel = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onCancelCallback) {
      onCancelCallback();
    }
  }, [onCancelCallback]);

  const handleClose = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialFilesUploaderState(),
    }));

    if (onCloseCallback) {
      onCloseCallback();
    }
  }, [onCloseCallback]);

  const contextValue = React.useMemo(() => {
    const firstFile = state.files?.item(0) ?? undefined;

    return {
      isOpen,
      isLoading,
      handleSelect,
      handleInputLibrary,
      handleInputCamera,
      handleInputCancel,
      handleClear,
      handleSave,
      handleCancel,
      handleClose,
      layout: state.layout,
      fileContent: firstFile ? state.contents[firstFile.name] : '',
      fileName: firstFile ? firstFile.name : '',
      fileExtensions: formatExtensionsForTooltip(extensions),
      inputAccept,
      inputCapture,
      deviceHasCamera: state.deviceHasCamera,
      maxSize: formatFileSize(documentsMaxSize),
      errorMessage: formatErrorMessage(state.errors),
      dialogTitle,
      dialogDescription,
    };
  }, [
    dataElementContext,
    componentProps,
    isOpen,
    isLoading,
    handleSelect,
    handleInputLibrary,
    handleInputCamera,
    handleInputCancel,
    handleClear,
    handleSave,
    handleCancel,
    handleClose,
    state.layout,
    state.contents,
    state.files,
    extensions,
    inputAccept,
    inputCapture,
    state.deviceHasCamera,
    documentsMaxSize,
    state.errors,
    dialogTitle,
    dialogDescription,
  ]);

  // console.log('DEBUG [FilesUploader]:', contextValue, state);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default FilesUploader;
