import React, { useMemo } from 'react';
import { RegisterModuleStateProps } from '@/components/modules/register/register-dynamic/types';
import { DEFAULT_INCREMENT_TIME } from '@/components/modules/register/register-dynamic/const';
import { useAppSelector } from '@/store';
import useMarketingOffer from '@/components/modules/register/hooks/useMarketingOffer';

export function useRegisterMarketingOffer(
  state: RegisterModuleStateProps,
  setState: React.Dispatch<React.SetStateAction<RegisterModuleStateProps>>,
  properties: any,
) {
  const marketingOffer = useMarketingOffer();
  const [iTimer, setITimer] = React.useState<NodeJS.Timeout | null>(null);
  const { signedUp } = useAppSelector((state) => state.register);

  const BONUS_FIELDS: string[] = [];

  if (properties.emailBonus) {
    BONUS_FIELDS.push('email');
  }
  if (properties.phoneBonus) {
    BONUS_FIELDS.push('phone');
  }
  if (properties.nicknameBonus) {
    BONUS_FIELDS.push('nickname');
  }
  if (properties.firstNameBonus) {
    BONUS_FIELDS.push('firstName');
  }
  if (properties.lastNameBonus) {
    BONUS_FIELDS.push('lastName');
  }
  if (properties.ninBonus) {
    BONUS_FIELDS.push('nin');
  }
  if (properties.addressBonus) {
    BONUS_FIELDS.push('address');
  }
  const calculateBonusValueUnlocked = () => {
    if (!marketingOffer) {
      return 0;
    }

    let startValue = 0;
    if (marketingOffer.ui_elements?.register_starting_value) {
      startValue = parseInt(marketingOffer.ui_elements.register_starting_value, 10) ?? 0;
    }

    let fieldsValid = 0;
    BONUS_FIELDS.forEach((field: string) => {
      // @ts-ignore
      if (state.fields[field]) {
        // @ts-ignore
        fieldsValid += typeof state.errors[field] !== 'undefined' && state.errors[field] === false ? 1 : 0;
      }
    });

    const bonusValueToUnlock =
      startValue +
      Math.round(((marketingOffer.registrationBonusContext.value - startValue) / BONUS_FIELDS.length) * fieldsValid);
    setState((prevState) => ({
      ...prevState,
      bonusValueToUnlock: isNaN(bonusValueToUnlock) ? 0 : bonusValueToUnlock,
      trigger: 'calculateBonusValueUnlocked',
    }));
  };

  React.useEffect(() => {
    if (iTimer) {
      clearInterval(iTimer);
    }
    let nextValue = state.bonusValueUnlocked;
    const incrementType = state.bonusValueUnlocked < state.bonusValueToUnlock ? 1 : -1;
    try {
      let startValue = 0;
      if (marketingOffer.ui_elements?.register_starting_value) {
        startValue = parseInt(marketingOffer.ui_elements.register_starting_value, 10) ?? 0;
      }
      if (nextValue < startValue) {
        nextValue = startValue;
      }
    } catch (e) {}

    const lTimer = setInterval(
      () => {
        if (nextValue >= state.bonusValueToUnlock || nextValue < 0) {
          if (lTimer) {
            clearInterval(lTimer); // optimization! should be lTimer not iTimer; this prevents infinite looping
          }
          setState((prevState) => ({
            ...prevState,
            bonusValueUnlocked: state.bonusValueToUnlock,
            trigger: 'bonusValueUnlocked-useEffect-1',
          }));
          return;
        } else {
          nextValue = nextValue + incrementType;
        }

        setState((prevState) => ({
          ...prevState,
          bonusValueUnlocked: nextValue,
          trigger: 'bonusValueUnlocked-useEffect-2',
        }));
      },
      DEFAULT_INCREMENT_TIME / (state.bonusValueToUnlock - state.bonusValueUnlocked),
    );

    setITimer(lTimer);
  }, [state.bonusValueToUnlock]);

  React.useEffect(() => {
    calculateBonusValueUnlocked();
  }, [state.errors, marketingOffer]);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      bonusValueToUnlock: marketingOffer?.registrationBonusContext?.value ?? 0,
      bonusValueUnlocked: marketingOffer?.registrationBonusContext?.value ?? 0,
    }));
  }, [signedUp]);

  return useMemo(() => marketingOffer, [marketingOffer]);
}