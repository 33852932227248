import React from 'react';
import styled from 'styled-components';
import { isEqual, set } from 'lodash-es';
import { useAppSelector, useAppDispatch } from '../../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';

type PokerProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Poker = (componentProps: PokerProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  // const dispatch = useAppDispatch();
  // const location = useLocation();
  const navigate = useNavigate();
  // const authentication = useAppSelector((state) => state.authentication);

  const [state, setState] = React.useState({});

  const onNavigateToCasino = () => {
    navigate('/casino');
  };

  const onPlayOnApp = () => {
    // console.log('TODO: playOnApp');
  };

  const contextValue = {
    ...state,
    onNavigateToCasino,
    onPlayOnApp,
  };

  // console.log('Poker[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Poker;
