import React, { useCallback } from 'react';

declare global {
    interface Window {
        $zoho: any;
    }
}
import MainEventBus from '../../utils/evbus';
import styled from 'styled-components';

const ZohoChatElementDiv = styled.div({});

type StateProps = {
    bubbleId: string;
};

const ZohoChatAgent = () => {
    const [state] = React.useState<StateProps>({
        bubbleId: 'zoho-chat-' + Math.random().toString(36).substr(2, 9),
    });

    function isZohoLoaded() {
        return window.$zoho && window.$zoho.salesiq && typeof window.$zoho.salesiq.floatbutton !== 'undefined';
    }

    React.useEffect(() => {
        MainEventBus.on('SHOW_ZOHO_CHAT', openChat);

        return () => {
            MainEventBus.remove('SHOW_ZOHO_CHAT', openChat);
        };
    }, []);

    const openChat = () => {
        loadChat();

        if (!isZohoLoaded()) {
            window.$zoho.salesiq.afterReady = function() {
                // hide the chat bubble
                window.$zoho.salesiq.floatbutton.visible("hide");
                // show the chat window
                window.$zoho.salesiq.chatwindow.visible('show');
            };
        } else {
            // hide the chat bubble
            window.$zoho.salesiq.floatbutton.visible("hide");
            // show the chat window
            window.$zoho.salesiq.chatwindow.visible('show');
        }
    };

    const loadChat = async () => {
        const zohoKey = window.config.customerSupport?.providers.zoho.key;

        if (!zohoKey) return;
        
        // Insert Zoho snippet if not present
        if (!document.getElementById('zoho-snippet')) {
            const snippet = document.createElement('script');
            snippet.id = 'zoho-snippet';
            snippet.text = `
                window.$zoho = window.$zoho || {};
                $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
            `;
            document.head.appendChild(snippet);
        }

        const el = document.getElementById('zsiqscript');
        // Insert Zoho chat script if not present
        if (!el) {
            const src = `https://salesiq.zohopublic.eu/widget?wc=${zohoKey}`;
            const lastScript = document.scripts[document.scripts.length - 1];
            const script = document.createElement('script');
            script.id = 'zsiqscript';
            script.async = true;
            script.src = src;
            // @ts-ignore
            script.onload = script.onreadystatechange = function () {
                // @ts-ignore
                if (this.readyState && this.readyState !== 'complete' && this.readyState !== 'loaded') {
                    return;
                }
            };
            lastScript?.parentElement?.insertBefore(script, lastScript.nextSibling);
        }
    };

    return <ZohoChatElementDiv id={state.bubbleId} className={'zoho-chat-element'}></ZohoChatElementDiv>;
};

export default ZohoChatAgent;

