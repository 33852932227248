import * as actionTypes from './../actions/actionTypes';

const withBonus = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get('wb') === 'yes') {
    return 'yes';
  }
  return null;
};

const NOT_AUTHENTICATED = 12;
const INVALID_EMAIL_CODE = 42;
const INVALID_SMS_CODE = 43;

const initialState = {
  account_status: null,
  account_sub_status: null,
  address: null,
  birthday: null,
  client_player_id: null,
  global_player_id: null,
  date_created: null,
  email: null,
  first_name: null,
  last_name: null,
  username: null,
  nin: null,
  phone: null,
  newPhone: null,
  receivedPhone: null,
  receivedPhoneCode: null,
  verified: null,
  email_verified: false,
  limits: null,
  loading: false,
  receivedChangePassword: null,
  loadingCheckPassword: false,
  passwordIsValid: null,
  verificationType: 'password',
  ftd: 0,
  chat: {
    account: null,
    active: null,
    loading: false,
  },
  errorCode: false,
  wb: withBonus(),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.profile.RECEIVED_ACCOUNT:
      let account = {};

      initialState.wb = null;

      Object.keys(initialState).forEach((property) => {
        if (typeof action.account[property] === 'undefined') {
          return;
        }

        account[property] = action.account[property];
      });

      account.wb = null;

      return {
        ...state,
        ...account,
        loading: false,
      };
    case actionTypes.profile.REQUEST_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.profile.RESET_ACCOUNT:
      return {
        ...initialState,
      };
    case actionTypes.profile.REQUEST_LIMITS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.profile.RECEIVED_LIMITS:
      let limits = {};

      Object.keys(initialState).forEach((property) => {
        if (typeof action.limits[property] === 'undefined') {
          return;
        }

        limits[property] = action.limits[property];
      });

      return {
        ...state,
        ...limits,
        loading: false,
      };
    case actionTypes.profile.SET_NEW_LIMIT:
      return {
        ...state,
        limit_type: action.limit_type,
        limit_type_period: action.limit_type_period,
        limit_value: action.limit_value,
      };
    case actionTypes.profile.SET_SELFEXCLUSION:
      return {
        ...state,
        limit_type: action.limit_type,
        limit_type_period: action.limit_type_period,
      };
    case actionTypes.profile.CHECK_PASSWORD:
      const tmpPhone =
        window.config.authenticationSettings.enablePhone === '1' &&
        window.config.authenticationSettings.enableEmail !== '1' &&
        window.config.authenticationSettings.enableUsername !== '1'
          ? action.phone
          : state.phone;

      return {
        ...state,
        loadingCheckPassword: true,
        passwordIsValid: null,
        password: action.password,
        phone: tmpPhone,
        errorCode: null,
      };
    case actionTypes.profile.RECEIVED_PASSWORD_CHECK:
      // let passwordIsValid = !(action.data && action.data.ResponseCode && action.data.ResponseCode === 12);
      let passwordIsValid = action.data ? true : false;

      // let errorCode = action.data.ResponseCode !== 'undefined' ? action.data.ResponseCode : false;
      let errorCode = '';
      if (action.data !== 'undefined') {
        if (action.data.ResponseCode) {
          errorCode = action.data.ResponseCode;
          passwordIsValid = false;
        } else {
          // if we have a server error
          if (typeof action.data !== 'boolean' && action.data.http !== 'undefined') errorCode = 1;
          // if (action.data.http !== 'undefined') errorCode = 1;
        }
      }

      if (passwordIsValid === false && !errorCode) {
        errorCode = 1;
      }

      // console.log('DEBUG REDUCER: ', { a: action.data, passwordIsValid, errorCode, action });
      return {
        ...state,
        loadingCheckPassword: false,
        password: null,
        passwordIsValid: passwordIsValid,
        errorCode: errorCode,
      };
    case actionTypes.profile.SELF_EXCLUDE:
      return {
        ...state,
        loading: true,
        loadingCheckPassword: true,
        passwordIsValid: null,
        password: action.password,
        errorCode: null,
      };
    case actionTypes.profile.RECEIVED_SELF_EXCLUDE:
      let eCode = null;
      if (!action?.data || action?.data?.ResponseCode) {
        eCode = action?.data?.ResponseCode ?? 1;
      }
      return {
        ...state,
        loading: false,
        loadingCheckPassword: false,
        passwordIsValid: eCode && eCode === NOT_AUTHENTICATED ? false : true,
        errorCode: eCode,
      };
    case actionTypes.profile.CLEAR_PASSWORD_CACHE:
      return {
        ...state,
        receivedChangePassword: null,
        passwordIsValid: null,
        errorCode: null,
        loading: false,
      };
    case actionTypes.profile.CHANGE_PASSWORD:
      return {
        ...state,
        currentPassword: action.currentPassword,
        newPassword: action.newPassword,
        verificationType: action.verificationType,
        phone: action.phoneNumber ?? null,
        loading: true,
      };
    case actionTypes.profile.RECEIVED_CHANGE_PASSWORD:
      return {
        ...state,
        receivedChangePassword: action.receivedChangePassword,
        loading: false,
      };
    case actionTypes.profile.CHECK_PHONE:
      return {
        ...state,
        newPhone: action.newPhone,
        loading: true,
        receivedPhoneCode: null,
      };
    case actionTypes.profile.RECEIVED_PHONE:
      return {
        ...state,
        receivedPhone: action.receivedPhone,
        loading: false,
      };
    case actionTypes.profile.CHECK_PHONE_CODE:
      return {
        ...state,
        newPhone: action.newData,
        loading: true,
      };
    case actionTypes.profile.RECEIVED_PHONE_CODE:
      let phone = state.phone;
      if (Array.isArray(action.receivedPhoneCode) && action.receivedPhoneCode.length === 0) {
        phone = state.newPhone.phone;
      }

      return {
        ...state,
        receivedPhoneCode: action.receivedPhoneCode,
        phone: phone,
        loading: false,
      };
    case actionTypes.profile.RESET_PHONE_CHANGE:
      return {
        ...state,
        receivedPhone: null,
        receivedPhoneCode: null,
        newPhone: null,
        loading: false,
      };
    case actionTypes.profile.RECEIVED_CHAT_INFO:
      return {
        ...state,
        chat: {
          ...state.chat,
          account: action.value.result.chat.account,
          authkey: action.value.result.authkey,
          active: action.value.result.chat.active,
          loading: false,
        },
      };
    case actionTypes.profile.GET_CHAT_INFO:
      return {
        ...state,
        chat: {
          account: null,
          authkey: null,
          active: false,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default reducer;
