import { updateTables, updateTablesKeys } from '../slices/pragmaticLive';
import store from '..';

const DEBUG = false;

const dga = {
  websocket: null,
  wsUri: window.config.pragmatic.wsUrl,
  tableId: null,
  casinoId: window.config.pragmatic.casinoId,
  tryToConnect: true,
  reconnectTimeout: 5000,
  pingInterval: 20000,
  pingIntervalId: null,

  // public
  connect: function () {
    // eslint-disable-next-line
    const self = this;
    self.tryToConnect = true;

    DEBUG && console.log('connecting to ' + 'wss://' + self.wsUri + '/ws');
    if (self.websocket !== null && self.websocket.readyState !== 3) {
      self.websocket.close();
      DEBUG && console.log('Socket open closing it');
    }
    self.websocket = new WebSocket('wss://' + self.wsUri + '/ws');
    self.websocket.onopen = function (evt) {
      self.onWsOpen(evt);
    };
    self.websocket.onclose = function (evt) {
      self.onWsClose(evt);
    };
    self.websocket.onmessage = function (evt) {
      self.onWsMessage(evt);
    };
    self.websocket.onerror = function (evt) {
      self.onWsError(evt);
    };
  },
  // public
  onMessage: function (data) {
    DEBUG && console.log('onMessage', data);
    if (Array.isArray(data?.tableKey)) {
      store.dispatch(updateTablesKeys(data));
    } else if (data?.tableId) {
      store.dispatch(updateTables(data));
    }
    // to fill
  },
  // public
  onConnect: function () {
    // on connect fetch the available tables
    this.available();
    this.pingIntervalId = setInterval(() => {
      this.ping();
    }, this.pingInterval);
  },
  // public
  disconnect: function () {
    // eslint-disable-next-line
    const self = this;
    self.tryToConnect = false;
    self.websocket.close();
    clearInterval(self.pingIntervalId);
    DEBUG && console.log('Disconnected');
  },
  // public
  subscribe: function (tableId, currency, delta) {
    var subscribeMessage = {
      type: 'subscribe',
      key: tableId,
      casinoId: this.casinoId,
      currency: currency
    };

    if (delta === 'true') {
      subscribeMessage.isDeltaEnabled = true;
    }

    DEBUG && console.log('subscribing' + tableId);

    var json = JSON.stringify(subscribeMessage);
    this.doWsSend(json);
  },
  // public
  unsubscribe: function (tableId) {
    var subscribeMessage = {
      type: 'unsubscribe',
      key: tableId,
      casinoId: this.casinoId,
    };

    DEBUG && console.log('unsubscribing ' + tableId);

    // eslint-disable-next-line
    const self = this;
    var json = JSON.stringify(subscribeMessage);
    self.doWsSend(json);
  },

  // public
  available: function () {
    var availableMessage = {
      type: 'available',
      casinoId: this.casinoId
    };
    DEBUG && console.log('checking availability');
    this.doWsSend(JSON.stringify(availableMessage));
  },

  onWsOpen: function (_evt) {
    DEBUG && console.log('Connected to wss server');
    if (this.onConnect != null) {
      this.onConnect();
    }
  },

  onWsClose: function (evt) {
    DEBUG && console.log('DISCONNECTED');
    // eslint-disable-next-line
    const self = this;
    clearInterval(self.pingIntervalId);

    if (self.tryToConnect === true) {
      DEBUG && console.log('RECONNECTING');
      setTimeout(function () {
        self.connect();
      }, self.reconnectTimeout);
    }
  },

  onWsMessage: function (evt) {
    const data = JSON.parse(evt.data);
    DEBUG && console.log(evt.data);
    if (this.onMessage != null) {
      this.onMessage(data);
    }
  },

  onWsError: function (evt) {
    DEBUG && console.log('ERROR: ' + evt.data);
  },

  ping: function () {
    const pingMessage = {
      type: 'ping',
      pingTime: Date.now().toString()
    };
    var jsonSub = JSON.stringify(pingMessage);
    this.doWsSend(jsonSub);
  },

  doWsSend: function (message) {
    DEBUG && console.log('SENT: ' + message);
    try {
      this.websocket.send(message);
    } catch (e) { }
  }
};


window._dga = dga;

export default dga;