import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import * as actionTypes from '../../../casino/store/actions/actionTypes';
import {
  setMomentumBonuses,
  getMomentumBonuses,
  clearMomentumState,
  setMomentumPrizeLoading,
} from '../actions/momentum';
import { momentumConstants } from '../actions/constants';
import getStore from '..';

let apiUrl = window.config.momentumApiUrl;

export function* getMomentumBonusesSaga(action) {
  if (window.config && window.config.momentumEnabled !== '1') return;
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;

  if (access_token) {
    try {
      const { data } = yield axios.get(apiUrl + '/player/award/get', {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      });
      // console.log('data', data);

      if (data?.award?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(data?.award?.bonus_expires_at).valueOf();
        if (now > prizeExpiresAt) {
          data.award = null;
        }
      }

      yield put(setMomentumBonuses(data, 'api', 'get'));
    } catch (e) {
      yield put(setMomentumPrizeLoading(false));
    }
  }
}

export function* forfeitMomentumBonusSaga(action) {
  if (window.config && window.config.momentumEnabled !== '1') return;
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;
  if (access_token && action.id) {
    yield put(setMomentumPrizeLoading(true));
    try {
      const { data } = yield axios.get(apiUrl + '/player/award/forfeit/' + action.id, {
        headers: {
          Authorization: 'Bearer ' + access_token,
        },
      });
      yield put(getMomentumBonuses()); // Refresh the list of bonuses
      // yield put(setMomentumPrizeLoading(false));
    } catch (e) {
      yield put(setMomentumPrizeLoading(false));
    }
  }
}

export function* acceptMomentumBonusSaga(action) {
  if (window.config && window.config.momentumEnabled !== '1') return;
  const store = yield getStore();
  const access_token = store.getState().authentication?.access_token;
  if (access_token && action.id) {
    const { data } = yield axios.get(apiUrl + '/player/award/accept/' + action.id, {
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
    });

    yield put(getMomentumBonuses()); // Refresh the list of bonuses
  }
}

export function* resetSaga() {
  if (window.config && window.config.momentumEnabled !== '1') return;
  yield put(clearMomentumState());
}

export default function* watchMomentumSaga() {
  yield takeEvery(momentumConstants.GET_MOMENTUM_BONUSES, getMomentumBonusesSaga);
  yield takeEvery(actionTypes.application.REINITIALIZE, getMomentumBonusesSaga);
  yield takeEvery(momentumConstants.FORFEIT_MOMENTUM_BONUS, forfeitMomentumBonusSaga);
  yield takeEvery(momentumConstants.ACCEPT_MOMENTUM_BONUS, acceptMomentumBonusSaga);
  const platformType = window.config?.platformType?.toString?.() ?? '2';
  yield takeEvery(platformType === '2' ? 'LOGIN_LOGOUT_USER' : 'LOGOUT', resetSaga);
}
