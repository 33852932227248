export const ComponentLayout = {
  UPLOAD: 'upload',
  LOADING: 'upload-loading',
  CONFIRM: 'confirm-upload',
} as const;

export type ComponentLayoutOption = (typeof ComponentLayout)[keyof typeof ComponentLayout];

export const FileExtension = {
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif',
  PNG: '.png',
  PDF: '.pdf',
} as const;

export type FileExtensionItem = (typeof FileExtension)[keyof typeof FileExtension];

export const FileExtensions = Object.values(FileExtension);

export const FileMimeType = {
  JPG: 'image/jpeg',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  PNG: 'image/png',
  PDF: 'application/pdf',
} as const;

export type FileMimeTypeItem = (typeof FileMimeType)[keyof typeof FileMimeType];

export const FileMimeTypes = Object.values(FileMimeType);

export const FileSizeUnit = {
  B: 'B',
  KB: 'KB',
  MB: 'MB',
  GB: 'GB',
} as const;

export type FileSizeUnitItem = (typeof FileSizeUnit)[keyof typeof FileSizeUnit];

export const FileSizeUnits = Object.values(FileSizeUnit);

export const GENERIC_FILE_MAX_SIZE = '2MB';

export const GENERIC_FILE_READER_ERROR_MESSAGE = 'Unknown error';
