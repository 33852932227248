export const Loading = {
  IDLE: 'IDLE',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
} as const;

export type LoadingStep = (typeof Loading)[keyof typeof Loading];

export const LoadingSteps = Object.values(Loading);

export interface LoadingState {
  state: LoadingStep;
  message?: string;
}

export function inProgress(loading?: LoadingState): boolean {
  return loading?.state === Loading.REQUEST;
}

export function isSuccess(loading?: LoadingState): boolean {
  return loading?.state === Loading.SUCCESS;
}

export function isFailure(loading?: LoadingState): boolean {
  return loading?.state === Loading.FAILURE;
}

export function setLoadingState(state: LoadingStep, message?: string): LoadingState {
  return {
    state,
    message,
  };
}
