import ClientAPI from '../ClientAPI/ClientAPI';
import { delay, put } from 'redux-saga/effects';
import * as actions from './../store/actions';
import axios from 'axios';

class RequestManager {
  static *request(id, data, callback) {
    if (yield this.canRequest(id)) {
      yield put(actions.beginRequest(id));
      let client = yield this.getAxiosWithToken();

      let response = yield client(data);
      if (callback) {
        yield callback(response);
      }
    }
  }

  static *postCustomUrl(id, url, body, callback) {
    if (yield this.canRequest(id)) {
      yield put(actions.beginRequest(id));
      yield this.getAxiosWithToken();
      // we have a token now;
      const state = ClientAPI.getStore().getState();
      const { authentication } = state;

      if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
        throw Error('not authenticated');
      }
      console.log('postCustomUrl', url, body, state.authentication.access_token);
      const { data } = yield axios.post(url, body, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });
    }
  }

  static canRequest(id) {
    let requestList = ClientAPI.getStore().getState().requestManager.requests;

    if (typeof requestList[id] === 'undefined') {
      return true;
    }

    let timestamp = parseInt(new Date().getTime() / 1000, 10);
    let ttl =
      window.config && window.config.cache_requests_ttl
        ? parseInt(window.config && window.config.cache_requests_ttl)
        : 600;
    if (ttl < 1) {
      ttl = 600;
    }

    if (requestList[id].last_sent + ttl <= timestamp) {
      return true;
    }

    return false;
  }

  // this functions wait for the axios plugin to receive the Authorization token, and returns it.
  static *getAxiosWithToken() {
    let axios = ClientAPI.getInstance();

    if (typeof axios.defaults.headers.common.Authorization === 'undefined') {
      yield delay(500);
      return yield this.getAxiosWithToken();
    }
    return axios;
  }
}

export default RequestManager;
