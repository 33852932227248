import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import store, { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { cloneDeep } from 'lodash-es';
import { formatDate, adjustOddName } from '../bets/utils/functions';
import { MatchType } from '@/components/modules/bets/utils/types';
import { prematchFetchMatches } from '@/modules/bets/store/actions/prematch';
import { liveFetchMatches } from '@/modules/bets/store/actions/live';
import { betsSlipToggle } from '@/modules/bets/store/actions/betsSlip';

type BetFeedProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetFeed = (componentProps: BetFeedProps) => {
  let props = componentProps;

  const { i18n } = useTranslation();
  const tid = React.useRef<any>(0);
  const accessToken = useAppSelector((state) => state.authentication?.access_token);
  const selectedBets = useAppSelector((state) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket]);
  const dispatch = useAppDispatch();

  const dataRef = React.useRef<any>(null);
  const [state, setState]: any = React.useState({
    data: null,
    loading: true,
    filters: {
      select: 'all',
      sort: 'most_played',
      limit: 50,
      sport_id: '1',
    },
  });

  const [navigator, setNavigator] = React.useState({
    selectedSport: null,
    hiddenSport: 0,
    extraTotal: 0,
  });

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const setLoading = React.useCallback((loading: boolean) => {
    setState((v: any) => ({
      ...v,
      loading,
    }));
  }, []);

  const onLoadData = React.useCallback(() => {
    setLoading(true);

    /**
      filters = Joi.object({
        sort: Joi.string().valid("most_played", "most_recent").default("most_played"),
        select: Joi.string().valid("all", "live", "prematch").default("all"),
        limit: Joi.number().max(100).default(20),
        sport_id: Joi.array().items(Joi.string()).default([]),
        category_id: Joi.array().items(Joi.string()).default([]),
        tournament_id: Joi.array().items(Joi.string()).default([]),
        market_id: Joi.array().items(Joi.string()).default([]),
      });
     */
    const postData = {
      ...state.filters,
    };

    if (postData.sport_id) {
      postData.sport_id = [postData.sport_id];
    }

    axios
      .post(`${window.config.betFeedApiUrl}/public/stakes`, postData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        dataRef.current = response.data;
        setState((v: any) => ({
          ...v,
          data: response.data,
          loading: false,
        }));

        setNavigator((v: any) => {
          if (v.selectedSport == null) {
            return {
              ...v,
              selectedSport: response.data.sports[0],
            };
          } else if (v.selectedSport) {
            const id = v.selectedSport.id;
            const sport = response.data.sports.find((s: any) => s.id === id);
            if (!sport) {
              return {
                ...v,
                selectedSport: response.data.sports[0],
              };
            }
          }

          return v;
        });
        handleResize(100);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [state.filters, accessToken]);

  React.useEffect(() => {
    onLoadData();
  }, [state.filters, accessToken]);

  const handleResize = (timeout: any) => {
    window.clearTimeout(tid.current);
    tid.current = window.setTimeout(
      () => {
        let baseTop = 0;
        let items: any = document.querySelectorAll('.wl-prematch-navigator.mobile>.list-wrapper>.list>.item-wrapper');
        if (items.length) baseTop = items[0].offsetTop;

        const total = dataRef.current?.sports?.length ?? 0;

        if (total) {
          let extraTotal = 0;

          if (items) {
            items = Array.from(items);
            items.find((item: any, index: number) => {
              if (item.offsetTop > baseTop) {
                extraTotal = total - index;
                return true;
              }

              return false;
            });

            setNavigator((v: any) => ({
              ...v,
              extraTotal,
            }));
          }
        }
      },
      typeof timeout === 'number' ? timeout : 100,
    );
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(100);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onAction = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    let type = e.currentTarget.getAttribute('data-type');
    const value = e.currentTarget.getAttribute('data-id');

    if (type === 'sport') type = 'sport_id';

    if (type && value) {
      setState((v: any) => ({
        ...v,
        filters: {
          ...v.filters,
          // @ts-ignore
          [type]: value,
        },
      }));
      handleResize(10);
    }
  }, []);

  const onBetClick = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    console.log('target', e.target);

    const mType = e.currentTarget.getAttribute('data-mtype');
    const idSport = e.currentTarget.getAttribute('data-idsport');
    const idMatch = e.currentTarget.getAttribute('data-idmatch');
    const idBet = e.currentTarget.getAttribute('data-idbet');
    const idBo = e.currentTarget.getAttribute('data-idbo');
    const idMbo = e.currentTarget.getAttribute('data-idmbo');

    if (!mType || !idMatch || !idBet) return;

    let stateMatches: any = {};

    if (mType === 'prematch') {
      stateMatches = store.getState().bets.prematch.matches;
    } else if (mType === 'live') {
      stateMatches = store.getState().bets.live;
    }

    const events: any = [idMatch];
    const markets: any = [idBet];

    if (stateMatches?.[idMatch]) {
      const match = stateMatches[idMatch];

      if (match?.matchBets) {
        for (const market of match.matchBets) {
          if (market.idBet == idBet) {
            return; // already loaded
          }
          markets.push(market.idBet);
        }
      }
    }

    if (events.length && markets.length) {
      if (mType === 'prematch') {
        dispatch(
          prematchFetchMatches(
            events,
            null,
            null,
            markets,
            (match: any, data: any) => {
              dispatch(
                betsSlipToggle(
                  data.mType,
                  data.idSport,
                  data.idMatch,
                  data.idBet,
                  `${data.idMatch}/${data.idBet}`,
                  data.idBo,
                  data.idMbo,
                ),
              );
            },
            {
              mType,
              idSport,
              idMatch,
              idBet,
              idBo,
              idMbo,
            },
          ),
        );
      } else if (mType === 'live') {
        dispatch(
          liveFetchMatches(
            events,
            null,
            null,
            markets,
            (match: any, data: any) => {
              dispatch(
                betsSlipToggle(
                  data.mType,
                  data.idSport,
                  data.idMatch,
                  data.idBet,
                  `${data.idMatch}/${data.idBet}`,
                  data.idBo,
                  data.idMbo,
                ),
              );
            },
            {
              mType,
              idSport,
              idMatch,
              idBet,
              idBo,
              idMbo,
            },
          ),
        );
      }
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const data = state.data ? cloneDeep(state.data) : null;

    data?.stakes?.forEach?.((stake: any) => {
      const bfIdBet = stake.bfIdBet;
      const bfIdMbo = stake.bfIdMbo;
      const bfPeriodIdMatch = stake.bfPeriodIdMatch;

      let foundBet: any = null;
      let foundOutcome: any = null;

      !bfPeriodIdMatch &&
        stake.matchBets.forEach((bet: any) => {
          if (bet.idBet != bfIdBet) return;

          foundBet = bet;

          bet.mbOutcomes.forEach((outcome: any) => {
            if (outcome.idMbo != bfIdMbo) return;

            foundOutcome = outcome;
          });
        });

      bfPeriodIdMatch &&
        stake.periods.forEach((period: any) => {
          if (period.idMatch != bfPeriodIdMatch) return;
          period.matchBets.forEach((bet: any) => {
            if (bet.idBet != bfIdBet) return;

            foundBet = bet;

            bet.mbOutcomes.forEach((outcome: any) => {
              if (outcome.idMbo != bfIdMbo) return;

              foundOutcome = outcome;
            });
          });
        });

      if (foundBet) {
        stake.marketName = foundBet.mbDisplayName;
      }

      if (foundOutcome) {
        stake.oddName = foundOutcome.mboDisplayName;
        stake.oddValue = foundOutcome.mboOddValue;

        if (stake.marketName) {
          stake.oddName = adjustOddName(stake, stake.marketName, stake.oddName);
        }
      }

      if (foundBet && foundOutcome) {
        stake.ids = {
          idBet: foundBet.idBet,
          idMb: foundBet.idMb,
          idBo: foundOutcome.idBo,
          idMbo: foundOutcome.idMbo,
          selected: false,
        };

        selectedBets?.prematch?.selected?.forEach?.((bet: any) => {
          if (bet.idMatch == stake.idMatch && bet.idBet == stake.ids.idBet && bet.idMbo == stake.ids.idMbo) {
            stake.ids.selected = true;
          }
        });

        !stake.ids.selected &&
          selectedBets?.live?.selected?.forEach?.((bet: any) => {
            if (bet.idMatch == stake.idMatch && bet.idBet == stake.ids.idBet && bet.idMbo == stake.ids.idMbo) {
              stake.ids.selected = true;
            }
          });
      }

      const teams = [];
      if (stake.team1Name[2] || stake.team1Name[0]) {
        teams.push(stake.team1Name);
      }
      if (stake.team2Name[2] || stake.team2Name[0]) {
        teams.push(stake.team2Name);
      }
      stake.eventName = teams.join(' vs ');
      stake.matchDateTimeString = formatDate(stake.matchDateTime, i18n.language);
    });

    const localNavigator: any = { ...navigator };
    let selectedSport = null;
    let selectedIndexSport = -1;
    if (state.filters.sport_id && data?.sports) {
      selectedSport = data.sports.find((sport: any) => sport.id == state.filters.sport_id);
      selectedIndexSport = data.sports.findIndex((sport: any) => sport.id == state.filters.sport_id);
    }

    localNavigator.hiddenSport =
      selectedIndexSport > -1 &&
      data.sports.length &&
      localNavigator.extraTotal > 0 &&
      selectedIndexSport >= data.sports.length - localNavigator.extraTotal
        ? true
        : false;

    if (selectedSport) {
      localNavigator.selectedSport = selectedSport;
    }

    return {
      data: data,
      navigator: localNavigator,
      filters: state.filters,
      actions: {
        onAction,
        onLoadData,
        onBetClick,
      },
    };
  }, [
    dataElementContext,
    componentProps,
    state,
    navigator,
    onAction,
    onBetClick,
    onLoadData,
    i18n.language,
    selectedBets,
  ]);

  //console.log('BetFeed[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetFeed;
