import React from 'react';
import Routes from './routes';
import { useAppDispatch, useAppSelector } from './store';

import { appInitialize as betsAppInitialize } from './modules/bets/store/actions/app';
import { appInitialize as lottoAppInitialize } from './modules/lotto/store/actions/app';
import { appInitialize as liveCasinoAppInitialize } from './modules/live-casino/store/actions/app';
import { prematchInitialize } from './modules/bets/store/actions/prematch';
import { liveInitialize } from './modules/bets/store/actions/live.js';
import MarketingEvents from '@/utils/marketing/MarketingEvents';
// import { fetchAllCountriesConfig } from './store/slices/allCountries';
import { appInitialize, startAppAuth } from './modules/casino/store/actions';
import { fetchPlayerGamesOrder } from './store/slices/playerGamesOrder';
import { initializeMetaPixel } from './analytics/metaPixel/metaPixelSDK';
import { fetchSources } from './store/slices/dataSources';

let didInit = false;

const App = () => {
  const dispatch = useAppDispatch();
  const configLoaded = useAppSelector((state) => state.templatesConfig.loaded);
  const fullLiveStateLoaded = useAppSelector((state) => state.bets.live.fullStateLoaded);
  const inited = React.useRef(false);
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  React.useEffect(() => {
    if (!didInit) {
      didInit = true;

      // dispatch(fetchAllCountriesConfig({ timezone: window.config.timezone }));
      dispatch(appInitialize());
      dispatch(startAppAuth());
    }
  }, []);

  React.useEffect(() => {
    if (authenticationToken) {
      MarketingEvents.init();

      // Meta Pixel initialization
      if (window.config.pixelAnalyticsEnabled === '1') {
        initializeMetaPixel();
      }

      const betsEnabled = window.config && window.config.betsEnabled === '1';
      const lottoEnabled = window.config && window.config.lottoEnabled === '1';
      const liveCasinoEnabled = window.config && window.config.liveCasinoEnabled === '1';

      if (betsEnabled) dispatch(betsAppInitialize());
      if (lottoEnabled) dispatch(lottoAppInitialize());
      if (liveCasinoEnabled) dispatch(liveCasinoAppInitialize());
    }
  }, [authenticationToken]);

  React.useEffect(() => {
    if (authenticationToken) {
      const IS_RECOMMENDED_GAMES_ENABLED = window?.config?.recommendedGamesEnabled === '1';
      const IS_REELS_RECOMMENDED_GAMES_ENABLED = window?.config?.casinoExplorer?.isReelsRecommendedGamesEnabled === '1';

      if (IS_RECOMMENDED_GAMES_ENABLED || IS_REELS_RECOMMENDED_GAMES_ENABLED) {
        dispatch(fetchPlayerGamesOrder());
      }

      dispatch(fetchSources({ ids: [window.config.dataSourceAllJackpots] }));
    }
  }, [authenticationToken]);

  React.useEffect(() => {
    if (configLoaded && !fullLiveStateLoaded && !inited.current) {
      inited.current = true;

      // lets start prematch and live processing after the page has rendered
      setTimeout(
        () => {
          dispatch(prematchInitialize());
          dispatch(liveInitialize());
        },
        window.config.disableTmplEngine === '1'
          ? 10000
          : parseInt((window.config.delayLoadingSportBetsMS ?? 2000).toString()),
      );
    }
  }, [configLoaded, fullLiveStateLoaded]);

  if (window.config.disableTmplEngine === '1')
    return <div>Templatization Engine Disabled (LiveState: {fullLiveStateLoaded ? 'loaded' : 'not loaded'})</div>;
  return <Routes />;
};

export default App;
