import { FileExtensionItem } from '../constants';
import { formatExtensionsForInputAttribute } from './formatters';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export async function detectDeviceCamera(): Promise<boolean> {
  return new Promise((resolve) => {
    try {
      if (!isMobile) {
        resolve(false);
      }

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            resolve(true);
          }
        });

        resolve(false);
      });
    } catch (event) {
      console.log(event);

      resolve(false);
    }
  });
}

export function getInputAcceptAttribute(
  deviceHasCamera: boolean,
  isCameraSelected: boolean,
  isLibrarySelected: boolean,
  extensions: FileExtensionItem[],
): string | undefined {
  if (
    !isMobile ||
    (deviceHasCamera && isCameraSelected && !isLibrarySelected) ||
    (deviceHasCamera && !isCameraSelected && isLibrarySelected)
  ) {
    return formatExtensionsForInputAttribute(extensions);
  }

  return undefined;
}

// https://www.w3.org/TR/html-media-capture/#the-capture-attribute
export function getInputCaptureAttribute(
  deviceHasCamera: boolean,
  isCameraSelected: boolean,
  isLibrarySelected: boolean,
): 'user' | 'environment' | undefined {
  if (deviceHasCamera && isCameraSelected && !isLibrarySelected) {
    return 'environment';
  }

  return undefined;
}
