import { FileMimeType, FileSizeUnit, GENERIC_FILE_READER_ERROR_MESSAGE } from '../constants';

type FileListIteratee = (file: File, index: number, files: FileList) => void;

export function fileListForEach(files: FileList, iteratee: FileListIteratee): void {
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);

    if (file !== null) {
      iteratee(file, i, files);
    }
  }
}

export function getFileDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const { result } = reader;

      if (typeof result === 'string') {
        resolve(result);
      } else {
        reject(GENERIC_FILE_READER_ERROR_MESSAGE);
      }
    };

    reader.onerror = () => {
      const { error } = reader;

      if (error) {
        reject(error.message);
      }
    };

    reader.readAsDataURL(file);
  });
}

export function getFileArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const { result } = reader;

      if (result instanceof ArrayBuffer) {
        resolve(result);
      } else {
        reject(GENERIC_FILE_READER_ERROR_MESSAGE);
      }
    };

    reader.onerror = () => {
      const { error } = reader;

      if (error) {
        reject(error.message);
      }
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function getFileContentMimeType(file: File): Promise<string> {
  const arrayBuffer = await getFileArrayBuffer(file);

  const byteArray = new Uint8Array(arrayBuffer).subarray(0, 4);

  let bytes = '';

  for (let i = 0; i < byteArray.length; i++) {
    bytes += byteArray[i].toString(16);
  }

  // For future file signatures
  // https://en.wikipedia.org/wiki/List_of_file_signatures
  // https://mimesniff.spec.whatwg.org/
  // This function returned value is compared against "file.type" computed by browser,
  // so if no new signatures are added, the validation will pass since the returned value is also "file.type"

  switch (bytes) {
    case '89504e47':
      return FileMimeType.PNG;

    case '47494638':
      return FileMimeType.GIF;

    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return FileMimeType.JPG;

    case '25504446':
      return FileMimeType.PDF;

    default:
      return file.type;
  }
}

export function getSizeByUnit(size: string): number {
  size = size.trim().replace(' ', '').toUpperCase();

  const value = parseInt(size.replace(/[^0-9]+/g, ''));
  const unit = size.replace(/[^a-zA-Z]+/g, '');

  switch (unit) {
    case FileSizeUnit.B:
      return value;

    case FileSizeUnit.KB:
      return value * 1024;

    case FileSizeUnit.MB:
      return value * 1024 * 1024;

    case FileSizeUnit.GB:
      return value * 1024 * 1024 * 1024;

    default:
      return value * 1024 * 1024;
  }
}
