import moment from 'moment';
import 'moment/dist/locale/ro';
import { get } from 'lodash-es';

import { store } from '../../store';
import { getText } from '../../components/modules/wheel/utils/index';

export const specialDateToString = (date: string | number, format?: string) => {
  if (!date) return;
  const lang = store.getState().application.language;
  const useLongDateToStrings = window.config && window.config.useLongDateToStrings === '1';

  let mDate = null;

  if (lang === 'ro') {
    mDate = moment(date).locale('ro');
  } else {
    mDate = moment(date).locale('en');
  }

  const refDate = mDate;
  const now = moment();

  if (!useLongDateToStrings) {
    if (format) {
      return `${refDate.format(format)}`;
    } else {
      return `${refDate.format('DD MM YYYY')}`;
    }
  }

  let formattedString = `${refDate.format('DD MM YYYY')}`;

  // if in 7 days or more
  if (refDate.isSameOrAfter(now.add(7, 'days'), 'day')) {
    formattedString = `${mDate.format('DD MMMM')}`;
  }

  // if is less than 7 days
  if (refDate.isBetween(now, now.add(6, 'days'), 'day') || refDate.isSame(now.add(6, 'days'), 'day')) {
    formattedString = `${mDate.format('dddd')}`;
  }

  // if tomorrow
  if (refDate.isSame(now.add(1, 'days'), 'day')) {
    formattedString = `Tomorrow ${mDate.format('HH:mm')}`;
  }

  // if today
  if (refDate.isSame(now, 'day')) {
    formattedString = `Today ${mDate.format('HH:mm')}`;
  }

  return formattedString;
};

export const extractAR = (imageType: string) => {
  if (imageType != null) {
    const re = RegExp('ar([0-9.]+)');
    const matches = re.exec(imageType);

    if (matches && matches.length === 2) {
      let val = matches[1];
      val = val.replaceAll('.', '_');

      if (val) {
        return `ar-${val}`;
      }
    }
  }

  return '';
};

export const getLanguageCode = (original: Record<string, any>): string | null => {
  if (typeof original !== 'object') return null;
  const languages = window.config.sportsLanguagesMap ?? {};

  const selectedAppLang: string = store.getState().application.language ?? window.config.defaultLanguage;
  if (languages[selectedAppLang] && original[languages[selectedAppLang]]) {
    return languages[selectedAppLang];
  }

  if (original['2'] || original['0']) {
    return original['2'] ? '2' : '0';
  }

  return null;
};

const isSportText = (original: Record<string, any>, selectedAppLang: string): boolean => {
  if (typeof original !== 'object') return false;

  const languages = window.config.sportsLanguagesMap;

  if (languages[selectedAppLang] != null && original[languages[selectedAppLang]] != null) {
    return true;
  }

  if (original['2'] != null || original['0'] != null) {
    return true;
  }

  return false;
};

const languagesByCode = window.config.languages.reduce(function (map: any, obj) {
  map[obj] = true;
  return map;
}, {});

const isTranslatableText = (original: Record<string, any>, selectedAppLang: string): boolean => {
  if (typeof original !== 'object') return false;

  if (languagesByCode[selectedAppLang] != null && original[selectedAppLang] != null) {
    return true;
  }

  if (original['en'] != null) {
    return true;
  }

  for (let i = 0; i < window.config.languages.length; i++) {
    const l = window.config.languages[i];
    if (original[l] != null) {
      return true;
    }
  }

  return false;
};

const regExp = /<[^>]*>?/gm;

export const getTranslatedText = (original: any) => {
  const appLang: string = store.getState().application.language ?? window.config.defaultLanguage;

  let text = original;

  if (typeof text === 'object') {
    //console.log('DEBUG GLOBALFN [getTranslatedText] typeof === obj', { text, appLang });

    if (isSportText(original, appLang)) {
      text = text[window.config.sportsLanguagesMap?.[appLang]] ?? text['2'] ?? text['0'] ?? '';
    } else if (isTranslatableText(text, appLang)) {
      text = text[appLang] ?? text['en'] ?? '';
      if (text && text.includes?.('>') && text.includes?.('<')) text = text.replace(regExp, '');
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (isTranslatableText(tmp, appLang)) {
        text = tmp[appLang] ?? tmp['en'] ?? '';
        if (text) text = text.replace(regExp, '');
      }
    } catch (e) {
      console.log('[getTranslatedText] Error parsing JSON', { text, appLang });
    }
  }

  return text ?? '';
};

export const processTranslatableText = (input: any, placeholders: any, language: string, t: any) => {
  let text = input;

  if (typeof text === 'object') {
    if (isSportText(text, language)) {
      text = text[window.config.sportsLanguagesMap?.[language]] ?? text['2'] ?? text['0'] ?? '';
    } else if (isTranslatableText(text, language)) {
      let tmp = getText(text, language, '');

      if (!tmp) {
        for (const lang of window.config.languages) {
          if (text[lang]) {
            tmp = text[lang];
            break;
          }
        }
      }

      text = tmp;
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (isTranslatableText(tmp, language)) {
        text = tmp[language] ?? tmp['en'] ?? '';
      }
    } catch (e) {}
  }
  text = text != null ? t(text, placeholders ? placeholders : {}) : '';

  if (text != null && text.includes('{{window.config')) {
    text = text.replace(/{{window.config.([^}]+)}}/g, (_m: any, p1: string) => {
      const v = get(window.config, p1, undefined);

      if (v === undefined) {
        return `{{window.config.${p1}}}`;
      }
      return v;
    });
  }

  return text;
};

export const getIsOver30Days = (date: string | number, daysDiff: number = 30) => {
  // returns true/false if date is over 30 days or not
  // returns null if date is invalid
  if (!date) {
    console.log('[getIsOver30Days]: no date provided ', date);
    return null;
  }

  // const in30Days = moment().add(31, 'days');
  const in30Days = moment().add(daysDiff ? daysDiff + 1 : 31, 'days');
  const mDate = moment(date);

  if (mDate.isValid() === false) {
    console.log('[getIsOver30Days]: date is invalid ', {
      momentDate: moment(date).format('DD MM YYYY'),
      in30Days,
      mDate,
      date,
      daysDiff,
    });

    return null;
  }

  const isOver30Days = mDate.isSameOrAfter(in30Days, 'day');

  // console.log('RESULT [getIsOver30Days]: ', {
  //   date: moment(date).format('DD MM YYYY'),
  //   in30Days,
  //   isOver30Days,
  //   mDate,
  //   daysDiff,
  // });
  return isOver30Days;
};

export const lottoDateToString = (date: string | number) => {
  if (!date) return;
  const lang = store.getState().application.language;

  let mDate = null;

  if (lang === 'ro') {
    mDate = moment(date).locale('ro');
  } else {
    mDate = moment(date).locale('en');
  }

  const refDate = mDate;
  const now = moment();
  // const now = moment('2023-09-08 00:00').valueOf();

  let formattedString = `${refDate.format('DD MMM, HH:mm')}`;

  // if in 7 days or more
  if (refDate.isSameOrAfter(now.add(7, 'days'), 'day')) {
    formattedString = `${mDate.format('DD MMMM')}`;
  }

  // if is less than 7 days
  if (refDate.isBetween(now, now.add(6, 'days'), 'day') || refDate.isSame(now.add(6, 'days'), 'day')) {
    formattedString = `${mDate.format('dddd')}`;
  }

  // if tomorrow
  if (refDate.isSame(now.add(1, 'days'), 'day')) {
    formattedString = `Tomorrow ${mDate.format('HH:mm')}`;
  }

  // if today
  if (refDate.isSame(now, 'day')) {
    formattedString = `Today ${mDate.format('HH:mm')}`;
  }

  return formattedString;
};

export const MONTHS: any = {
  '1': 'Ian',
  '2': 'Feb',
  '3': 'Mart',
  '4': 'Apr',
  '5': 'Mai',
  '6': 'Iun',
  '7': 'Iul',
  '8': 'Aug',
  '9': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

const localeRex = RegExp(/^[A-Za-z]{2,4}([_-][A-Za-z]{4})?([_-]([A-Za-z]{2}|[0-9]{3}))?$/);

const formatNumber = (value: any, comma: string, point: string, decimals: number = 2) => {
  if (!value) return `0${point}00`;

  let v: any = value;
  if (typeof value === 'string') {
    v = parseFloat(value);
  }

  v = v.toFixed(decimals);

  const parts = v.split('.');
  parts[0] = parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);
  return parts.join(point);
};

export const getCurrencyNumberInfo = ({
  locale,
  amount,
  currency,
  digits,
}: {
  locale: string;
  amount: string | number;
  currency: string;
  digits: string | number;
}) => {
  let l = locale;
  if (!l) {
    const lang = store.getState().application.language;
    // @ts-ignore
    if (window.config[`locale_currency_${lang}`]) {
      // @ts-ignore
      l = window.config[`locale_currency_${lang}`];
    }
  }

  if (!l) return {};

  // convert string to number; if it's not a number, set it to empty string
  if (digits && typeof digits === 'string') {
    digits = parseInt(digits);
    if (isNaN(digits)) {
      digits = '';
    }
  }

  if (localeRex.test(l)) {
    // matches a locale string
    const d = digits != null ? (typeof digits === 'string' ? parseFloat(digits) : digits) : 2;
    const c = currency === 'Lei' ? 'RON' : currency;

    try {
      if (l && c && amount != null) {
        const am = typeof amount === 'string' ? parseFloat(amount) : amount;
        const parts = new Intl.NumberFormat(l, {
          style: 'currency',
          currency: c.toUpperCase(),
          minimumFractionDigits: d,
          maximumFractionDigits: d,
        }).formatToParts(am);

        const exists: any = {};
        const types: string[] = [];
        let cu = '';
        let s = '';
        let formattedAmount = '';

        parts.forEach((e) => {
          if (e.type === 'currency') {
            if (e.value.toUpperCase() === c.toUpperCase()) {
              if (!exists['c']) {
                types.push('c');
                exists['c'] = true;
                cu = e.value;
              }
            } else {
              if (!exists['s']) {
                types.push('s');
                exists['s'] = true;
                s = e.value;
              }
            }
          } else {
            if (e.type !== 'literal') {
              if (!exists['a']) {
                types.push('a');
                exists['a'] = true;
              }
              formattedAmount += e.value;
            }
          }
        });

        const formattedString: any[] = [];
        types.forEach((t) => {
          if (t === 'c') {
            formattedString.push(cu);
          } else if (t === 's') {
            formattedString.push(s);
          } else {
            formattedString.push(formattedAmount);
          }
        });

        return {
          types,
          amount: formattedAmount,
          currency: cu,
          symbol: s,
          formattedString: formattedString.join(' '),
        };
      }
    } catch (e) {}
  } else {
    let formatData = null; // it might be a JSON string [{"s": {"RON", "Lei", "USD": "$"}}, {"n": [",",".",2]}, "c": {"RON":"Lei", "MWK": "MWK"}]

    try {
      const tmp = JSON.parse(l);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (tmp && typeof tmp === 'object') {
        formatData = tmp;
      }
    } catch (e) {}

    if (formatData) {
      const exists: any = {};
      const types: string[] = [];
      let cu = '';
      let s = '';
      let formattedAmount = '';

      formatData.forEach((e: any) => {
        if (e.s && !exists['s']) {
          types.push('s');
          s = e.s[currency] ? e.s[currency] : currency;
          exists['s'] = true;
        } else if (e.n && !exists['n']) {
          types.push('a');
          exists['a'] = true;

          formattedAmount = formatNumber(
            amount,
            e.n[0],
            e.n[1],
            digits != null && typeof digits === 'number' ? digits : e.n[2],
          );
        } else if (e.c) {
          types.push('c');
          cu = e.c[currency] ? e.c[currency] : currency;
          exists['c'] = true;
        }
      });

      const formattedString: any[] = [];
      types.forEach((t) => {
        if (t === 'c') {
          formattedString.push(cu);
        } else if (t === 's') {
          formattedString.push(s);
        } else {
          formattedString.push(formattedAmount);
        }
      });

      return {
        types,
        amount: formattedAmount,
        currency: cu,
        symbol: s,
        formattedString: formattedString.join(' '),
      };
    }
  }

  return {};
};

export const getNumberInfo = ({
  locale,
  amount,
  digits,
}: {
  locale: string;
  amount: string | number;
  digits: string | number;
}) => {
  let l = locale;
  if (!l) {
    const lang = store.getState().application.language;
    // @ts-ignore
    if (window.config[`locale_${lang}`]) {
      // @ts-ignore
      l = window.config[`locale_${lang}`];
    }
  }

  if (localeRex.test(l)) {
    const d = digits != null ? (typeof digits === 'string' ? parseFloat(digits) : digits) : 2;

    try {
      if (l && amount != null) {
        const am = typeof amount === 'string' ? parseFloat(amount) : amount;
        const formattedAmount = new Intl.NumberFormat(l, {
          style: 'decimal',
          minimumFractionDigits: d,
          maximumFractionDigits: d,
        }).format(am);

        return {
          amount: formattedAmount,
        };
      }
    } catch (e) {}
  } else {
    let formatData = null; // it might be a JSON string [",",".",2]

    try {
      const tmp = JSON.parse(l);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (tmp && typeof tmp === 'object') {
        formatData = tmp;
      }
    } catch (e) {}

    if (formatData) {
      const formattedAmount = formatNumber(amount, formatData[0], formatData[1], formatData[2]);

      return {
        amount: formattedAmount,
      };
    }
  }

  return {};
};

/**
 * Get formatted currency amount
 * @param locale
 * @param amount
 * @param currency
 * @param digits
 */
export const getFormattedCurrencyAmount = ({
  locale,
  amount,
  currency,
  digits,
}: {
  locale: string;
  amount: string | number;
  currency: string;
  digits: string | number;
}) => {
  const info = getCurrencyNumberInfo({ locale, amount, currency, digits });
  const result: string[] = [];
  info.types?.forEach((key) => {
    if (key === 's') {
      result.push(`<span class="formatted-amount symbol">${info.symbol}</span>`);
    } else if (key === 'a') {
      result.push(`<span class="formatted-amount amount">${info.amount}</span>`);
    } else if (key === 'c') {
      result.push(`<span class="formatted-amount currency">${info.currency}</span>`);
    }
  });
  return result.join(' ');
};

export function changeUserScalableViewportMeta(hasUserRightsZoom = false) {
  const viewportMeta = document.querySelector('meta[name="viewport"]');
  if (viewportMeta) {
    let content = viewportMeta.getAttribute('content') || '';
    if (hasUserRightsZoom) {
      // console.log('add user-scalable=no');
      if (!content.includes('user-scalable=no')) {
        content += ', user-scalable=no';
      }
    } else {
      // console.log('remove user-scalable=no');
      content = content.replace('user-scalable=no', '');
    }
    viewportMeta.setAttribute('content', content.trim());
  }
}
