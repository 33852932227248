// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { matchPath } from 'react-router-dom';

import { endpoints } from '../../api/endpoints';
import StyleIds from '../../constants/css-ids';
import { invalidatePropsFunctionsCache } from '../../page-components/utils/evaluatePropFunction';

const DEBUG = true;

type FetchConfigProps = {
  tenantId: string;
  siteId: string;
};

type FetchConfigResult = {
  data: {
    page_layouts: any;
    set_pages: any;
    set_css_variables: any;
    set_css_light_variables: any;
    set_css_dark_variables: any;
    set_components_defaults: any;
    set_components_data_defaults: any;
    components_data: any;
    components_data_ref: any;
    components: any;
    components_elements: any;
    data_sources: any;
    data_elements: any;
    data_element_types: any;
    break_points: any[];
    common_css: string;
    pages: any;
    fonts: string;
    theme_mode: string;
    popups: any;
    font_icons_svg: string;
    reroute: string;
  };
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

export const fetchConfig = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'templatesConfig/list',
  async ({ tenantId, siteId }, { rejectWithValue }) => {
    DEBUG && console.time('fetchConfig.query');
    try {
      const response = await axios.get(endpoints.templatization.config, {
        params: {
          tenantId,
          siteId,
          path: location.pathname + location.search,
        },
      });

      if (response.data) {
        // test css setting before rendering page
        return new Promise((resolve) => {
          setWebsiteStyles({
            setCSSVariables: response.data['set_css_variables'],
            setCSSLightVariables: response.data['set_css_light_variables'],
            setCSSDarkVariables: response.data['set_css_dark_variables'],
            fonts: response.data['fonts'],
            breakPoints: response.data['break_points'],
            commonCSS: response.data['common_css'],
            themeMode: response.data['theme_mode'],
          });

          setTimeout(() => {
            resolve({ data: response.data, success: true });
          }, 10);
        });
        //return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch config",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

interface SetCSSVariables {
  [id: string]: string;
}

interface PageLayouts {
  [id: string]: {
    projectData: string;
  };
}

interface SetPages {
  [url: string]: {
    page_id: string;
    page_layout_id: string;
  };
}

interface Pages {
  [id: string]: {
    projectData: any;
    a: boolean; // if true then requires user to be authenticated
    pmd: {
      [id: string]: string;
    } | null; // page meta data if any
  };
}

interface Popups {
  [id: string]: {
    projectData: any;
    a: boolean; // if true then requires user to be authenticated
  };
}

interface DataSources {
  [id: string]: {
    id: string;
    name: string;
    items: any[];
    enable_auto_refresh: boolean;
  };
}

interface DataElements {
  [id: string]: {
    id: string;
    name: string;
    data: any[];
  };
}

interface Internal {
  id: string;
  type: string;
  value: any;
}

interface DataElementTypes {
  [id: string]: {
    id: string;
    description: string;
    properties: any[];
    internal: Internal[];
    type_id: string;
    tags: string[];
  };
}

interface ComponentsData {
  [id: string]: {
    [key: string]: string | number | boolean | null;
  };
}

interface ComponentsDataRef {
  [id: string]: string;
}

interface Components {
  [id: string]: any;
}
interface BreakPoints {
  name: string;
  value: string;
}
export interface ConfigReducer {
  setComponentsDefaults: any;
  setComponentsDataDefaults: any;
  setCSSVariables: SetCSSVariables;
  setCSSLightVariables: SetCSSVariables;
  setCSSDarkVariables: SetCSSVariables;
  setPages: SetPages;
  pageLayouts: PageLayouts;
  pages: Pages;
  popups: Popups;
  fonts: string;
  themeMode: string;
  dataSources: DataSources;
  dataElements: DataElements;
  dataElementTypes: DataElementTypes;
  components: Components;
  componentsElements: Components;
  componentsData: ComponentsData;
  componentsDataRef: ComponentsDataRef;
  breakPoints: BreakPoints[];
  commonCSS: string;
  inProgress: boolean;
  loaded: boolean;
  error: any;
  version: number;
  fontIconsSvg: string;
  reroute: string;
  revReroute: { [id: string]: string } | void | null;
  sports: { [id: string]: string } | void | null;
}

export const setWebsiteStyles = (state: any) => {
  window.config.breakpointDesktop = state.setCSSVariables['brk-point-desktop'] ?? '900px';
  window.config.breakpointTablet = state.setCSSVariables['brk-point-tablet'] ?? '900px';

  if (
    Object.keys(state.setCSSVariables).length !== 0 ||
    Object.keys(state.setCSSLightVariables).length !== 0 ||
    Object.keys(state.setCSSDarkVariables).length !== 0
  ) {
    let styleTag = document.getElementById(StyleIds.styleRootId);

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = StyleIds.styleRootId;
      document.head.append(styleTag);
    }

    if (styleTag) {
      let styleTxt = '';
      if (state.fonts) {
        styleTxt += `${state.fonts}\n\n`;
      }
      const commonCss = true;
      if (commonCss) {
        const sortValues: any = {};
        const keys = Object.keys(state.setCSSVariables);

        const varMatch = /var\(--(.*?)\)/g;

        keys.forEach((key) => {
          const value = state.setCSSVariables[key];

          if (!sortValues[key]) {
            sortValues[key] = 0;
          }

          if (value.indexOf('var(') === -1) {
            sortValues[key] += 10000;
          } else {
            let matches;

            while ((matches = varMatch.exec(value)) !== null) {
              const m = matches[1];
              if (m) {
                if (sortValues[m] != null) {
                  sortValues[m] += 1;
                } else {
                  sortValues[m] = 0;
                }
              }
            }
          }
        });

        const entries = Object.entries(state.setCSSVariables);

        entries.sort((a: any, b: any) => {
          try {
            const valA = sortValues[a[0]] || 0;
            const valB = sortValues[b[0]] || 0;
            return valB - valA;
          } catch (e) {
            console.error('Error sorting CSS variables [a,b]', e, a, b);
            return 0;
          }
        });

        if (entries.length) {
          const breakPointVars: any = {
            default: [],
          };

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              breakPointVars.default = [];
            } else {
              breakPointVars[bp.value] = [];
            }
          });

          const re = RegExp('^(.*?)\\[(.*?)\\]$');

          entries.forEach(([key, value]) => {
            const matches = re.exec(key);
            if (matches) {
              if (breakPointVars[matches[2]] != null) {
                breakPointVars[matches[2]].push(`--${matches[1]}: ${value};`);
              }
              return;
            }

            breakPointVars.default.push(`--${key}: ${value};`);
          });

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              styleTxt += `:root {\n${breakPointVars.default.join('\n')}\n}\n`;
            } else {
              if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                styleTxt += `@media only screen and (min-width: ${bp.value}) {\n:root {\n${breakPointVars[
                  bp.value
                ].join('\n')} \n}\n}\n`;
              }
            }
          });
        }
      }

      let themeModes = [];

      if (state.themeMode === 'dark') {
        themeModes = ['dark-mode', 'light-mode'];
      } else {
        themeModes = ['light-mode', 'dark-mode'];
      }

      themeModes.forEach((themeMode, index) => {
        const entries = Object.entries(
          themeMode === 'dark-mode' ? state.setCSSDarkVariables : state.setCSSLightVariables,
        );

        entries.sort((a: any, b: any) => {
          if (a[1].indexOf('var(') !== -1 && b[1].indexOf('var(') !== -1) {
            return a[0].localeCompare(b[0]);
          }
          if (a[1].indexOf('var(') !== -1) return 1;
          if (b[1].indexOf('var(') !== -1) return -1;
          return a[0].localeCompare(b[0]);
        });

        if (entries.length) {
          const breakPointVars: any = {
            default: [],
          };

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              breakPointVars.default = [];
            } else {
              breakPointVars[bp.value] = [];
            }
          });

          const re = RegExp('^(.*?)\\[(.*?)\\]$');

          entries.forEach(([key, value]) => {
            const matches = re.exec(key);
            if (matches) {
              if (breakPointVars[matches[2]] != null) {
                breakPointVars[matches[2]].push(`--${matches[1]}: ${value};`);
              }
              return;
            }

            breakPointVars.default.push(`--${key}: ${value};`);
          });

          if (index === 0) {
            state.breakPoints.forEach((bp: any, index: number) => {
              if (index === 0) {
                styleTxt += `:root {\n${breakPointVars.default.join('\n')}\n}\n`;
              } else {
                if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                  styleTxt += `@media only screen and (min-width: ${bp.value}) {\n:root {\n${breakPointVars[
                    bp.value
                  ].join('\n')} \n}\n}\n`;
                }
              }
            });
          }

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              styleTxt += `.${themeMode} {\n${breakPointVars.default.join('\n')}\n}\n`;
            } else {
              if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                styleTxt += `@media only screen and (min-width: ${bp.value}) {\n.${themeMode} {\n${breakPointVars[
                  bp.value
                ].join('\n')} \n}\n}\n`;
              }
            }
          });
        }
      });

      styleTxt += state.commonCSS ? `\n\n${state.commonCSS}` : '';

      styleTag.textContent = styleTxt;
    }
  }
};

const processConfig = (state: any, action: any) => {
  state.inProgress = false;

  if (action.payload.success) {
    if (typeof action.payload.data['page_layouts'] !== 'undefined')
      state.pageLayouts = action.payload.data['page_layouts'];
    if (typeof action.payload.data['set_pages'] !== 'undefined') {
      state.setPages = action.payload.data['set_pages'];

      if (window.config.cordova && state.setPages['/'] != null) {
        state.setPages['/index.html'] = state.setPages['/'];
      }
    }
    if (typeof action.payload.data['set_components_defaults'] !== 'undefined')
      state.setComponentsDefaults = action.payload.data['set_components_defaults'];
    if (typeof action.payload.data['set_components_data_defaults'] !== 'undefined')
      state.setComponentsDataDefaults = action.payload.data['set_components_data_defaults'];
    if (typeof action.payload.data['components_data'] !== 'undefined')
      state.componentsData = action.payload.data['components_data'];
    if (typeof action.payload.data['components_data_ref'] !== 'undefined')
      state.componentsDataRef = action.payload.data['components_data_ref'];
    if (typeof action.payload.data['pages'] !== 'undefined') state.pages = action.payload.data['pages'];
    if (typeof action.payload.data['components'] !== 'undefined') state.components = action.payload.data['components'];
    if (typeof action.payload.data['data_sources'] !== 'undefined')
      state.dataSources = action.payload.data['data_sources'];
    if (typeof action.payload.data['data_elements'] !== 'undefined')
      state.dataElements = action.payload.data['data_elements'];
    if (typeof action.payload.data['data_element_types'] !== 'undefined')
      state.dataElementTypes = action.payload.data['data_element_types'];

    if (typeof action.payload.data['theme_mode'] !== 'undefined') state.themeMode = action.payload.data['theme_mode'];
    if (typeof action.payload.data['components_elements'] !== 'undefined')
      state.componentsElements = action.payload.data['components_elements'];
    if (typeof action.payload.data['popups'] !== 'undefined') state.popups = action.payload.data['popups'];
    if (typeof action.payload.data['font_icons_svg'] !== 'undefined')
      state.fontIconsSvg = action.payload.data['font_icons_svg'];
    try {
      if (
        typeof action.payload.data['reroute'] !== 'undefined' &&
        action.payload.data['reroute'] !== '' &&
        action.payload.data['reroute']?.[0] === '{' &&
        action.payload.data['reroute']?.[action.payload.data['reroute']?.length - 1] === '}'
      ) {
        state.reroute = action.payload.data['reroute'];

        const r: { [id: string]: any } = JSON.parse(action.payload.data['reroute']);

        if (action.payload.data['rerouteReplace']) {
          try {
            const revReroute: any = {};
            if (r) {
              Object.keys(r).forEach((k) => {
                if (k === '/') return;
                if (r[k]?.replace === false) return;

                const orig = typeof r[k] === 'string' ? r[k] : r[k]?.dest;
                let url = typeof r[k] === 'string' ? r[k] : r[k]?.dest;
                let hasGroups = false;

                if (url.includes('/:')) {
                  // has named groups
                  let parts = url.split('/');
                  parts = parts.map((p: any) => {
                    const tmp = p;

                    if (tmp[0] === ':') {
                      hasGroups = true;
                      const isOptional = tmp[tmp.length - 1] === '?';

                      if (!isOptional) {
                        return `(?<${tmp.substring(1)}>.+)`;
                      } else {
                        return `(?<${tmp.substring(1, tmp.length - 1)}>.+)`;
                      }
                    }
                    return tmp;
                  });

                  url = parts.join('/');
                }

                revReroute[orig] = {
                  rgx: new RegExp(`^${url}$`),
                  dest: k,
                  hasGroups,
                };
              });
            }

            if (Object.keys(revReroute).length) {
              state.revReroute = revReroute;
            }
          } catch (e) {
            console.error(e);
          }
        }

        try {
          if (r) {
            let srcMatch = null;
            let destMatch = null;

            for (const k in r) {
              const orig = typeof r[k] === 'string' ? r[k] : r[k]?.dest;
              if (orig.startsWith('/bets/pre-match/:idSport')) {
                srcMatch = k;
                destMatch = orig;
                break;
              }
            }

            const sports: any = {};

            if (srcMatch) {
              for (const k in r) {
                if (k.includes(':idSport')) continue;

                const orig = typeof r[k] === 'string' ? r[k] : r[k]?.dest;

                const matchSrc = matchPath(
                  {
                    path: srcMatch,
                  },
                  k,
                );

                if (matchSrc?.params?.idSport) {
                  const matchDest = matchPath(
                    {
                      path: destMatch,
                    },
                    orig,
                  );

                  if (matchDest?.params?.idSport) {
                    sports[matchSrc?.params?.idSport] = matchDest?.params?.idSport;
                  }
                }
              }
            }

            if (Object.keys(sports).length) state.sports = sports;
          }
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {
      console.error(e);
    }

    // Let's not store any of the CSS stuff in the state
    // if (typeof action.payload.data['set_css_variables'] !== 'undefined') state.setCSSVariables = action.payload.data['set_css_variables'];
    // if (typeof action.payload.data['set_css_light_variables'] !== 'undefined') state.setCSSLightVariables = action.payload.data['set_css_light_variables'];
    // if (typeof action.payload.data['set_css_dark_variables'] !== 'undefined') state.setCSSDarkVariables = action.payload.data['set_css_dark_variables'];
    // if (typeof action.payload.data['fonts'] !== 'undefined') state.fonts = action.payload.data['fonts'];
    // if (typeof action.payload.data['break_points'] !== 'undefined') state.breakPoints = action.payload.data['break_points'];
    // if (typeof action.payload.data['common_css'] !== 'undefined') state.commonCSS = action.payload.data['common_css'];
    /*
          setWebsiteStyles({
            setCSSVariables: action.payload.data['set_css_variables'],
            setCSSLightVariables: action.payload.data['set_css_light_variables'],
            setCSSDarkVariables: action.payload.data['set_css_dark_variables'],
            fonts: action.payload.data['fonts'],
            breakPoints: action.payload.data['break_points'],
            commonCSS: action.payload.data['common_css'],
            themeMode: action.payload.data['theme_mode'],
          });
          */
  }

  state.version = state.version + 1;
  DEBUG && console.timeEnd('fetchConfig.query');
  //DEBUG && console.log('fetchConfig.fulfilled', action.payload);

  setTimeout(() => {
    invalidatePropsFunctionsCache();
  }, 0);
};

export const templatesConfigSlice = createSlice({
  name: 'templatesConfig',
  initialState: <ConfigReducer>{
    setComponentsDefaults: {},
    setComponentsDataDefaults: {},
    setCSSVariables: {},
    setCSSLightVariables: {},
    setCSSDarkVariables: {},
    setPages: {},
    pageLayouts: {},
    componentsData: {},
    componentsDataRef: {},
    dataSources: {},
    components: {},
    componentsElements: {},
    pages: {},
    popups: {},
    fonts: '',
    themeMode: 'light',
    breakPoints: [] as BreakPoints[],
    commonCSS: '',
    inProgress: false,
    loaded: false,
    error: null,
    version: 0,
    fontIconsSvg: '',
    reroute: '',
    revReroute: null,
    sports: null,
  },
  reducers: {
    reset: (_state) => {
      /* example */
    },
    updatePageContent: (state, action) => {
      if (state.pages[action.payload.id] != null && state.pages[action.payload.id].projectData) {
        state.pages[action.payload.id].projectData = action.payload.content;
      }
    },
    updateComponentDataDefaults: (state, action) => {
      state.setComponentsDataDefaults = action.payload.content;
    },
    updateComponentDefaults: (state, action) => {
      state.setComponentsDefaults = action.payload.content;
    },
    loadConfig: (state, action) => {
      processConfig(state, { payload: { success: true, data: action.payload.data } });

      if (window.config.googleTagManagerId && window.config.googleTagManagerDelayLoading === '1') {
        setTimeout(() => {
          window.initGTM();
        }, 100);
      }

      state.loaded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        processConfig(state, action);

        if (window.config.googleTagManagerId && window.config.googleTagManagerDelayLoading === '1') {
          setTimeout(() => {
            window.initGTM();
          }, 100);
        }

        state.loaded = true;
      })
      .addCase(fetchConfig.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.inProgress = false;
        state.loaded = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchConfig.rejected', action.payload);
      });
  },
});

export const { reset, updatePageContent, updateComponentDataDefaults, updateComponentDefaults, loadConfig } =
  templatesConfigSlice.actions;

export default templatesConfigSlice.reducer;
