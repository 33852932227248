import { useEffect, useMemo, useState } from 'react';

type StepperState = {
  currentStep: number;
  steps: any[];
};

export type StepperConfig = {
    currentStep: number; // begins at 1
    totalSteps?: number; // needed if steps is not provided
    canClickOnPreviousSteps?: boolean;
    onStepChangeCallback?: (step: number) => void;
    steps?: StepperStepContext[];
}

export type StepperStepContext = {
    index: number; // begins at 1
    status: 'done' | 'active' | 'default';
    onClick?: () => void;
    // the next ones should not be set programmatically, but just in case it is needed
    label?: 'default' | 'horizontal' | 'vertical'; // if not set, will be inherited from StepperContext
    labelText?: string;
    stepType?: 'number' | 'icon'; // if not set, will be inherited from StepperContext, but only if we have prevStepsIconName or lastStepIconName
    iconName?: string; // uses the same icon for all prev steps and another one for the last step (StepperContext.prevStepsIconName and StepperContext.lastStepIconName)

}

export type StepperContext = {
    steps: StepperStepContext[];
    currentStep: number;
    // the next ones should not be set programmatically, but just in case it is needed
    label?: 'default' | 'horizontal' | 'vertical';
    stepsType?: 'number' | 'icon';
    prevStepsIconName?: string;
    lastStepIconName?: string;
}

export function useStepper(config: StepperConfig) {

    const [state, setState]  = useState<StepperState>({
        currentStep: config.currentStep,
        steps: [],
    });

    // This effect is used to trigger the onStepChangeCallback when the currentStep changes
    useEffect(() => {
        if (config.onStepChangeCallback) {
            config.onStepChangeCallback(state.currentStep);
        }
    },[state.currentStep]);

    // This effect is used to update the currentStep when the config.currentStep changes
    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            currentStep: config.currentStep,
        }));
    }, [config.currentStep]);

    // This can be triggered only if canClickOnPreviousSteps is true, and we are on a previous step
    const onStepClick = (step: number) => {
        if (step > state.currentStep || !config.canClickOnPreviousSteps) {
            return;
        }
        setState((prevState) => ({
            ...prevState,
            currentStep: step,
            trigger: 'onStepClick',
        }));
    };

    const stepperContext:StepperContext = useMemo(():StepperContext => {
        const totalSteps:number = config.totalSteps ?? config.steps?.length ?? 0;
        if (totalSteps === 0) {
            throw new Error('totalSteps or steps[] must be provided on useStepper(config)');
        }
        const steps:StepperStepContext[] = [];
        const currentStep:number = state.currentStep;

        for (let i = 0; i < totalSteps; i++) {
            const currentIndex = i + 1;
            steps.push({
                index: currentIndex,
                status: currentIndex < currentStep ? 'done' : (currentIndex == currentStep ? 'active' : 'default'),
                onClick: () => onStepClick(currentIndex)
            });
        }
        return {
            steps: steps,
            currentStep: currentStep,
        };
    }, [state.currentStep, config.totalSteps]);


  return useMemo(
    () => ({
      stepperContext
    }),
    [stepperContext],
  );
}

export default useStepper;
