import { call, delay, put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

const timeConversionUnit = 1000;

export function* authenticateAppSaga(action) {
  switch (action.step) {
    case 1:
      //yield delay(1000);
      break;
    case 2:
      console.log('delay(5000)');
      yield delay(5000);
      break;
    case 3:
      console.log('delay(9000)');
      yield delay(9000);
      break;
    default:
      console.log('delay(15000)');
      yield delay(15000);
      // reload if for 30 seconds it doesn't work
      return window.location.reload();
  }

  if (yield call(checkAuthStateSaga)) {
    yield call(authFromStorage);
    return;
  } else if (window.config.guestToken) {
    const data = {
        'access_token': window.config.guestToken,
        'expires_in': 3600 * 24 * 365,
        'token_type': 'Bearer',
        'scope': null,
        'auth_type': 'app'
    };
    yield call(authenticateSaga, data);
    return;
  }

  yield put(actions.resetWallet());
  yield put(actions.resetAccount());

  const axios = ClientAPI.getInstance();

  let authData = {};

  try {
    const response = yield axios({
      url: paths.authentication.APP,
      method: 'post',
    });

    if (!response.status) {
      yield put(actions.startAppAuth(action.step + 1));
      return;
    }

    if (!('OK' === response.status)) {
      console.log('[AUTH] App auth error');
      return;
    }

    authData = response.result;
    yield call(authenticateSaga, authData);
  } catch (error) {
    yield put(actions.startAppAuth(action.step + 1));
    return;
  }
}

export function* checkAuthTimeoutSaga(action) {
  let d = action.expirationTime * timeConversionUnit;
  let MAX_SIGNED_INT_VALUE = 2147483647;

  // delay can't be more than 2147483647
  if (d > MAX_SIGNED_INT_VALUE) {
    d = MAX_SIGNED_INT_VALUE;
  }

  yield delay(d);

  const reduxState = ClientAPI.getStore().getState();
  // Check auth type
  let authType = yield localStorage.getItem('auth_type');
  if (!authType) {
    authType = reduxState.authentication.auth_type;
  }

  if (!authType) {
    yield put(actions.startAppAuth());
    return;
  }
  if ('app' === authType) {
    // Check if token is expired
    let expiredToken = yield call(isAppTokenExpired);
    if (expiredToken) {
      yield put(actions.startAppAuth());
    }

    // There is no refresh token for APP
    return;
  }

  if ('user' === authType) {
    // check token state
    let tokenState = yield call(checkAuthStateSaga, false);
    if (tokenState) {
      return;
    }
  }

  yield put(actions.refreshAuthToken());
}

export function* refreshAuthTokenSaga(action) {
  const reduxState = ClientAPI.getStore().getState();

  let authData = {};
  let refreshToken = yield localStorage.getItem('refresh_token');
  if (!refreshToken) {
    refreshToken = reduxState.authentication.refresh_token;
  }

  if (!refreshToken) {
    yield put(actions.startAppAuth());
    return false;
  }
  authData.refresh_token = refreshToken;

  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.authentication.TOKEN,
      method: 'post',
      data: authData,
    });

    if (!response.status) {
      console.log('[AUTH] Refresh auth_token error');
      return false;
    }

    if (!('OK' === response.status)) {
      console.log('[AUTH] Refresh auth_token error');
      return false;
    }

    authData = response.result;
    if ('token' === authData.auth_type) {
      authData.auth_type = 'user';
    }
    yield call(authenticateSaga, authData);
    return true;
  } catch (error) {
    console.log(error);
    // yield localStorage.removeItem('refresh_token');
    yield call(clearAuthStorage);
    yield put(actions.startAppAuth());
    return false;
  }
}

export function* authenticateSaga(authData) {
  yield call(clearAuthStorage);

  const axios = ClientAPI.getInstance();

  let accessToken, expiresIn, refreshToken, authType, tokenType;

  if (typeof authData['access_token'] !== 'undefined') {
    accessToken = authData.access_token;
  }
  if (typeof authData['token_type'] !== 'undefined') {
    tokenType = authData.token_type;
  }
  if (typeof authData['expires_in'] !== 'undefined') {
    expiresIn = authData.expires_in;
  }
  if (typeof authData['refresh_token'] !== 'undefined') {
    refreshToken = authData.refresh_token;
  }
  if (typeof authData['auth_type'] !== 'undefined') {
    authType = authData.auth_type;
  }

  let authDate = yield Date.now();

  if (!['app', 'user'].includes(authType)) {
    throw new Error(`[ERROR] AuthType "${authType}" is not recognized!`);
  }

  if ('user' === authType) {
    yield call(saveAuthStorage, accessToken, expiresIn, refreshToken, authType, tokenType, authDate);
  }

  if ('app' === authType) {
    yield put(actions.clearAuthentication());
  }

  yield (axios.defaults.headers.common['Authorization'] = tokenType + ' ' + accessToken);

  yield put(actions.authenticate(accessToken, expiresIn, refreshToken, authType, tokenType, authDate));

  yield put(actions.checkAuthTimeout(expiresIn));
}

export function* saveAuthStorage(accessToken, expiresIn, refreshToken, authType, tokenType, authDate) {
  yield localStorage.setItem('access_token', accessToken);
  yield localStorage.setItem('token_type', tokenType);
  yield localStorage.setItem('expires_in', expiresIn);
  yield localStorage.setItem('refresh_token', refreshToken);
  yield localStorage.setItem('auth_type', authType);
  yield localStorage.setItem('auth_date', authDate);
}

export function* clearAuthStorage() {
  yield localStorage.removeItem('access_token');
  yield localStorage.removeItem('token_type');
  yield localStorage.removeItem('auth_type');
  yield localStorage.removeItem('refresh_token');
  yield localStorage.removeItem('expires_in');
  yield localStorage.removeItem('auth_date');
}

export function* checkAuthStateSaga(overwrite = true) {
  let authDate = localStorage.getItem('auth_date');
  if (!authDate) {
    return false;
  }

  let expiresIn = localStorage.getItem('expires_in');
  if (!expiresIn) {
    return false;
  }

  let actualDate = Date.now();
  let timePassed = actualDate - authDate;
  if (timePassed > expiresIn * timeConversionUnit) {
    let authType = localStorage.getItem('auth_type');
    if ('user' === authType) {
      // try to reload token
      let reloadToken = yield call(refreshAuthTokenSaga);
      if (reloadToken) {
        return true;
      }
    }

    return false;
  }

  if (overwrite) {
    yield localStorage.setItem('expires_in', expiresIn - parseInt(timePassed / 1000, 10));
  }
  return true;
}

export function* authFromStorage() {
  let accessToken = yield localStorage.getItem('access_token');
  let tokenType = yield localStorage.getItem('token_type');
  let authType = yield localStorage.getItem('auth_type');
  let refreshToken = yield localStorage.getItem('refresh_token');
  let expiresIn = yield localStorage.getItem('expires_in');
  let authDate = yield localStorage.getItem('auth_date');

  const axios = ClientAPI.getInstance();
  yield (axios.defaults.headers.common['Authorization'] = tokenType + ' ' + accessToken);

  yield put(actions.authenticate(accessToken, expiresIn, refreshToken, authType, tokenType, authDate));

  yield put(actions.checkAuthTimeout(expiresIn));
}

export function* isAppTokenExpired() {
  const reduxState = ClientAPI.getStore().getState();
  let authDate = reduxState.authentication.auth_date;
  if (!authDate) {
    return true;
  }

  let expiresIn = reduxState.authentication.expires_in;
  if (!expiresIn) {
    return true;
  }

  let actualDate = yield Date.now();
  let timePassed = actualDate - authDate;
  if (timePassed > expiresIn * timeConversionUnit) {
    return true;
  }

  return false;
}
