import { normalizeBets } from './bonusEvaluation';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { debug } from './';

export const digitainBonusEvaluation = (rt) => {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const bonusRules = structuredClone(bst.config.digitainBonusRules);

  // const bonusRules = [
  //   {
  //     "Id": 2,
  //     "BetTypeId": 0,
  //     "MinBetStakeCount": "1.1",
  //     "MinBetSum": 50,
  //     "MinFactor": 0,
  //     "MaxFactor": 0,
  //     "CashOutPercent": 0,
  //     "BettingRules": [
  //       {
  //         "OddCount": 5,
  //         "OddNextCount": 6,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 6,
  //         "OddNextCount": 7,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 7,
  //         "OddNextCount": 8,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 8,
  //         "OddNextCount": 9,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 9,
  //         "OddNextCount": 10,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 10,
  //         "OddNextCount": 11,
  //         "OddFactor": 10,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 11,
  //         "OddNextCount": 12,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 12,
  //         "OddNextCount": 13,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 13,
  //         "OddNextCount": 14,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 14,
  //         "OddNextCount": 15,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 15,
  //         "OddNextCount": 16,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 16,
  //         "OddNextCount": 17,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 17,
  //         "OddNextCount": 18,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 18,
  //         "OddNextCount": 19,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 19,
  //         "OddNextCount": 20,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 20,
  //         "OddNextCount": 21,
  //         "OddFactor": 15,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 21,
  //         "OddNextCount": 22,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 22,
  //         "OddNextCount": 23,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 23,
  //         "OddNextCount": 24,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 24,
  //         "OddNextCount": 25,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 25,
  //         "OddNextCount": 26,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 26,
  //         "OddNextCount": 27,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 27,
  //         "OddNextCount": 28,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 28,
  //         "OddNextCount": 29,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 29,
  //         "OddNextCount": 30,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 30,
  //         "OddNextCount": 31,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 31,
  //         "OddNextCount": 32,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 32,
  //         "OddNextCount": 33,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 33,
  //         "OddNextCount": 34,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 34,
  //         "OddNextCount": 35,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 35,
  //         "OddNextCount": 36,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 36,
  //         "OddNextCount": 37,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 37,
  //         "OddNextCount": 38,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 38,
  //         "OddNextCount": 39,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 39,
  //         "OddNextCount": 40,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 40,
  //         "OddNextCount": 41,
  //         "OddFactor": 20,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 41,
  //         "OddNextCount": 42,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 42,
  //         "OddNextCount": 43,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 43,
  //         "OddNextCount": 44,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 44,
  //         "OddNextCount": 45,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 45,
  //         "OddNextCount": 46,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 46,
  //         "OddNextCount": 47,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 47,
  //         "OddNextCount": 48,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 48,
  //         "OddNextCount": 49,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 49,
  //         "OddNextCount": 50,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 50,
  //         "OddNextCount": 100,
  //         "OddFactor": 50,
  //         "OddIncorrectMax": 0
  //       }
  //     ],
  //     "SCT": {
  //       "SportId": 0,
  //       "CountryId": 0,
  //       "TournamentId": 0,
  //       "SportName": null,
  //       "CountryName": null,
  //       "TournamentName": null
  //     },
  //     "TournamentIds": [
  //       -1
  //     ],
  //     "SportIds": [
  //       -1
  //     ],
  //     "BonusCalculateType": 0,
  //     "SportName": null,
  //     "CountryName": null,
  //     "TournamentName": null,
  //     "Status": false,
  //     "IsDefault": false
  //   },
  //   {
  //     "Id": 1,
  //     "BetTypeId": 0,
  //     "MinBetStakeCount": "1.25",
  //     "MinBetSum": 50,
  //     "MinFactor": 0,
  //     "MaxFactor": 0,
  //     "CashOutPercent": 0,
  //     "BettingRules": [
  //       {
  //         "OddCount": 3,
  //         "OddNextCount": 4,
  //         "OddFactor": "1.03",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 4,
  //         "OddNextCount": 5,
  //         "OddFactor": "1.05",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 5,
  //         "OddNextCount": 6,
  //         "OddFactor": "1.1",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 6,
  //         "OddNextCount": 7,
  //         "OddFactor": "1.15",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 7,
  //         "OddNextCount": 8,
  //         "OddFactor": "1.2",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 8,
  //         "OddNextCount": 9,
  //         "OddFactor": "1.25",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 9,
  //         "OddNextCount": 10,
  //         "OddFactor": "1.3",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 10,
  //         "OddNextCount": 11,
  //         "OddFactor": "1.35",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 11,
  //         "OddNextCount": 12,
  //         "OddFactor": "1.4",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 12,
  //         "OddNextCount": 13,
  //         "OddFactor": "1.45",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 13,
  //         "OddNextCount": 14,
  //         "OddFactor": "1.5",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 14,
  //         "OddNextCount": 15,
  //         "OddFactor": "1.55",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 15,
  //         "OddNextCount": 16,
  //         "OddFactor": "1.6",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 16,
  //         "OddNextCount": 17,
  //         "OddFactor": "1.65",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 17,
  //         "OddNextCount": 18,
  //         "OddFactor": "1.75",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 18,
  //         "OddNextCount": 19,
  //         "OddFactor": "1.8",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 19,
  //         "OddNextCount": 20,
  //         "OddFactor": "1.9",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 20,
  //         "OddNextCount": 21,
  //         "OddFactor": 2,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 21,
  //         "OddNextCount": 22,
  //         "OddFactor": "2.1",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 22,
  //         "OddNextCount": 23,
  //         "OddFactor": "2.3",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 23,
  //         "OddNextCount": 24,
  //         "OddFactor": "2.5",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 24,
  //         "OddNextCount": 25,
  //         "OddFactor": "2.7",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 25,
  //         "OddNextCount": 26,
  //         "OddFactor": "2.9",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 26,
  //         "OddNextCount": 27,
  //         "OddFactor": "3.1",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 27,
  //         "OddNextCount": 28,
  //         "OddFactor": "3.3",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 28,
  //         "OddNextCount": 29,
  //         "OddFactor": "3.6",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 29,
  //         "OddNextCount": 30,
  //         "OddFactor": "3.8",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 30,
  //         "OddNextCount": 31,
  //         "OddFactor": 4,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 31,
  //         "OddNextCount": 32,
  //         "OddFactor": "4.3",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 32,
  //         "OddNextCount": 33,
  //         "OddFactor": "4.6",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 33,
  //         "OddNextCount": 34,
  //         "OddFactor": "4.9",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 34,
  //         "OddNextCount": 35,
  //         "OddFactor": "5.2",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 35,
  //         "OddNextCount": 36,
  //         "OddFactor": "5.6",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 36,
  //         "OddNextCount": 37,
  //         "OddFactor": 6,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 37,
  //         "OddNextCount": 38,
  //         "OddFactor": "6.5",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 38,
  //         "OddNextCount": 39,
  //         "OddFactor": 7,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 39,
  //         "OddNextCount": 40,
  //         "OddFactor": 8,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 40,
  //         "OddNextCount": 41,
  //         "OddFactor": "8.5",
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 41,
  //         "OddNextCount": 42,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 42,
  //         "OddNextCount": 43,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 43,
  //         "OddNextCount": 44,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 44,
  //         "OddNextCount": 45,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 45,
  //         "OddNextCount": 46,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 46,
  //         "OddNextCount": 47,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 47,
  //         "OddNextCount": 48,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 48,
  //         "OddNextCount": 49,
  //         "OddFactor": 9,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 49,
  //         "OddNextCount": 50,
  //         "OddFactor": 11,
  //         "OddIncorrectMax": 0
  //       },
  //       {
  //         "OddCount": 50,
  //         "OddNextCount": 100,
  //         "OddFactor": "13.5",
  //         "OddIncorrectMax": 0
  //       }
  //     ],
  //     "SCT": {
  //       "SportId": 0,
  //       "CountryId": 0,
  //       "TournamentId": 0,
  //       "SportName": null,
  //       "CountryName": null,
  //       "TournamentName": null
  //     },
  //     "TournamentIds": [
  //       -1
  //     ],
  //     "SportIds": [
  //       -1
  //     ],
  //     "BonusCalculateType": 0,
  //     "SportName": null,
  //     "CountryName": null,
  //     "TournamentName": null,
  //     "Status": false,
  //     "IsDefault": false
  //   }
  // ];

  // guests are allowed to see the bonus
  // if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
  //   return;
  // }

  if (!(bonusRules && Array.isArray(bonusRules) && bonusRules.length)) {
    debug('no digitain bonus rules');
    return {
      success: false,
    };
  }

  debug('digitainBonusEvaluation', rt, bonusRules);

  const ticket = { ...rt.ticket };

  if (ticket.dayMultiBetNumber) {
    debug('digitainBonusEvaluation dayMultiBetNumber exists');

    return {
      success: true,
      bonuses: [],
    };
  }

  const tbets = normalizeBets(null, ticket.bets);

  debug('digitainBonusEvaluation tbets', tbets);

  // [
  //     {
  //       "Id": 1,
  //       "BetTypeId": 0,
  //       "MinBetStakeCount": 1.25,
  //       "MinBetSum": 2,
  //       "MinFactor": 0,
  //       "MaxFactor": 0,
  //       "CashOutPercent": 0,
  //       "BettingRules": [
  //         {
  //           "OddCount": 6,
  //           "OddNextCount": 7,
  //           "OddFactor": 1.05,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 7,
  //           "OddNextCount": 8,
  //           "OddFactor": 1.1,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 8,
  //           "OddNextCount": 9,
  //           "OddFactor": 1.15,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 9,
  //           "OddNextCount": 10,
  //           "OddFactor": 1.2,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 10,
  //           "OddNextCount": 11,
  //           "OddFactor": 1.25,
  //           "OddIncorrectMax": 0
  //         },
  //         {
  //           "OddCount": 30,
  //           "OddNextCount": 31,
  //           "OddFactor": 2.5,
  //           "OddIncorrectMax": 0
  //         }
  //       ],
  //       "SCT": {
  //         "SportId": 0,
  //         "CountryId": 0,
  //         "TournamentId": 0,
  //         "SportName": null,
  //         "CountryName": null,
  //         "TournamentName": null
  //       },
  //       "TournamentIds": null,
  //       "BonusCalculateType": 0,
  //       "SportName": null,
  //       "CountryName": null,
  //       "TournamentName": null,
  //       "Status": false,
  //       "IsDefault": true
  //     }
  //   ]

  let bonuses = [];

  // check if there are duplicate stakes
  for (const b of tbets) {
    /*
    if (b.betType === 'betBuilder') {
      debug('digitainBonusEvaluation betBuilder');
      return {
        success: true,
        bonuses
      };
    }
    */
    if (b?.idMbo?.startsWith('dup:')) {
      debug('digitainBonusEvaluation duplicate stakes');
      return {
        success: true,
        bonuses,
      };
    }
  }

  let bLen = tbets.length;

  // go through each bonus rule
  for (const bnr of bonusRules) {
    if (bnr.Id === 1) {
      if (!Array.isArray(bnr.BettingRules) || bnr.BettingRules.length === 0) {
        continue;
      }

      bnr.BettingRules.sort((a, b) => a.OddCount - b.OddCount);

      let lbr = null;
      let nlbr = bnr.BettingRules[0];
      let validCount = 0;

      if (ticket.amount < bnr.MinBetSum) {
        debug('digitainBonusEvaluation ticket amount less than min bet sum', ticket.amount, bnr.MinBetSum);
        continue;
      }

      // check each betting rule
      for (const br of bnr.BettingRules) {
        validCount = 0;

        // go through each bet and check min odd value
        for (const b of tbets) {
          if (b.value >= bnr.MinBetStakeCount) {
            validCount++;
            continue;
          } else {
            debug('digitainBonusEvaluation odd value less than min required', b.value, bnr.MinBetStakeCount);
          }
        }

        if (validCount >= br.OddCount) {
          lbr = br;
        } else {
          nlbr = br;
        }

        debug(
          `digitainBonusEvaluation pass blen = ${bLen}, validCount = ${validCount}, br.OddCount = ${br.OddCount}`,
          lbr,
        );

        if (bLen < br.OddCount) {
          debug('digitainBonusEvaluation tbets length less than odd count', tbets.length, br.OddCount);
          break;
        }
      }

      if (lbr !== null) {
        nlbr = null;
        for (const br of bnr.BettingRules) {
          if (br.OddCount === lbr.OddNextCount) {
            nlbr = br;
            break;
          }
        }
      }

      if (lbr !== null || nlbr !== null) {
        bonuses.push({
          type: 'Express',
          factor: lbr ? parseFloat(lbr.OddFactor) : 0,
          percentage: lbr ? parseInt(parseFloat((lbr.OddFactor * 100).toFixed(0)), 10) - 100 : 0,
          nextStep: nlbr
            ? {
                stakesCount: nlbr.OddCount,
                percentage: parseInt(parseFloat((nlbr.OddFactor * 100).toFixed(0)), 10) - 100,
                validStakesCount: validCount,
              }
            : null,
        });
      }
    }
  }

  return {
    success: true,
    bonuses,
  };
};
