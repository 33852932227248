import { FeatureCategory, FeatureLocation, FeatureSubcategory } from '../types/eventData';
import { logEvent } from './logEvent';

export const logRegistrationEvent = (
  featureUsed: string,
  actionsTaken: string[],
  currentStep: string,
  stateErrors: any,
): void => {
  try {
    logEvent(
      null,
      {
        feature_used: featureUsed,
        feature_category: FeatureCategory.Registration,
        feature_subcategory: FeatureSubcategory.NotApplicable,
        feature_location: FeatureLocation.MainContent,
      },
      {
        current_step: currentStep,
        state_errors: stateErrors,
        actions_taken: simplifyActions(actionsTaken),
      },
    );
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

const simplifyActions = (actions: string[]): string[] => {
  return actions.reduce((acc: string[], current: string) => {
    // Skip consecutive onChange events (keep only the first one in a sequence)
    if (current.includes('Change')) {
      const prevAction = acc[acc.length - 1];
      if (prevAction?.includes('Change') && prevAction.startsWith(current.split('Change')[0])) {
        return acc;
      }
    }

    // Skip duplicate validator states
    if (current.includes('Validator')) {
      const prevAction = acc[acc.length - 1];
      if (prevAction === current) {
        return acc;
      }
    }

    return [...acc, current];
  }, []);
};
