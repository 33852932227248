export const NICKNAME_SERVER_VALIDATION = false;

// const DEFAULT_TRIGGER_TIME = 3000;
export const DEFAULT_TRIGGER_TIME = 500;
export const DEFAULT_INCREMENT_TIME = 1000;
export const TYPE_EMAIL = 'email';
export const TYPE_PHONE = 'phone';
export const TYPE_OTP = 'otp';
export const TYPE_PASSWORD = 'password';
export const TYPE_CONFIRM_PASSWORD = 'confirmPassword';
export const TYPE_NICKNAME = 'nickname';
export const TYPE_FIRST_NAME = 'firstName';
export const TYPE_LAST_NAME = 'lastName';
export const TYPE_NIN = 'nin';
export const TYPE_ADDRESS = 'address';
export const TYPE_USERNAME = 'username';
export const RESTRICT_SPECIAL_CHARACTERS = window.config.password.restrictSpecialCharacters === '1';
export const DEBUG_REGISTER_STEPS =  false;