export const ifAnalyticsEnabled = <T extends (...args: any[]) => any>(fn: T) => {
  return (...args: Parameters<T>) => {
      if (window.config.analyticsEnabled === '0') return;
      return fn(...args);
  };
};

export const ifPixelAnalyticsEnabled = <T extends (...args: any[]) => any>(fn: T) => {
  return (...args: Parameters<T>) => {
    if (window.config.pixelAnalyticsEnabled === '0') return;
    return fn(...args);
  };
};
