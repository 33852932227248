import { ProductTypeNames } from '@/constants/product';
import { BonusConflict, BonusConflictReason, BonusType, BonusTypeName } from '@/constants/bonus';
import { paymentProviderNameById } from '@/constants/paymentProvider';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import { getFormattedCurrencyAmount } from '@/page-components/utils/functions';

interface BonusConflictInterface {
  message: string;
  translatePlaceholders?: { [key: string]: any };
}
interface BonusInterface {
  id?: number | string;
  name?: string;
  product?: string[];
  payment_method?: string[];
  payment_method_raw?: number[];
  amount?: number;
  currency?: string;
  amount_display?: string; // what is shown in the frontend (amount + currency) or (free spins) or (tokens x value)
  end_date?: string;
  show_date?: boolean;
  terms_url?: string;
  cta_text?: string;
  bonus_conflicts?: BonusConflictInterface[];
  bonus_type?: string;
  bonus_type_id?: number;
  description?: string;
  image?: string;
  icon?: string;
  deposit?: {
    min?: number;
    max?: number;
  };
}

class Bonus implements BonusInterface {
  id?: number | string;
  rawData?: any;
  name?: string;
  product?: string[];
  payment_method?: string[];
  payment_method_raw?: number[];
  amount?: number;
  currency?: string;
  amount_display?: string; // what is shown in the frontend (amount + currency) or (free spins) or (tokens x value)
  end_date?: string;
  show_date?: boolean;
  terms_url?: string;
  cta_text?: string;
  bonus_conflicts?: BonusConflictInterface[];
  bonus_type?: string;
  bonus_type_id?: number;
  description?: string;
  image?: string;
  icon?: string;
  deposit?: {
    min?: number;
    max?: number;
  };
  constructor(bonus: any, award?: any) {
    if (bonus && bonus.bonus_available_id !== undefined) {
      this.initFromBonusAvailable(bonus, award);
    } else {
      this.isNoBonusType();
    }
  }

  haveInfo(): boolean {
    return this.id !== undefined;
  }

  /**
   * Set bonus to no bonus type
   * used on deposit page when no bonus is selected
   * @returns void
   */
  isNoBonusType(): void {
    this.id = 0;
    this.rawData = {};
    this.name = 'Without Bonus';
    this.product = [];
    this.payment_method = [];
    this.payment_method_raw = [];
    this.amount = 0;
    this.currency = '';
    this.amount_display = 'Deposit without Bonus';
    this.end_date = '';
    this.show_date = false;
    this.terms_url = '';
    this.cta_text = 'No Bonus';
    this.bonus_conflicts = [];
    this.bonus_type = 'Deposit';
    this.bonus_type_id = 1;
    this.description = '';
    this.image = '';
    this.icon = '';
    this.deposit = {
      min: 0,
      max: 0,
    };
  }

  initFromBonusAvailable(bonus: any, award?: any) {
    this.id = bonus.bonus_available_id;
    this.name = bonus.bonus_available_name;
    this.rawData = bonus;

    this.amount = bonus.bonus_available_max; // value is not in cents;
    this.currency = bonus.currency_code ?? window.config.defaultCurrency;
    this.amount_display = ''; // calculated later
    this.end_date = bonus.bonus_campaign_end;
    this.show_date = true;
    this.terms_url = bonus.terms_url ?? '';
    this.cta_text = bonus.cta_text ?? 'GET BONUS';
    this.bonus_conflicts = [];
    this.bonus_type = bonus.bonus_available_type;
    this.bonus_type_id = bonus.bonus_available_type;
    this.description = bonus.description ?? '';
    this.image = bonus.deposit_wizard_image ?? '';
    this.icon = bonus.icon_image ?? '';

    this.deposit = {
      min: Number(award?.bonusMin ? award.bonusMin/100 : bonus.bonus_available_min), // value is not in cents / 100,
      max: Number(award?.bonusMax ? award.bonusMax/100 : bonus.bonus_available_max), // value is not in cents / 100,
    };

    this.product = bonus.eligible_products.map((product: any) => {
      return { id: product, name: ProductTypeNames[product] };
    });

    if (bonus.bonus_conflicts !== undefined) {
      // check if it's an object
      if (typeof bonus.bonus_conflicts === 'object') {
        this.bonus_conflicts = Object.keys(bonus.bonus_conflicts).map((index: any) => {
          return BonusConflictReason[index]
            ? { message: BonusConflictReason[index] }
            : { message: BonusConflictReason[BonusConflict.unknown] };
        });
      }
    }

    if (bonus.eligible_payment_methods !== undefined) {
      this.payment_method_raw = bonus.eligible_payment_methods;
      this.payment_method = bonus.eligible_payment_methods.map((payment_method: any) => {
        return paymentProviderNameById[payment_method] ?? '';
      });
    }

    // @ts-ignore
    this.bonus_type = BonusTypeName[this.bonus_type_id] ?? '';

    this.amount_display = this.calculateAmountDisplay(award, this.deposit.max);
  }

  calculateAmountDisplay(award?: any, depositValue?: any): string {
    if (depositValue) {
      // @ts-ignore
      if (this?.deposit?.min > depositValue) {
        return 'No Bonus';
      }
    }
    if (this.id === 0) {
      return this.amount_display ?? '';
    }
    let amountToDisplay: string = '';

    const bonus = this.rawData;
    switch (this.bonus_type_id) {
      case BonusType.freeBetLotto:
      case BonusType.freeBetSport:
        if (award) {
          amountToDisplay = `${award.tokenCount} x ${award.awardAmount / 100} <small>${formatCurrency(this.currency)}</small>`;
        } else {
          amountToDisplay = `${bonus.bonus_type_specific.tokenCount} x 
          ${getFormattedCurrencyAmount({
            locale: '',
            amount: (bonus.bonus_type_specific.tokenValue / 100)!,
            currency: this.currency!,
            digits: '0',
          })}`;
        }
        break;
      case BonusType.freeSpin:
        amountToDisplay = `${award ? award.awardAmount : bonus.bonus_type_specific?.fs_count} <small>${formatCurrency('FS')}</small>`;
        break;
      default:
        amountToDisplay =
          // (award ? award.awardAmount / 100 : this.amount) + ' <small>' + formatCurrency(this.currency) + '</small>';
          getFormattedCurrencyAmount({
            locale: '',
            amount: (award ? award.awardAmount / 100 : this.amount)!,
            currency: this.currency!,
            digits: 0,
          });
        break;
    }
    return amountToDisplay;
  }

  getBonusId(): number | string | null {
    return this.id ?? null;
  }

  getBonusName(): string {
    return this.name ?? '';
  }

  getBonusProducts(): string[] {
    return this.product ?? [];
  }

  getBonusAmount(): number | undefined {
    return this.amount;
  }

  getBonusCurrency(): string {
    return this.currency ?? '';
  }

  getBonusAmountDisplay(award?: any, depositValue?: any): string {
    return this.calculateAmountDisplay(award, depositValue);
  }

  getBonusEndDate(): string {
    return this.end_date ?? '';
  }

  getBonusShowDate(): boolean {
    return !!this.show_date;
  }

  getBonusTermsUrl(): string {
    return this.terms_url ?? '';
  }

  getBonusCtaText(): string {
    return this.cta_text ?? '';
  }

  getBonusConflicts(): BonusConflictInterface[] {
    return this.bonus_conflicts ?? [];
  }

  haveConflicts(): boolean {
    // @ts-ignore
    return this.bonus_conflicts?.length > 0;
  }
  getBonusType(): string {
    return this.bonus_type ?? '';
  }

  getBonusTypeId(): number {
    return this.bonus_type_id ?? 0;
  }

  getBonusDescription(): string {
    return this.description ?? '';
  }

  getBonusDepositMin(): number {
    return this.deposit?.min ?? 0;
  }

  getBonusDepositMax(): number {
    return this.deposit?.max ?? 0;
  }

  getBonusDeposit(): { min: number | undefined; max: number | undefined; } {
    return { min: this.getBonusDepositMin(), max: this.getBonusDepositMax() };
  }

  getBonusPaymentMethods(): string[] {
    return this.payment_method ?? [];
  }

  getBonusPaymentMethodsRaw(): number[] {
    return this.payment_method_raw ?? [];
  }

  getBonusPaymentMethodsDisplay(): string {
    return this.payment_method ? this.payment_method.join(', ') : '';
  }

  getBonusImage(): string {
    return this.image ?? '';
  }

  getBonusIcon(): string {
    return this.icon ?? '';
  }
}

export default Bonus;
