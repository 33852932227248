import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { cloneDeep, round } from 'lodash-es';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

//import './index.scss';

type Super8Props = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Super8 = (componentProps: Super8Props) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const accessToken = useAppSelector((state) => state.authentication?.access_token);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );
  const navigate = useNavigate();

  const defaultDialogsState = {
    prize: {
      open: false,
      round: null,
      data: null,
    },
    prediction: {
      open: false,
      round: null,
      data: null,
      betOption: null,
      index: 0,
      saving: false,
    },
    changePrediction: {
      open: false,
      round: null,
      data: null,
      edit: {},
      saving: false,
    },
    leaderboard: {
      open: false,
      data: null,
      loading: false,
    },
    results: {
      open: false,
      data: null,
      type: 'current', // current, all
      round: null,
      opened: {},
    },
  };

  const [state, setState] = React.useState<any>({
    data: null,
    dialogs: cloneDeep(defaultDialogsState),
  });

  const closeDialogs = React.useCallback(() => {
    setState((v: any) => ({
      ...v,
      dialogs: cloneDeep(defaultDialogsState),
    }));
  }, []);

  const showPrizeDialog = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const round = event.currentTarget.getAttribute('data-round');
    if (round) {
      setState((v: any) => {
        const roundData = v.data?.rounds?.find((r: any) => r.id === round);
        return {
          ...v,
          dialogs: {
            ...v.dialogs,
            prize: {
              open: true,
              round: round,
              data: cloneDeep(roundData),
            },
          },
        };
      });
    }
  }, []);

  const showPredictionDialog = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!authenticated) {
        navigate('/login');
        return;
      }
      const round = event.currentTarget.getAttribute('data-round');
      if (round) {
        setState((v: any) => {
          let roundData = v.data?.rounds?.find((r: any) => r.id === round);
          if (roundData) {
            roundData = cloneDeep(roundData);
          }
          return {
            ...v,
            dialogs: {
              ...v.dialogs,
              prediction: {
                open: true,
                round: round,
                data: roundData,
                betOption: roundData.bet_options[0],
                index: 0,
              },
            },
          };
        });
      }
    },
    [authenticated, navigate],
  );

  const showChangePredictionDialog = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!authenticated) {
        navigate('/login');
        return;
      }

      const round = event.currentTarget.getAttribute('data-round');
      if (round) {
        setState((v: any) => {
          const roundData = v.data?.rounds?.find((r: any) => r.id === round);

          return {
            ...v,
            dialogs: {
              ...v.dialogs,
              changePrediction: {
                open: true,
                round: round,
                data: cloneDeep(roundData),
                edit: {},
                saving: false,
              },
            },
          };
        });
      }
    },
    [authenticated, navigate],
  );

  const toggleLeaderboardDialog = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!state.data.id) return;

      if (state.dialogs.leaderboard.open) {
        setState((v: any) => {
          return {
            ...v,
            dialogs: {
              ...v.dialogs,
              leaderboard: {
                ...v.dialogs.leaderboard,
                open: false,
                round: '',
                loading: true,
              },
            },
          };
        });
        return;
      }

      setState((v: any) => {
        return {
          ...v,
          dialogs: {
            ...v.dialogs,
            leaderboard: {
              ...v.dialogs.leaderboard,
              open: true,
              round: '',
              loading: true,
            },
          },
        };
      });

      const headers: any = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios
        .get(
          `${window.config.super8ApiUrl}/player/leaderboard/campaign/${state.data.id}?top=${window.config.super8LeaderboardLength}`,
          { headers: headers },
        )
        .then((response) => {
          setState((v: any) => {
            return {
              ...v,
              dialogs: {
                ...v.dialogs,
                leaderboard: {
                  ...v.dialogs.leaderboard,
                  data: response.data,
                  loading: false,
                },
              },
            };
          });
        });
    },
    [accessToken, state],
  );

  const showResultsDialog = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const round = event.currentTarget.getAttribute('data-round');
      setState((v: any) => {
        return {
          ...v,
          dialogs: {
            ...v.dialogs,
            results: {
              open: true,
              data: null,
              type: 'current', // current, all
              round: v.data?.rounds?.[0]?.id,
              opened: {},
            },
          },
        };
      });

      const headers: any = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios.get(`${window.config.super8ApiUrl}/player/history`, { headers: headers }).then((response) => {
        setState((v: any) => {
          const opened: any = {};
          if (round) {
            opened[round] = true;
          } else if (response.data?.[0]?.rounds?.[0]) {
            opened[response.data?.[0]?.rounds?.[0]?.id] = true;
          }

          return {
            ...v,
            dialogs: {
              ...v.dialogs,
              results: {
                ...v.dialogs.results,
                data: response.data?.[0] ?? null,
                opened,
              },
            },
          };
        });
      });
    },
    [accessToken],
  );

  const onToggleRoundVisibility = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const round = event.currentTarget.getAttribute('data-round');
    if (round) {
      setState((v: any) => {
        const opened = cloneDeep(v.dialogs.results.opened);

        if (opened[round]) {
          delete opened[round];
        } else {
          opened[round] = true;
        }

        return {
          ...v,
          dialogs: {
            ...v.dialogs,
            results: {
              ...v.dialogs.results,
              opened,
            },
          },
        };
      });
    }
  }, []);

  const onResultsTab = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const tab = event.currentTarget.getAttribute('data-tab');
    setState((v: any) => {
      return {
        ...v,
        dialogs: {
          ...v.dialogs,
          results: {
            ...v.dialogs.results,
            open: true,
            type: tab === 'all' ? 'all' : 'current', // current, all
          },
        },
      };
    });
  }, []);

  const onActionButton = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const boId = event.currentTarget.getAttribute('data-id');
    const type = event.currentTarget.getAttribute('data-type');
    const set = !isNaN(parseInt(event.currentTarget.getAttribute('data-set') ?? ''))
      ? parseInt(event.currentTarget.getAttribute('data-set') ?? '')
      : 0;
    if (boId && type) {
      setState((v: any) => {
        const newV = cloneDeep(v);
        const roundData: any = newV.dialogs.prediction.open
          ? newV.dialogs.prediction.data
          : newV.dialogs.changePrediction.data;

        const bo: any = roundData.bet_options.find((bo: any) => bo.id === boId);

        if (roundData) {
          try {
            if (bo?.betting_type === 'SINGLE_VALUE') {
              const noValue = bo.player_result == null;

              let player_result = bo.player_result ?? 0;
              player_result = !isNaN(parseInt(player_result)) ? parseInt(player_result) : 0;

              if (type === 'inc') {
                player_result += 1;
              } else if (type === 'dec') {
                player_result -= 1;
                if (player_result < 0) player_result = 0;
              }

              if (noValue) {
                player_result = 0;
              }

              bo.player_result = player_result;
            } else if (bo.betting_type === 'DOUBLE_VALUE') {
              const noValue = bo.player_result == null;
              let player_result = bo.player_result ?? [0, 0];
              player_result = Array.isArray(player_result) ? player_result : [0, 0];

              if (type === 'inc') {
                player_result[set] += 1;
              } else if (type === 'dec') {
                player_result[set] -= 1;
                if (player_result[set] < 0) player_result[set] = 0;
              }

              if (noValue) {
                player_result = [0, 0];
              }

              bo.player_result = player_result;
            } else if (bo.betting_type === 'PREDEFINED') {
              const player_result = bo.betting_outcomes?.find((bo: any) => bo.id === type);

              if (player_result) {
                bo.player_result = player_result.id;
              }
            }
          } catch (e) {
            console.error(e);
          }
        }

        if (newV.dialogs.prediction.open) {
          newV.dialogs.prediction.data = roundData;
          newV.dialogs.prediction.betOption = bo;
        } else {
          newV.dialogs.changePrediction.data = roundData;
        }

        return newV;
      });
    }
  }, []);

  const onActionChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const boId = e.currentTarget.getAttribute('data-id');
    const value = e.target.value;
    if (boId && value && !isNaN(parseInt(e.target.value))) {
      setState((v: any) => {
        const newV = cloneDeep(v);
        const roundData: any = newV.dialogs.prediction.open
          ? newV.dialogs.prediction.data
          : newV.dialogs.changePrediction.data;

        if (roundData) {
          try {
            const bo: any = roundData.bet_options.find((bo: any) => bo.id === boId);

            bo.player_result = parseInt(value);
            if (bo.player_result < 0) {
              bo.player_result = 0;
            }
            if (newV.dialogs.prediction.open) {
              newV.dialogs.prediction.data = roundData;
              newV.dialogs.prediction.betOption = bo;
            } else {
              newV.dialogs.changePrediction.data = roundData;
            }
          } catch (e) {
            console.error(e);
          }
        }

        return newV;
      });
    }
  }, []);

  const onPreviousBetOption = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setState((v: any) => {
      let newIndex = v.dialogs.prediction.index - 1;
      if (newIndex < 0) {
        newIndex = 0;
      }

      return {
        ...v,
        dialogs: {
          ...v.dialogs,
          prediction: {
            ...v.dialogs.prediction,
            // @ts-ignore
            betOption: v.dialogs.prediction?.data?.bet_options?.[newIndex],
            index: newIndex,
          },
        },
      };
    });
  }, []);
  const onNextBetOption = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setState((v: any) => {
      let newIndex = v.dialogs.prediction.index + 1;
      let shouldSave = false;
      // @ts-ignore
      if (newIndex + 1 > v.dialogs.prediction?.data?.bet_options?.length) {
        // @ts-ignore
        newIndex = v.dialogs.prediction.data.bet_options.length - 1;
        shouldSave = true;

        console.log('SavePrediction[newIndex]', newIndex);
      }

      const newData = {
        ...v,
        dialogs: {
          ...v.dialogs,
          prediction: {
            ...v.dialogs.prediction,
            // @ts-ignore
            betOption: v.dialogs.prediction?.data?.bet_options?.[newIndex],
            index: newIndex,
          },
        },
      };

      if (shouldSave) {
        newData.dialogs.prediction.saving = true;
      }

      return newData;
    });
  }, []);

  const onEditOption = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    const boId = event.currentTarget.getAttribute('data-id');
    const isCancel = event.currentTarget.getAttribute('data-is-cancel');
    if (boId) {
      setState((v: any) => {
        const newV = cloneDeep(v);
        const edit: any = newV.dialogs.changePrediction.edit;

        if (edit[boId]) {
          edit[boId] = false;
        } else {
          edit[boId] = true;
        }

        if (isCancel === 'true') {
          // reset data for bet option
          const roundData: any = newV.dialogs.changePrediction.data;
          const bo: any = roundData.bet_options.find((bo: any) => bo.id === boId);

          const originalRoundData: any = newV.data?.rounds?.find((r: any) => r.id === roundData.id);
          const originalBo: any = originalRoundData.bet_options.find((bo: any) => bo.id === boId);

          if (bo && originalBo) {
            bo.player_result = Array.isArray(originalBo.player_result)
              ? [...originalBo.player_result]
              : originalBo.player_result;
          }
        }

        return newV;
      });
    }
  }, []);

  const onModifyOption = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const boId = event.currentTarget.getAttribute('data-id');
      if (boId) {
        const headers: any = {
          Authorization: `Bearer ${accessToken}`,
        };

        const postData: any = { results: {} };
        if (state.data) {
          postData['round_id'] = state.dialogs.changePrediction?.data?.id;
          const originalRoundData: any = state.data?.rounds?.find((r: any) => r.id === postData['round_id']);
          originalRoundData.bet_options?.forEach?.((betOption: any) => {
            // @ts-ignore
            postData.results[betOption.id] = Array.isArray(betOption.player_result)
              ? [...betOption.player_result]
              : betOption.player_result;
          });
        }

        const changePrediction: any = state.dialogs.changePrediction;
        const bo: any = changePrediction.data.bet_options.find((bo: any) => bo.id === boId);

        if (
          (bo.betting_type !== 'DOUBLE_VALUE' && bo.player_result != postData.results[boId]) ||
          (bo.betting_type === 'DOUBLE_VALUE' &&
            (bo.player_result?.[0] != postData.results[boId]?.[0] ||
              bo.player_result?.[1] != postData.results[boId]?.[1]))
        ) {
          postData.results[boId] = Array.isArray(bo.player_result) ? [...bo.player_result] : bo.player_result;

          setState((v: any) => ({
            ...v,
            dialogs: {
              ...v.dialogs,
              changePrediction: {
                ...v.dialogs.changePrediction,
                saving: true,
              },
            },
          }));

          axios
            .post(`${window.config.super8ApiUrl}/player/bet-options/results`, postData, {
              headers: headers,
            })
            .then((_) => {
              setState((v: any) => {
                const newV = cloneDeep(v);
                const changePrediction: any = newV.dialogs.changePrediction;
                const bo: any = changePrediction.data.bet_options.find((bo: any) => bo.id === boId);

                if (bo) {
                  const player_result = bo.player_result;
                  const originalRoundData: any = newV.data?.rounds?.find((r: any) => r.id === changePrediction.data.id);
                  const originalBo: any = originalRoundData.bet_options.find((bo: any) => bo.id === boId);

                  if (originalBo) {
                    originalBo.player_result = Array.isArray(player_result) ? [...player_result] : player_result;
                  }

                  changePrediction.edit[boId] = false;
                }

                changePrediction.saving = false;

                return newV;
              });
            });
        } else {
          setState((v: any) => {
            const newV = cloneDeep(v);
            const changePrediction: any = newV.dialogs.changePrediction;
            changePrediction.edit[boId] = false;
            changePrediction.saving = false;

            return newV;
          });
        }
      }
    },
    [state, accessToken],
  );

  React.useEffect(() => {
    if (state.dialogs.prediction.saving) {
      const headers: any = {
        Authorization: `Bearer ${accessToken}`,
      };

      const postData: any = { results: {} };
      if (state.dialogs.prediction.data) {
        // @ts-ignore
        postData['round_id'] = state.dialogs.prediction?.data?.id;
        // @ts-ignore
        state.dialogs.prediction?.data?.bet_options?.forEach?.((betOption: any) => {
          // @ts-ignore
          postData.results[betOption.id] = betOption.player_result;
        });
      }

      axios
        .post(`${window.config.super8ApiUrl}/player/bet-options/results`, postData, {
          headers: headers,
        })
        .then((_) => {
          setState((v: any) => {
            const newData = cloneDeep(v);

            newData.dialogs = cloneDeep(defaultDialogsState);
            newData.data?.rounds?.forEach((round: any) => {
              if (round.id === postData['round_id']) {
                let hasOptionsSet = false;
                let hasLocked = false;

                round.bet_options.forEach((betOption: any) => {
                  if (postData.results[betOption.id] != null) {
                    betOption.player_result = postData.results[betOption.id];
                    hasOptionsSet = true;
                  }

                  if (betOption.locked) {
                    hasLocked = true;
                  }
                });

                if (hasOptionsSet) {
                  round.has_options_set = true;
                }

                if (hasLocked) {
                  round.has_locked = true;
                }
              }
            });

            return newData;
          });
        });
    }
  }, [accessToken, state.dialogs.prediction.saving, state.dialogs.prediction.data]);

  React.useEffect(() => {
    if (accessToken) {
      const headers: any = {
        Authorization: `Bearer ${accessToken}`,
      };

      axios.get(`${window.config.super8ApiUrl}/player/status`, { headers: headers }).then((response) => {
        console.log(response);
        if (Array.isArray(response.data) && response.data.length > 0) {
          const super8Data = response.data[0];

          super8Data.rounds.sort((a: any, b: any) => {
            return b.start_date?.localeCompare(a.start_date);
          });

          super8Data.rounds.forEach((round: any) => {
            let hasOptionsSet = false;
            let allLocked = true;
            let hasLocked = false;
            round.bet_options.forEach((betOption: any) => {
              if (betOption.player_result) {
                hasOptionsSet = true;
              }
              if (!betOption.locked) {
                allLocked = false;
              }
              if (betOption.locked) {
                hasLocked = true;
              }
            });

            round.has_options_set = hasOptionsSet;
            round.all_locked = allLocked;
            round.has_locked = hasLocked;
          });

          setState((state: any) => ({
            ...state,
            data: super8Data,
          }));
        }
      });
    }
  }, [accessToken]);

  const contextValue = React.useMemo(() => {
    return {
      ...(state.data ?? {}),
      dialogs: state.dialogs,
      dialogsActions: {
        closeDialogs: closeDialogs,
        showPrizeDialog: showPrizeDialog,
        showPredictionDialog: showPredictionDialog,
        showChangePredictionDialog: showChangePredictionDialog,
        onActionButton,
        onActionChange,
        onPreviousBetOption,
        onNextBetOption,
        onEditOption,
        onModifyOption,
        toggleLeaderboardDialog,
        showResultsDialog,
        onResultsTab,
        onToggleRoundVisibility,
      },
    };
  }, [
    dataElementContext,
    componentProps,
    state,
    closeDialogs,
    showPrizeDialog,
    showPredictionDialog,
    showChangePredictionDialog,
    onActionButton,
    onActionChange,
    onPreviousBetOption,
    onNextBetOption,
    onEditOption,
    onModifyOption,
    toggleLeaderboardDialog,
    showResultsDialog,
    onResultsTab,
    onToggleRoundVisibility,
  ]);

  console.log('Super8[state]', state);
  console.log('Super8[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Super8;
