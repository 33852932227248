import * as defs from './defs';
import { debug } from '../index';

import { TaxCalculatorAO } from './ao';
import { TaxCalculatorKE } from './ke';
import { TaxCalculatorMW } from './mw';
import { TaxCalculatorMZ } from './mz';
import { TaxCalculatorRO } from './ro';
import { TaxCalculatorZM } from './zm';

const TaxCalculators: { [key: string]: defs.TaxCalculatorBase } = {
  ao: new TaxCalculatorAO(),
  ke: new TaxCalculatorKE(),
  mw: new TaxCalculatorMW(),
  mz: new TaxCalculatorMZ(),
  ro: new TaxCalculatorRO(),
  zm: new TaxCalculatorZM(),
};

type TaxResponses = { [key: string]: defs.TaxResponse };

export const calculateTax = (requests: defs.TaxRequest[]): TaxResponses => {
  if (!window.config.taxCode) {
    throw new Error('tax code not set');
  }

  const tc = window.config.taxCode.toLowerCase();

  if (tc in TaxCalculators) {
    const responses: TaxResponses = {};

    for (const req of requests) {
      const tr = TaxCalculators[tc].calculateTax(req);

      debug('calculateTax', tc, req, tr);

      responses[req.key] = tr;
    }

    return responses;
  }

  throw new Error('tax code not implemented');
};

export const calculateDigitainProgressiveBonusAmount = (
  maxWinAmount: number,
  minWinAmount: number,
  stakeAmount: number,
  bonusAmountFactor: number,
) => {
  let tc = window.config.taxCode ?? 'default';
  tc = tc.toLowerCase();

  const res: { [key: string]: number } = {};

  debug('calculateDigitainProgressiveBonusAmount', tc, maxWinAmount, minWinAmount, stakeAmount, bonusAmountFactor);

  if (bonusAmountFactor === 0) {
    res.maxAmount = 0;
    res.totalMaxWinAmount = maxWinAmount;
    res.minAmount = 0;
    res.totalMinWinAmount = minWinAmount;
    return res;
  }

  if (maxWinAmount === 0 || minWinAmount === 0) {
    res.maxAmount = 0;
    res.totalMaxWinAmount = 0;
    res.minAmount = 0;
    res.totalMinWinAmount = 0;
    return res;
  }

  switch (tc) {
    case 'mw':
      res.maxAmount = (maxWinAmount - stakeAmount) * (bonusAmountFactor - 1);
      res.totalMaxWinAmount = maxWinAmount + res.maxAmount;
      res.minAmount = (minWinAmount - stakeAmount) * (bonusAmountFactor - 1);
      res.totalMinWinAmount = minWinAmount + res.minAmount;
      break;
    default:
      res.maxAmount = maxWinAmount * (bonusAmountFactor - 1);
      res.totalMaxWinAmount = maxWinAmount + res.maxAmount;
      res.minAmount = minWinAmount * (bonusAmountFactor - 1);
      res.totalMinWinAmount = minWinAmount + res.minAmount;
  }

  return res;
};
