interface ProductType {
  [key: string]: number;
}

interface ProductTypeStrings {
    [key: string]: number;
}

interface ProductTypeNames {
  [key: number|string]: string;
}

export const ProductType: ProductType = {
  casino: 1,
  sport: 2,
  lotto: 3,
  live_casino: 4,
};

export const ProductTypeStrings: ProductTypeStrings = {
    'CASINO': 1,
    'SPORT': 2,
    'LOTTO': 3,
    'LIVE_CASINO': 4,
};

export const ProductTypeNames: ProductTypeNames = {
  [ProductType.casino]: 'Casino',
  [ProductType.sport]: 'Sport',
  [ProductType.lotto]: 'Lotto',
  [ProductType.live_casino]: 'Live Casino',
  [ProductTypeStrings.CASINO]: 'Casino',
  [ProductTypeStrings.SPORT]: 'Sport',
  [ProductTypeStrings.LOTTO]: 'Lotto',
  [ProductTypeStrings.LIVE_CASINO]: 'Live Casino',
};
