import { isEqual } from 'lodash';

const DEBUG = (localStorage.getItem('debug')?.indexOf('customEqual') ?? -1) !== -1;

export const makeCustomEqual =
  (name: string, propNames?: string[], skip?: string[], debug?: boolean) => (pp: any, np: any) => {
    for (const k in pp) {
      if (skip && skip.includes(k)) {
        continue;
      }

      let pv = pp[k];
      let nv = np[k];

      if (typeof pv === 'function' && typeof nv === 'function') {
        pv = pv.toString();
        nv = nv.toString();
      }

      if (pv === nv) {
        continue;
      }

      debug ||= DEBUG;

      // if (!propNames || !propNames.includes(k)) {
      //   debug && console.log(`customEqual ${name} -> ${k} is not shallow equal, stop here`, typeof pp[k], pp[k], np[k]);
      //   return false;
      // }

      if (!isEqual(pv, nv)) {
        debug && console.log(`customEqual ${name} -> ${k} is not equal, stop here`, typeof pp[k], pp[k], np[k]);
        return false;
      } else {
        // np[k] = pp[k];
        // debug && console.log(`customEqual ${name} -> ${k} is equal, continue`, typeof pp[k], pp[k], np[k]);
      }
    }

    // debug && console.log(`customEqual ${name} -> all props are equal`);

    return true;
  };

const customMemoCache: any = {};

export const customMemo = (
  key: string,
  data: any,
  equalFn: (pp: any, np: any) => boolean,
  optimize?: (ov: any, nv: any) => any,
) => {
  if (typeof data === 'function') {
    data = data();
  }

  let prevData: any = null;

  if (key in customMemoCache) {
    prevData = customMemoCache[key];
    if (equalFn(prevData, data)) {
      return prevData;
    }
  }

  if (optimize && prevData) {
    data = optimize(prevData, data);
  }

  customMemoCache[key] = data;

  DEBUG && console.log(`customMemo ${key} does not match`, key, data);

  return data;
};

export type CustomMemo2Options = {
  key: string;
  cache: any;
  data: any;
  rootProps?: string[];
  equalFn: (pp: any, np: any) => boolean;
  optimize?: (ov: any, nv: any) => any;
};

const getDataForPath = (data: any, path: string) => {
  if (path.indexOf('.') === -1) {
    return data[path];
  }

  const keys = path.split('.');

  let d = data;

  for (const k of keys) {
    if (!d[k]) {
      return undefined;
    }

    d = d[k];
  }

  return d;
};

export const customMemo2 = (opts: CustomMemo2Options) => {
  let data = opts.data;

  if (typeof data === 'function') {
    data = data();
  }

  let prevData: any = null;

  if (opts.key in opts.cache) {
    prevData = opts.cache[opts.key];

    const rootProps = opts.rootProps || ['__self'];
    let pairs = [[prevData, data]];

    if (opts.rootProps) {
      pairs = opts.rootProps.map((k) => {
        const pv = getDataForPath(prevData, k);
        const nv = getDataForPath(data, k);

        return [pv, nv];
      });
    }

    let equal = true;

    for (let i = 0; i < pairs.length; i++) {
      const [pv, nv] = pairs[i];

      if (typeof pv === 'undefined' || typeof nv === 'undefined') {
        DEBUG && console.log(`customMemo2 ${opts.key} undefined on pair ${rootProps[i]}`);

        equal = false;
        break;
      }

      const re = opts.equalFn(pv, nv);
      if (!re) {
        DEBUG && console.log(`customMemo2 ${opts.key} does not match on pair ${rootProps[i]}`, pv, nv);

        equal = false;
        break;
      }

      if (equal) {
        DEBUG && console.log(`customMemo2 ${opts.key} match on all pairs ${rootProps}`, prevData, data);

        return prevData;
      }
    }
  }

  if (opts.optimize && prevData) {
    data = opts.optimize(prevData, data);
  }

  opts.cache[opts.key] = data;

  DEBUG && console.log(`customMemo2 ${opts.key} does not match`, prevData, data);

  return data;
};
