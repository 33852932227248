import React, { useMemo } from 'react';
import { useAppDispatch } from '@/store';
import { loginMethod, loginPassword, loginStart, logout } from '@/modules/casino/store/actions';
import { IS_SEAMLESS_TOKEN } from '@/modules/casino/utils/LoginMethods';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSeamlessLogin() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const location = window.location;
  const navigate = useNavigate();

  const SEAMLESS_LOGIN_PARAM = 'etok';

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get(SEAMLESS_LOGIN_PARAM);
    if (token) {
      // remove etok from url
      searchParams.delete(SEAMLESS_LOGIN_PARAM);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl);
      dispatch(logout());
      dispatch(loginMethod(IS_SEAMLESS_TOKEN));
      dispatch(loginPassword(token));
      dispatch(loginStart(undefined));
    }
  }, []);

  return useMemo(() => ({}), []);
}