import Axios from 'axios';

const ROMANIA_MINIMUM_LENGHT = 9;
const ROMANIA_MAX_LENGTH = 10;
const ROMANIA_MINIMUM_LENGTH_START_CHAR = '7';
const ROMANIA_MAX_LENGTH_START_CHAR = '07';
const ROMANIA_COUNTRY_CODE = '0040';
const ROMANIA_COUNTRY_CODE_SHORT = '004';
const EXIT_CODE = '00';
const GLOBAL_MIN_LENGHT = 12;
class PhoneNumberValidator {
  static validate(phone = undefined, currentCountry = undefined) {
    if (undefined === phone) {
      return false;
    }

    if (currentCountry !== 'RO') {
      // return true; // TODO: check for other countries ???
      const promises = [];

      promises.push(
        Axios.get(window.config.front_url + '/app/validatePhone', {
          params: {
            phoneNumber: phone,
            countryCode: currentCountry,
          },
        }),
      );

      return Promise.all(promises)
        .then((values) => {
          // console.log('DEBUG [PhoneNumberValidator.validate] response', values);

          const response = values[0] ?? null;
          if (!response?.data?.result) {
            return false;
          }

          return response.data.result.isValid ?? false;
        })
        .catch((error) => {
          console.error('ERROR [PhoneNumberValidator.validate]', error);
          return false;
        });
    }

    return new Promise((resolve) => {
      phone = this.stripNoise(phone);
      phone = this.checkForPlus(phone);

      if (!isNaN(phone)) {
        phone = this.checkRO(phone);
        if (phone.length > GLOBAL_MIN_LENGHT) {
          resolve(true);
          return;
        }
      }
      resolve(false);
    });
  }

  static stripNoise(phone) {
    phone = phone.replaceAll(' ', '');
    phone = phone.replaceAll('.', '');
    phone = phone.replaceAll('-', '');

    return phone;
  }

  static checkForPlus(phone) {
    let firstVal = phone.substr(0, 1);

    if (firstVal === '+') {
      phone = EXIT_CODE + phone.substr(1);
    }

    return phone;
  }
  /* 
  TODO: check for other countries ???
  TODO:
    -> php proxy: dispatch(app/validatePhone);
      postdata: { 
        phoneNumber: phone,
        countryCode: 'RO',
      }   
    -> core :
        api/countries/default
        api/countries   
  */

  static checkRO(phone) {
    let length = phone.length;

    // check for short version of Romanian phone
    if (length === ROMANIA_MINIMUM_LENGHT && phone.substr(0, 1) === ROMANIA_MINIMUM_LENGTH_START_CHAR) {
      phone = ROMANIA_COUNTRY_CODE + phone;
    }

    // check for long version of Romanian phone
    if (length === ROMANIA_MAX_LENGTH && phone.substr(0, 2) === ROMANIA_MAX_LENGTH_START_CHAR) {
      phone = ROMANIA_COUNTRY_CODE_SHORT + phone;
    }
    return phone;
  }
}

export default PhoneNumberValidator;
