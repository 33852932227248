import openSocket from 'socket.io-client';
import axios from 'axios';
import ClientAPI from './../../ClientAPI/ClientAPI';

import { walletReceived } from '../actions/wallet';
import { walletReceived as winnerFunWalletReceived } from '../../../winner-fun/store/actions/wallet';
import {
  happyHourUpdateStatus,
  happyHourPrizeWon,
  happyHourStatusCompleted,
  happyHourBetInfo,
} from '../actions/happy_hour';
import { betsSlipComputeWin } from './../../../bets/store/actions/betsSlip';
import { freeBetsLoaded, freeBetsFetchFilters } from '../actions/free_bets';
import { getEligibleBonuses } from '../actions/application';

import { tournamentCreated, tournamentEnded } from '../../../tournaments-missions/store/actions/tournaments_groups';
import { updatePlayerLeaderBoard } from '../../../tournaments-missions/store/actions/leader_board';
import { fetchConfig } from '../../../../store/slices/templatesConfig';

import { updateCurrentRank, fetchRankSystems, updateLoyaltyWallets } from '../../../../store/slices/rankSystems';
import { updateDataSourceJackpot, fetchSources } from '../../../../store/slices/dataSources';
import { receivedMomentumMessage } from '../../../momentum/store/sagas/websocket';
import { updateAirdropCampaign, fetchCampaign } from '@/store/slices/airdrop';
import { updateChallenge } from '../../../../store/slices/challenges';
import { fetchMaintenance } from '@/store/slices/maintenance';
import { setGame as setHotspinsJackpotGame } from '@/store/slices/hotspinsJackpotGame';

let socket = null;

const executeWithDelay = (fn) => {
  setTimeout(fn, Math.floor(Math.random() * 3000));
};

export const connectPlayerWebsocket = () => {
  if (socket !== null) {
    return;
  }

  const state = ClientAPI.getStore().getState();
  const { authentication } = state;

  //console.log("player ws to ", import.meta.env.CFW, window.config.playerWsUrl + window.config.playerWsPath);

  const query = {
    protocol: 'sio1',
  };

  if (window.config && window.config.clientId) {
    query['tenantId'] = window.config.clientId;
  }

  const options = {
    path: window.config.playerWsPath,
    forceNew: true,
    transports: ['websocket'],
    allowEIO3: true,
    query,
  };

  if (authentication.access_token) {
    options.query.auth = 'Bearer ' + authentication.access_token;
    options.auth = {
      token: 'Bearer ' + authentication.access_token,
    };
  }

  socket = openSocket(window.config.playerWsUrl, options);

  /*
    socket.on('connect', () => {
      console.log('player ws connected');
      //return;
  
      const state = ClientAPI.getStore().getState();
      const { authentication } = state;
  
      if (['user', 'token'].indexOf(authentication.auth_type) > -1) {
        socket.emit('login', {
          token: 'Bearer ' + authentication.access_token,
        });
      }
    });
  */

  socket.on('maintenance_update', (data) => {
    if (data && data.includes(window.config.clientId.toString())) {
      executeWithDelay(() => {
        ClientAPI.getStore().dispatch(fetchMaintenance());
      });
    }
  });

  socket.on('set_balance', (data) => {
    if (data && data.balance) {
      //console.log("SET_BALANCE", data);
      if (data.currency === 'WINCOIN') {
        ClientAPI.getStore().dispatch(winnerFunWalletReceived(data.balance));
      } else {
        ClientAPI.getStore().dispatch(walletReceived(data.balance, data.currency));
      }

      if (window.config && (window.config.betsEnabled === '1' || window.config.nsoftEnabled === '1')) {
        ClientAPI.getStore().dispatch(betsSlipComputeWin());
      }
    } else {
      console.error('invalid balance data received: ', data);
    }
  });

  socket.on('set_free_bets', (data) => {
    if (data && data.free_bets) {
      ClientAPI.getStore().dispatch(freeBetsLoaded(data.free_bets));
      ClientAPI.getStore().dispatch(freeBetsFetchFilters(data.free_bets));
    } else {
      console.error('invalid free_bets data received: ', data);
    }
  });

  // happy hour messages
  socket.on('happy_hour_status', (data) => {
    ClientAPI.getStore().dispatch(happyHourUpdateStatus(data));
  });

  socket.on('happy_hour_completed', (data) => {
    ClientAPI.getStore().dispatch(happyHourStatusCompleted(data));
  });

  socket.on('happy_hour_bet_info', (data) => {
    ClientAPI.getStore().dispatch(happyHourBetInfo(data));
  });

  socket.on('happy_hour_prize_won', (data) => {
    ClientAPI.getStore().dispatch(happyHourPrizeWon(data));
  });

  socket.on('tournament_end', (data) => {
    ClientAPI.getStore().dispatch(tournamentEnded({ tournamentId: data.id, missionGroupId: data.mission_group_id }));
  });

  socket.on('tournament_created', (data) => {
    ClientAPI.getStore().dispatch(
      tournamentCreated({ tournamentId: data.id, missionGroupId: data.mission_group_id, data: data }),
    );
  });

  socket.on('tournament_position', (data) => {
    ClientAPI.getStore().dispatch(updatePlayerLeaderBoard({ tournamentId: parseInt(data.id), data: data }));
  });

  socket.on('templatization_config_change', (data) => {
    if (data && data.length && Array.isArray(data)) {
      if (import.meta.env.DEV) {
        const tenantId = window.config.clientId;
        const siteId = window.config.siteId;
        if (data.indexOf(siteId) > -1) {
          // for dev execute immediately
          ClientAPI.getStore().dispatch(fetchConfig({ tenantId: tenantId, siteId: siteId }));
        }
      } else if (data.indexOf(window.config.siteId.toString()) > -1) {
        executeWithDelay(() => {
          ClientAPI.getStore().dispatch(
            fetchConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }),
          );
        });
      }
    }
  });

  socket.on('data_source_change', (data) => {
    if (data && data.length && Array.isArray(data)) {
      const state = ClientAPI.getStore().getState().dataSources.items;

      let fetchIds = [];

      // re-fetch only the data sources that are already in the state; the rest will get fetched on demand
      data.forEach((id) => {
        if (id === window.config.dataSourceAllJackpots) return;
        if (state[id] != null) {
          fetchIds.push(id);
        }
      });

      if (fetchIds.length) {
        executeWithDelay(() => {
          ClientAPI.getStore().dispatch(fetchSources({ ids: fetchIds }));
        });
      }
    }
  });

  socket.on('templatization_design_variant_change', (data) => {
    if (data && data.length && Array.isArray(data)) {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has('wsid') || !urlParams.has('wsp')) return;
      const wsid = urlParams.get('wsid');
      if (!data.includes(wsid)) return;

      if (import.meta.env.DEV) {
        const devConfig = import.meta.env.VITE_LOCAL_SITE_ID ?? '13';
        executeWithDelay(() => {
          ClientAPI.getStore().dispatch(fetchConfig({ tenantId: '2', siteId: devConfig }));
        });
      } else {
        executeWithDelay(() => {
          ClientAPI.getStore().dispatch(
            fetchConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }),
          );
        });
      }
    }
  });

  socket.on('loyalty_rank_update', (pkd) => {
    const state = ClientAPI.getStore().getState().rankSystems;
    //console.log(pkd);
    /*
     pkd = {
      "timestamp": 1693651867334,
      "rank_group_id": 1,
      "rank_title_id": 2,
      "points": 838
    } 
    */

    let found = false;
    let callApi = false;

    // get ranks from state
    for (const rg of state.rankGroups) {
      if (rg.id === pkd.rank_group_id) {
        if (rg.current_rank === null) {
          // no current rank, so we need to call the API
          callApi = true;
          break;
        }

        if (rg.current_rank.id === pkd.rank_title_id) {
          // rank found, update points if timestamp is newer
          if (rg.current_rank.timestamp < pkd.timestamp) {
            // rg.current_rank.points = pkd.points;
            // update points

            ClientAPI.getStore().dispatch(
              updateCurrentRank({
                rank_group_id: rg.id,
                data: {
                  points: pkd.points,
                },
              }),
            );
          }

          // no need to call the API
          found = true;
        } else {
          // rank has changed, so we need to call the API
          callApi = true;
        }

        break;
      }
    }

    if (!found || callApi) {
      // call API to get player ranks
      ClientAPI.getStore().dispatch(fetchRankSystems());
    }
  });

  socket.on('loyalty_wallet_update', (pkd) => {
    ClientAPI.getStore().dispatch(updateLoyaltyWallets({ data: pkd }));
  });

  socket.on('jackpot_update', (pkd) => {
    ClientAPI.getStore().dispatch(updateDataSourceJackpot({ data: pkd }));
  });

  socket.on('momentum_award_created', (data) => {
    receivedMomentumMessage('momentum_award_created', data);
  });

  socket.on('momentum_award_canceled', (data) => {
    receivedMomentumMessage('momentum_award_canceled', data);
  });

  socket.on('momentum_award_expired', (data) => {
    receivedMomentumMessage('momentum_award_expired', data);
  });

  socket.on('momentum_award_completed', (data) => {
    receivedMomentumMessage('momentum_award_completed', data);
  });

  socket.on('momentum_award_timeout', (data) => {
    receivedMomentumMessage('momentum_award_timeout', data);
  });

  socket.on('momentum_award_done', (data) => {
    receivedMomentumMessage('momentum_award_done', data);
  });

  socket.on('momentum_award_progress', (data) => {
    receivedMomentumMessage('momentum_award_progress', data);
  });

  socket.on('momentum_status', (data) => {
    receivedMomentumMessage('momentum_status', data);
  });

  socket.on('challenge_update', (data) => {
    ClientAPI.getStore().dispatch(updateChallenge(data));
  });

  socket.on('airdrop_campaign_update', (data) => {
    ClientAPI.getStore().dispatch(updateAirdropCampaign(data));
  });

  socket.on('airdrop_campaign_started', (data) => {
    const state = ClientAPI.getStore().getState();

    if (state.social?.socialHub?.isOpen) {
      executeWithDelay(() => {
        ClientAPI.getStore().dispatch(fetchCampaign());
      });
    }
  });

  socket.on('reload_player_bonuses', (data) => {
    ClientAPI.getStore().dispatch(getEligibleBonuses());
  });

  socket.on('jackpot_game_created', (data) => {
    console.log('jackpot_game_created', data);
    ClientAPI.getStore().dispatch(setHotspinsJackpotGame({ data }));
  });
};

export function* websocketPlayerLoginSaga(action) {
  //console.log('doing player login', action);

  if (socket === null) {
    connectPlayerWebsocket();
    return;
  }

  if (['user', 'token'].indexOf(action.authType) > -1) {
    yield socket.emit('login', {
      token: 'Bearer ' + action.accessToken,
    });
  }
}

export function websocketPlayerLogoutSaga() {
  //console.log("doing player logout");

  if (socket === null) {
    return;
  }

  socket.emit('logout');
}
