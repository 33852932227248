import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';

import { useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { testValues, getTr, sportNameToId } from '../../utils/functions';
import { SportType } from '../../utils/types';
import { prematchFetchSportByDay } from '../../../../../modules/bets/store/actions/prematch';
import { GetPrematchCalendarData } from '../../utils/hooks';
import { useMediaQuery } from '../../../../utils/useQueryMedia';
import { appToggleCompetitions } from '../../../../../modules/bets/store/actions/app';
import { navSportsUpdate } from '../../../../../modules/bets/store/actions/navSports';

import './index.scss';

type NavigatorDesktopProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const NavigatorDesktop = (componentProps: NavigatorDesktopProps) => {
  const dispatch = useAppDispatch();
  const uriMatches = useMatches();

  const data = GetPrematchCalendarData();
  const { i18n } = useTranslation();

  const [collapsed, setCollapsed] = React.useState<any>({
    '1': false,
  });
  const [showMore, setShowMore] = React.useState<{ [index: string]: boolean }>({});
  const [deferredSearch, setSearch] = React.useState<string>('');
  const search = React.useDeferredValue(deferredSearch);

  const getFavoriteListFromLocalStorage = () => {
    const favs = localStorage.getItem('favorite-pre-match-sport-competitions') ?? '[]';
    return JSON.parse(favs);
  };

  const [favoriteList, setFavoriteList] = React.useState<any[]>(getFavoriteListFromLocalStorage());

  const isMobile = useMediaQuery('(max-width:900px)');

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.sport) {
        const sport = e.currentTarget.dataset.sport;
        dispatch(navSportsUpdate('prematch', sport));
        dispatch(prematchFetchSportByDay({ idSport: sport, day: data.filterByDate, noDays: 1 }));
      }
    },
    [data.filterByDate],
  );

  const onToggleCollapse = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.currentTarget.dataset.sport) {
      const sport = e.currentTarget.dataset.sport;
      const category = e.currentTarget.dataset.category;
      const tournament = e.currentTarget.dataset.tournament;

      const parts = [];
      if (sport) parts.push(sport);
      if (category) parts.push(category);
      if (tournament) parts.push(tournament);
      const key = parts.join('-');

      setCollapsed((prev: any) => {
        return {
          ...prev,
          [key]: prev[key] != null ? !prev[key] : false,
        };
      });
    }
  }, []);

  const onToggleIsFavorite = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.currentTarget.dataset.sport && e.currentTarget.dataset.category && e.currentTarget.dataset.tournament) {
        const sport = e.currentTarget.dataset.sport;
        const category = e.currentTarget.dataset.category;
        const tournament = e.currentTarget.dataset.tournament;

        const { sportsMeta, categoriesMeta, tournamentsMeta } = data;

        const parts = [];
        if (sport) parts.push(sport);
        if (category) parts.push(category);
        if (tournament) parts.push(tournament);

        const key = parts.join('-');

        const favorites = getFavoriteListFromLocalStorage();

        const exists = favorites.findIndex((fav: any) => {
          if (fav.idSport === sport && fav.idCategory === category && fav.idTournament === tournament) {
            return true;
          }
          return false;
        });

        if (exists !== -1) {
          favorites.splice(exists, 1);
        } else {
          favorites.push({
            key: key,
            idSport: sport,
            idCategory: category,
            idTournament: tournament,
            name: tournamentsMeta?.[tournament]?.tournamentName ?? '',
            numberOfEvents: tournamentsMeta[tournament] ? tournamentsMeta[tournament].numberOfEvents : 0,
            categoryName: categoriesMeta[category] ? categoriesMeta[category].categoryName : '',
            categoryDLId: categoriesMeta[category] ? categoriesMeta[category].categoryDLId : '',
          });
        }

        localStorage.setItem('favorite-pre-match-sport-competitions', JSON.stringify(favorites));

        setFavoriteList(favorites);
      }
    },
    [data],
  );

  const onToggleShowMore = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.currentTarget.dataset.sport) {
      const sport = e.currentTarget.dataset.sport;

      setShowMore((prev: any) => {
        return {
          ...prev,
          [sport]: true,
        };
      });
    }
  }, []);

  const onChangeSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  let idSport = '1';
  if (uriMatches?.[0]?.params?.idSport) {
    idSport = uriMatches[0].params.idSport;
  }
  let idCategory = '';
  if (uriMatches?.[0]?.params?.idCategory) {
    idCategory = uriMatches[0].params.idCategory;
  }
  let idTournament = '';
  if (uriMatches?.[0]?.params?.idTournament) {
    idTournament = uriMatches[0].params.idTournament;
  }

  if (idSport) idSport = sportNameToId(idSport);

  React.useEffect(() => {
    let idSport = '1';
    if (uriMatches?.[0]?.params?.idSport) {
      idSport = uriMatches[0].params.idSport;
    }
    let idCategory = '';
    if (uriMatches?.[0]?.params?.idCategory) {
      idCategory = uriMatches[0].params.idCategory;
    }
    let idTournament = '';
    if (uriMatches?.[0]?.params?.idTournament) {
      idTournament = uriMatches[0].params.idTournament;
    }

    let selectedKeyGroup = '';
    let selectedKeyTournament = '';
    const selectedKeyParts: string[] = [];
    if (idSport) selectedKeyParts.push(idSport);
    if (idCategory) selectedKeyParts.push(idCategory);
    if (selectedKeyParts.length === 2) selectedKeyGroup = selectedKeyParts.join('-');
    if (idTournament) selectedKeyParts.push(idTournament);
    if (selectedKeyParts.length === 3) selectedKeyTournament = selectedKeyParts.join('-');

    if (selectedKeyGroup || selectedKeyTournament) {
      setCollapsed((prev: any) => {
        const newData = {
          ...prev,
        };
        if (selectedKeyGroup && selectedKeyTournament) newData[selectedKeyGroup] = false;
        if (selectedKeyTournament) newData[selectedKeyTournament] = false;

        return newData;
      });
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const { sportsMeta, categoriesMeta, tournamentsMeta } = data;

    const final: any[] = [];

    if (!search || search.length === 0) {
      const allSports: SportType[] = JSON.parse(JSON.stringify(Object.values(sportsMeta)));

      // only active sports
      const sports = allSports.filter((sport: SportType) => {
        return sport?.sportActive === true;
      });

      // sort sport by backend sport position
      sports.sort((a, b) => {
        return testValues(a?.sportPosition, b?.sportPosition);
      });

      const cats: { [isSport: string]: any[] } = {};
      Object.keys(categoriesMeta).forEach((idCategory) => {
        const category = categoriesMeta[idCategory];
        if (!cats[category.idSport]) cats[category.idSport] = [];
        cats[category.idSport].push(category);
      });

      const tours: { [idCategory: string]: any[] } = {};
      Object.keys(tournamentsMeta).forEach((idTournament) => {
        const tournament = tournamentsMeta[idTournament];
        if (!tours[tournament.idCategory]) tours[tournament.idCategory] = [];
        tours[tournament.idCategory].push(tournament);
      });

      sports.forEach((sport: SportType) => {
        const cs: any[] = [];

        // @ts-ignore
        //sport.showMore = showMore[sport.idSport] != null ? showMore[sport.idSport] : false;
        sport.showMore = true;
        // @ts-ignore
        // sport.collapsed = collapsed[sport.idSport] != null ? collapsed[sport.idSport] : true;
        sport.collapsed = false;
        // @ts-ignore
        if (!sport.collapsed) {
          if (cats[sport.idSport]) {
            cats[sport.idSport].forEach((c: any) => {
              const category = { ...c };

              const key = `${sport.idSport}-${category.idCategory}`;
              category.collapsed = collapsed[key] != null ? collapsed[key] : true;

              const ts: any[] = [];

              if (!category.collapsed) {
                if (tours[category.idCategory]) {
                  tours[category.idCategory].forEach((t: any) => {
                    const tournament = { ...t };

                    const key = `${sport.idSport}-${category.idCategory}-${tournament.idTournament}`;
                    tournament.collapsed = collapsed[key] != null ? collapsed[key] : true;
                    ts.push(tournament);
                  });
                }

                // don't sort tournaments unless we actually see them
                ts.sort((a, b) => {
                  return testValues(a?.tournamentPosition, b?.tournamentPosition);
                });
              }

              category.tournaments = ts;
              cs.push(category);
            });
          }

          // don't sort categories unless we actually see them
          cs.sort((a, b) => {
            return testValues(a?.categoryPosition, b?.categoryPosition);
          });
        }

        // @ts-ignore
        sport.categories = cs;
        final.push(sport);
      });
    }

    const filteredLeagues: any[] = [];
    if (search && search.length >= 3) {
      Object.values(tournamentsMeta).forEach((tournament: any) => {
        if (!tournament) return;
        if (!tournament?.tournamentActive) return;

        const text = getTr(tournament.tournamentName, i18n.language);

        if (text?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) {
          filteredLeagues.push({
            idSport: tournament.idSport,
            idCategory: tournament.idCategory,
            idTournament: tournament.idTournament,
            tournamentName: tournament.tournamentName,
          });
        }
      });
    }
    const filteredMatches: any[] = [];
    if (search && search.length >= 3) {
      Object.values(data.matches).forEach((match: any) => {
        if (!match) return;
        if (!match?.active && match?.currentStatus?.IsLiveFinished) return;

        let found = false;

        const team1Name = getTr(match.team1Name, i18n.language);
        if (team1Name?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) found = true;

        if (!found) {
          const team2Name = getTr(match.team2Name, i18n.language);
          if (team2Name?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) found = true;
        }

        if (found) {
          filteredMatches.push({
            idSport: match.idSport,
            idCategory: match.idCategory,
            idTournament: match.idTournament,
            idMatch: match.idMatch,
            team1Name: match.team1Name,
            team2Name: match.team2Name,
          });
        }
      });
    }

    const isFavorite: any = {};
    favoriteList.forEach((fav: any) => {
      if (fav.idSport && fav.idCategory && fav.idTournament) {
        isFavorite[`${fav.idSport}-${fav.idCategory}-${fav.idTournament}`] = true;
      }
    });

    return {
      sports: final.filter((sport: SportType) => {
        return sport.idSport === idSport;
      }),
      search: search ?? '',
      onChangeSearch,
      filteredLeagues,
      filteredMatches,
      onClick: onClick,
      onToggleCollapse: onToggleCollapse,
      onToggleShowMore: onToggleShowMore,
      isMobile,
      onToggleCompetitions: () => {
        dispatch(appToggleCompetitions());
      },

      isFavorite,
      onToggleIsFavorite,
      favorites: favoriteList,
    };
  }, [
    data,
    collapsed,
    showMore,
    onClick,
    onToggleCollapse,
    onToggleShowMore,
    search,
    i18n.language,
    isMobile,

    onToggleIsFavorite,
    favoriteList,

    idSport,
    idCategory,
    idTournament,
  ]);

  //console.log('NavigatorDesktop[contextValue] PRE-MATCH', contextValue, collapsed);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default NavigatorDesktop;
