import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { loadTournamentsGroups } from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { fetchSources } from '../../../store/slices/dataSources';
import { getMomentumBonuses } from '../../../modules/momentum/store/actions/momentum';
import { fetchChallenges } from '@/store/slices/challenges';

import './index.scss';

export interface Group {
  id: number;
  meta: any;
  priority: number;
  type: number;
  url: string;
  name: string;
  currentTournament: any;
  tournaments: any[];
}

const emptyArr: any[] = [];
const emptyObj: any = {};

type RewardsCardCarouselProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    attrOrientation?: string;
  };
};

type ActiveEvents = {
  happyhours: any[];
  tournaments: any[];
  challenges: any | null;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RewardsCardCarousel = (componentProps: RewardsCardCarouselProps) => {
  const dispatch = useAppDispatch();

  const refresh = React.useRef(0);
  const fetchedSources = React.useRef(false);

  // tournaments
  const allTournamentGroups = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArr,
  );
  const groupsLoaded = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  );
  const [allLoadedTournaments, setAllLoadedTournaments] = React.useState<any>([]);
  const [allActiveTournaments, setAllActiveTournaments] = React.useState<any>([]);

  const ranking: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : emptyObj,
  );

  // happy hour
  const happyHourRunning = useAppSelector((state) => state.happyHour.running);
  const happyHourStatus = useAppSelector((state) => state.happyHour.status);
  // jackpots
  const dsItems = useAppSelector((state) => state.dataSources.items);
  const dsLoaded = useAppSelector((state) => state.dataSources.loaded);
  // challenge
  const challenges = useAppSelector((state) => state.challenges.challenges);

  const prize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum?.prize?.loaded);
  const authentication = useAppSelector((state) => state.authentication);

  const activeEvents: ActiveEvents = {
    happyhours: [],
    tournaments: [],
    challenges: challenges && Array.isArray(challenges) && challenges.length > 0 ? challenges.length : null,
  };

  const getTournamentGroups = () => {
    if (!allTournamentGroups) return;

    const resGr: any = [];
    const activeEventTournaments: any = [];

    if (allTournamentGroups && Array.isArray(allTournamentGroups)) {
      allTournamentGroups.forEach((group: any) => {
        if (group && group.tournaments && Array.isArray(group.tournaments)) {
          const tournaments = [...group.tournaments];
          tournaments.sort((a, b) => {
            if (a && a.start_date && b && b.end_date) {
              return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
            }
            return 0;
          });

          const now = moment().valueOf();
          const activeTournaments = tournaments.filter((t) => {
            if (t && parseInt(t.end_date, 10) > now) return true;
            return false;
          });

          // console.log('debug reward-card activeTournaments', { activeTournaments, tournaments });

          if (activeTournaments.length > 0) {
            resGr.push({ type: 'tournament', subtype: group.type, id: group.id });

            // for events, only those who have started
            const eventsTournaments = activeTournaments.filter((t) => {
              if (t && parseInt(t.start_date, 10) < now) return true;
              return false;
            });

            activeEventTournaments.push({
              type: 'tournament',
              subtype: group.type,
              id: group.id,
              data: eventsTournaments.map((t) => {
                return {
                  ...t,
                  me: t && typeof ranking[t.id] !== 'undefined' && ranking[t.id].me ? ranking[t.id].me : null,
                };
              }),
            });
          }
        }
      });
    }

    setAllActiveTournaments(activeEventTournaments);
    setAllLoadedTournaments(resGr);
  };

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  React.useEffect((): any => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    } else {
      getTournamentGroups();
    }
  }, [groupsLoaded]);

  React.useEffect(() => {
    if (dsLoaded && dsItems) {
      if (!dsItems[window.config.dataSourceAllJackpots] && !fetchedSources.current) {
        dispatch(fetchSources({ ids: [window.config.dataSourceAllJackpots] }));
        fetchedSources.current = true;
      }
    }
  }, [dsLoaded, dsItems]);

  React.useEffect(() => {
    if (window?.config?.challengesEnabled === '1') {
      dispatch(fetchChallenges());
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const list = [];
    const activeHHList = [];
    const ceTrList: any[] = [];

    if (prize?.active) {
      if (prize?.bonus_expires_at) {
        const now = new Date().valueOf();
        const prizeExpiresAt = new Date(prize?.bonus_expires_at).valueOf();
        const expired = now > prizeExpiresAt;

        if (!expired) {
          list.push({ type: 'momentum', subtype: '' });
        }
      }
    }

    if (happyHourRunning['online-slots']) {
      list.push({ type: 'happy-hour', subtype: 'online-slots' });

      activeHHList.push({
        type: 'happy-hour',
        subtype: 'online-slots',
        data: happyHourStatus['online-slots'] ?? null,
      });
    }

    if (happyHourRunning['bets-prematch']) {
      list.push({ type: 'happy-hour', subtype: 'bets-prematch' });

      activeHHList.push({
        type: 'happy-hour',
        subtype: 'bets-prematch',
        data: happyHourStatus['bets-prematch'] ?? null,
      });
    }

    if (happyHourRunning['bets-live']) {
      list.push({ type: 'happy-hour', subtype: 'bets-live' });

      activeHHList.push({
        type: 'happy-hour',
        subtype: 'bets-live',
        data: happyHourStatus['bets-live'] ?? null,
      });
    }

    if (allLoadedTournaments.length > 0) {
      list.push(...allLoadedTournaments);
    }

    if (allActiveTournaments.length > 0) {
      allActiveTournaments.forEach((t: any) => {
        if (t.data && t.data.length > 0) {
          t.data.forEach((tr: any) => {
            ceTrList.push({
              type: 'tournament',
              subtype: t.subtype,
              id: t.id,
              data: tr,
            });
          });
        }
      });

      activeEvents.tournaments = allActiveTournaments;
    }

    if (
      dsItems &&
      dsItems[window.config.dataSourceAllJackpots] &&
      dsItems[window.config.dataSourceAllJackpots].data &&
      dsItems[window.config.dataSourceAllJackpots].data.length > 0
    ) {
      dsItems[window.config.dataSourceAllJackpots].data.forEach((jackpot: any) => {
        list.push({ type: 'jackpot', subtype: jackpot.provider, id: jackpot.group });
      });
    }

    refresh.current += 1;

    const ceCarrouselList = [];

    if (window.config?.challengesEnabled === '1' && challenges && challenges.length > 0)
      ceCarrouselList.push({
        type: 'challenge',
        subtype: 'challenge',
        data: activeEvents.challenges,
      });

    if (activeHHList.length > 0) ceCarrouselList.push(...activeHHList);
    if (window.config?.tournamentsMissionsEnabled === '1' && ceTrList.length > 0) ceCarrouselList.push(...ceTrList);

    return {
      list,
      activeEvents: {
        happyhours: activeHHList,
        tournaments: activeEvents.tournaments,
        challenges: activeEvents.challenges,
      },
      ceCarrouselList: ceCarrouselList,
      orientation:
        componentProps?.properties?.attrOrientation &&
        typeof componentProps.properties.attrOrientation === 'string' &&
        componentProps.properties.attrOrientation !== ''
          ? componentProps.properties.attrOrientation
          : 'horizontal',
      refresh: refresh.current,
    };
  }, [dsItems, dsLoaded, allLoadedTournaments, happyHourRunning, prize, challenges, happyHourStatus, ranking]);

  // console.log('RewardsCardCarousel[contextValue]', {
  //   contextValue,
  //   allLoadedTournaments,
  //   happyHourStatus,
  //   allTournamentGroups,
  //   activeEvents,
  //   allActiveTournaments,
  //   componentProps,
  // });

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RewardsCardCarousel;
