export const momentumConstants = {
  GET_MOMENTUM_BONUSES: 'MOMENTUM_GET_MOMENTUM_BONUSES',
  SET_MOMENTUM_BONUSES: 'MOMENTUM_SET_MOMENTUM_BONUSES',
  SET_MOMENTUM_SOURCE: 'MOMENTUM_SET_MOMENTUM_SOURCE',
  FORFEIT_MOMENTUM_BONUS: 'MOMENTUM_FORFEIT_MOMENTUM_BONUS',
  ACCEPT_MOMENTUM_BONUS: 'MOMENTUM_ACCEPT_MOMENTUM_BONUS',
  UPDATE_MOMENTUM_BONUS: 'MOMENTUM_UPDATE_MOMENTUM_BONUS',
  UPDATE_MOMENTUM_STATUS: 'MOMENTUM_UPDATE_MOMENTUM_STATUS',
  CLEAR_STATE: 'MOMENTUM_CLEAR_STATE',
  SET_MOMENTUM_PRIZE_LOADING: 'MOMENTUM_SET_MOMENTUM_PRIZE_LOADING',
};
