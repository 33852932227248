import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { useAppDispatch, useAppSelector } from '@/store';
import { fetchWheelsDataSource } from '@/store/slices/wheelsDataSource';
import Axios from 'axios';

//import './index.scss';

type PromoBubbleProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PromoBubble = (componentProps: PromoBubbleProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const dispatch = useAppDispatch();
  const apiUrl = window.config.betsApiUrl + '/wheel/player';

  const authentication = useAppSelector((state) => state.authentication);
  const [showPromoBubble, setShowPromoBubble] = React.useState<boolean>(false);
  const [showChest, setShowChest] = React.useState<any>(false);
  const [loggedIn, setLoggedIn] = React.useState<any>(false);

  React.useEffect(() => {
    if (loggedIn) {
      dispatch(fetchWheelsDataSource({ id: 'player_wheel_setups' }));
      Axios.get(apiUrl + '/status?scope=MYSTERY_CHEST', {
        headers: {
          Authorization: 'Bearer ' + authentication.access_token,
        },
      })
        .then((response) => {
          if (response?.data?.status === 'available' || 
            (response?.data?.status === 'not_available' && response?.data?.free_spin?.next_spin_after === null)) {
            setShowChest(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowChest(false);
    }
  }, [loggedIn]);

  React.useEffect(() => {
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [authentication]);

  const contextValue = React.useMemo(() => {
    return {
      showPromoBubble,
      loggedIn,
      showChest: showChest,
      togglePromoBubble: () => setShowPromoBubble(!showPromoBubble),
    };
  }, [showPromoBubble, showChest, loggedIn]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PromoBubble;
