import axios from 'axios';
import store from '@/store';

export type LaunchUrlResponse = {
  data: {
    success: boolean;
    language: string;
    currency: string;
    game_data: {
      link: string;
    };
    requested: {
      game_id: string;
      language: string;
      currency: string;
    };
  };
};

export const launchGameUrl = async (gameId: string) => {
  const state = store.getState();

  const access_token = state.authentication.access_token;

  if (!access_token)
    return new Promise((resolve, reject) => {
      reject('[ERROR] URL link not found!');
    });

  let platformType = window.config.platformType;

  if (platformType == '3' || platformType == '4') {
    platformType = '2';
  }

  return axios
    .request<any, LaunchUrlResponse>({
      url: `${window.config.casinoLaunchApiUrl}/casino-game/launch`,
      method: 'post',
      data: {
        game_id: gameId,
        language: state.application.language ? state.application.language : window.config.defaultLanguage,
        currency: state.wallet.currency,
        platformType: platformType,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const data = console.log(response);
      if (!response.data) {
        throw new Error('[ERROR] URL response is empty!');
      }

      if (!response.data?.success) {
        throw new Error('[ERROR] Api call failed!');
      }

      if (!response.data?.game_data?.link) {
        throw new Error('[ERROR] URL link not found!');
      }

      return response;
    });

  /*
  const axios = ClientAPI.getInstance();

  return axios
    .request<any, LaunchUrlResponse>({
      url: games.LAUNCH_URL,
      method: 'post',
      data: {
        platformType: window.config.platformType,
        gameId: gameId,
        currencyCode: ClientAPI.getStore().getState().wallet.currency,
      },
    })
    .then((response) => {
      if (!response) {
        throw new Error('[ERROR] URL response is empty!');
      }

      if (response.result === undefined) {
        throw new Error('[ERROR] Server response has no result!');
      }

      const responseData = response.result;
      if (typeof responseData['link'] === 'undefined') {
        throw new Error('[ERROR] URL link not found!');
      }

      return responseData.link;
    });

    */
};
