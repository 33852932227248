import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import { isEqual } from 'lodash-es';

import { useAppSelector, useAppDispatch, store } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

type BreadcrumbsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const fetchBreadCrumbs = (uriMatches: any) => {
  const [match, setMatch] = React.useState<any>({
    hasMatch: false,
    pathname: '',
    params: {},
    paths: [],
    title: '…',
    allowLoginButtonInRegisterPage: true,
  });

  const fullLiveStateLoaded = useAppSelector((state) => state.bets.live.fullStateLoaded);
  const fullPrematchtateLoaded = useAppSelector((state) => state.bets.prematch.fullStateLoaded);
  const dataSources = useAppSelector((state) => state.dataSources.items);
  const register = useAppSelector((state) => state.register);

  React.useEffect(() => {
    if (uriMatches && uriMatches.length) {
      // console.log('Breadcrumbs[state]', { fullLiveStateLoaded, fullPrematchtateLoaded });

      const state = store.getState();

      const {
        sports: prematchSports,
        categories: prematchCategories,
        tournaments: prematchTournaments,
      }: any = state.bets.prematch;
      const { sports: liveSports, categories: liveCategories, tournaments: liveTournaments }: any = state.bets.live;

      const uriMatch = uriMatches[uriMatches.length - 1];
      const tmp = {
        hasMatch: true,
        pathname: uriMatch.pathname,
        params: uriMatch.params,
        paths: [
          {
            uri: '/',
            label: 'Home',
          },
        ],
        title: '…',
        allowLoginButtonInRegisterPage: true,
      };

      if (
        tmp.pathname?.indexOf('/bets/live-') === 0 ||
        tmp.pathname?.indexOf('/bets/pre-') === 0 ||
        tmp.pathname?.indexOf('/bets/match/') === 0 ||
        tmp.pathname?.indexOf('/winnerfun/live-') === 0 ||
        tmp.pathname?.indexOf('/winnerfun/pre-') === 0 ||
        tmp.pathname?.indexOf('/winnerfun/match/') === 0
      ) {
        const res = [];
        let mType = 'prematch';
        let isWinnerFun = false;

        if (tmp.pathname.indexOf('live-match') > -1) mType = 'live';
        if (tmp.pathname.indexOf('winnerfun') > -1) isWinnerFun = true;

        const idSport = uriMatch?.params?.idSport ?? 1;

        if (idSport) {
          if (mType === 'prematch') {
            if (prematchSports?.[idSport]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/pre-match/${idSport}`,
                label: prematchSports[idSport]?.sportName,
              });
              tmp.title = prematchSports[idSport]?.sportName;
            }
          } else {
            if (liveSports?.[idSport]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/live-match/${idSport}`,
                label: liveSports[idSport]?.sportName,
              });
              tmp.title = liveSports[idSport]?.sportName;
            }
          }
        }
        if (uriMatch?.params?.idCategory) {
          if (mType === 'prematch') {
            if (prematchCategories?.[uriMatch.params.idCategory]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/pre-match/${idSport}/${uriMatch.params.idCategory}`,
                label: prematchCategories[uriMatch.params.idCategory]?.categoryName,
              });
              tmp.title = prematchCategories[uriMatch.params.idCategory]?.categoryName;
            }
          } else {
            if (liveCategories?.[uriMatch.params.idCategory]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/live-match/${idSport}/${uriMatch.params.idCategory}`,
                label: liveCategories[uriMatch.params.idCategory]?.categoryName,
              });
              tmp.title = liveCategories[uriMatch.params.idCategory]?.categoryName;
            }
          }
        }
        if (uriMatch?.params?.idTournament) {
          if (mType === 'prematch') {
            if (prematchTournaments?.[uriMatch.params.idTournament]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/pre-match/${idSport}/${uriMatch.params.idCategory}/${
                  uriMatch.params.idTournament
                }`,
                label: prematchTournaments[uriMatch.params.idTournament]?.tournamentName,
              });
              tmp.title = prematchTournaments[uriMatch.params.idTournament]?.tournamentName;
            }
          } else {
            if (liveTournaments?.[uriMatch.params.idTournament]) {
              res.push({
                uri: `/${isWinnerFun ? 'winnerfun' : 'bets'}/live-match/${idSport}/${uriMatch.params.idCategory}/${
                  uriMatch.params.idTournament
                }`,
                label: liveTournaments[uriMatch.params.idTournament]?.tournamentName,
              });
              tmp.title = liveTournaments[uriMatch.params.idTournament]?.tournamentName;
            }
          }
        }

        tmp.paths = [...tmp.paths, ...res];
        if (tmp.paths.length > 1) tmp.paths.pop();
      } else if (tmp.pathname?.indexOf('/jackpots') === 0) {
        tmp.title = 'Jackpot';

        if (tmp.pathname === '/jackpots/' || tmp.pathname === '/jackpots') {
          tmp.title = 'Jackpots';
        } else {
          tmp.paths.push({
            uri: '/jackpots',
            label: 'Jackpots',
          });

          const parts = tmp.pathname.split('/');
          const jackpotId = parts[parts.length - 1];

          if (jackpotId && dataSources?.[window.config.dataSourceAllJackpots]) {
            let jackpot = null;
            for (let i = 0; i < dataSources[window.config.dataSourceAllJackpots]?.data.length; i++) {
              if (dataSources[window.config.dataSourceAllJackpots]?.data?.[i].group === jackpotId) {
                jackpot = dataSources[window.config.dataSourceAllJackpots].data[i];
                break;
              }
            }

            if (jackpot && jackpot?.art_bundle?.data?.text_group_display_name) {
              tmp.title = jackpot?.art_bundle?.data?.text_group_display_name;
            }
          }
        }
      } else if (tmp.pathname?.indexOf('/promotions') === 0) {
        tmp.title = 'Promotion';

        if (tmp.pathname === '/promotions/' || tmp.pathname === '/promotions') {
          tmp.title = 'Promotions';
        } else {
          tmp.paths.push({
            uri: '/promotions',
            label: 'Promotions',
          });

          const parts = tmp.pathname.split('/');
          const promotionId = parts[parts.length - 1];

          if (promotionId && dataSources?.all_promotions_api) {
            let promotion = null;
            for (let i = 0; i < dataSources.all_promotions_api.data.length; i++) {
              if (dataSources.all_promotions_api?.data?.[i].id === promotionId) {
                promotion = dataSources.all_promotions_api.data[i];
                break;
              }
            }

            if (promotion && promotion?.message_primary_short) {
              const div = document.createElement('div');
              div.innerHTML = promotion?.message_primary_short;
              tmp.title = div.textContent || div.innerText;
            }
          }
        }
      } else if (tmp.pathname?.indexOf('/games/') === 0) {
        tmp.title = 'Games';

        switch (tmp.pathname) {
          case '/games/all-games':
            tmp.title = 'All Games';
            break;
          case '/games/all-amusnet-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Amusnet';
            break;
          case '/games/all-egt-digital-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'EGT Digital';
            break;
          case '/games/all-relax-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Relax Gaming';
            break;
          case '/games/all-isoftbet-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'iSoftBet';
            break;
          case '/games/all-novomatic-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Novomatic';
            break;
          case '/games/all-skywind-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Skywind';
            break;
          case '/games/all-ctg-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'CT Interactive';
            break;
          case '/games/all-nevada-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Nevada';
            break;
          case '/games/all-omniplay-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'OmniPlay';
            break;
          case '/games/all-spribe-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Spribe';
            break;
          case '/games/all-hacksaw-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Hacksaw';
            break;
          case '/games/all-wazdan-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Wazdan';
            break;
          case '/games/all-playson-games':
            tmp.paths.push({
              uri: '/games/all-games',
              label: 'All Games',
            });
            tmp.title = 'Playson';
            break;

          default:
          /* */
        }
      } else if (tmp.pathname?.indexOf('/casino-missions/') === 0) {
        tmp.paths.push({
          uri: '/tournaments',
          label: 'Tournaments',
        });

        const groupId = uriMatch?.params?.groupId;

        const groups = state.tournamentsMissions?.groups?.list;
        const group = groups?.find((g: any) => g.id.toString() === groupId);

        if (group) {
          tmp.title = group.name;
        } else {
          tmp.title = ' ';
        }
      } else if (tmp.pathname?.indexOf('/tournaments') === 0) {
        tmp.title = 'Tournaments';
      } else if (tmp.pathname?.indexOf('/challenges') === 0) {
        // details page

        if (tmp.pathname?.indexOf('/challenges/') === 0) {
          tmp.paths.push({
            uri: '/challenges',
            label: 'Missions',
          });

          const challengeId = uriMatch?.params?.challengeId;

          let challenge = null;
          if (Array.isArray(state.challenges.challenges) && state.challenges.challenges.length > 0)
            challenge = state.challenges.challenges.find((c: any) => c.id.toString() === challengeId.toString());

          // console.log('DEBUG BREADCRUMBS challenge: ', { challenge, state });

          if (challenge) {
            tmp.title = challenge.name;
          } else {
            tmp.title = ' ';
          }
        } else {
          // list page
          tmp.title = 'Missions';
        }

        // console.log(' DEBUG BREADCRUMBS  tmp', tmp);
      } else if (tmp.pathname?.indexOf('/lotto/details') === 0) {
        tmp.title = 'Lotto Event';

        tmp.paths.push({
          uri: '/lotto',
          label: 'Lotto',
        });

        const systemId = uriMatch?.params?.systemId;

        if (systemId) {
          const systems = state.lotto.lottoEvents?.systems;
          const system = systems?.find((e: any) => e.system_id === systemId);

          if (system) {
            tmp.title = system.system_name;
          }
        }
      } else if (tmp.pathname?.indexOf('/profile') === 0) {
        tmp.paths.push({
          uri: '/profile/home',
          label: 'My Account',
        });
      } else if (tmp.pathname?.indexOf('/customer-support') === 0) {
        tmp.paths.push({
          uri: '/customer-support',
          label: 'Help Center',
        });
      } else if (tmp.pathname?.indexOf('/slot-game/') === 0) {
        tmp.title = 'Login';
      } else if (tmp.pathname?.indexOf('/register') === 0) {
        // if register process is done, we don't want to have active login button
        if (register.signedUp || register.signUpLoading) tmp.allowLoginButtonInRegisterPage = false;
      }

      setMatch((v: any) => {
        if (!isEqual(v, tmp)) return tmp;
        return v;
      });
    }
  }, [uriMatches, fullLiveStateLoaded, fullPrematchtateLoaded, dataSources, register]);

  return match;
};

const Breadcrumbs = (componentProps: BreadcrumbsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const uriMatches = useMatches();

  const match = fetchBreadCrumbs(uriMatches);

  // console.log('Breadcrumbs[contextValue]', match);
  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={match}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Breadcrumbs;
