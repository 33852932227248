import './dev.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap Bundle JS
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.scss';

import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n'; // the initialized i18next instance

import './utils/logger'; // needed for the logger to be initialez
import store from './store';
import { fetchConfig, loadConfig, setWebsiteStyles } from './store/slices/templatesConfig';
import { fetchSources } from './store/slices/dataSources';

import App from './app';

import PragmaticLive from './store/sagas/pragmaticLiveWS';

// add the root, page-layout and page style rules containers (as style tags)
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
);

if (window.config.preloadDS) {
  try {
    const values: any = Object.values(window.config.preloadDS);
    if (Array.isArray(values) && values.length > 0) {
      store.dispatch(fetchSources({ ids: values }));
    }
  } catch (e) {
    /* do nothing */
  }
}

// get the pages configuration; TODO: fix the hardcoded tenantID and siteId
if (import.meta.env.DEV) {
  const tenantId = window.config.clientId;
  const siteId = window.config.siteId;
  document.documentElement.classList.toggle(`-s-${siteId}`);
  store.dispatch(fetchConfig({ tenantId: tenantId, siteId: siteId }));
} else {
  document.documentElement.classList.toggle(`-s-${window.config.siteId}`);
  if (window.config.preloadTmplConfig === '1') {
    if (typeof window.tmplConfigPromise === 'object' && typeof window.tmplConfigPromise.then === 'function') {
      window.tmplConfigPromise
        .then((config: any) => {
          setWebsiteStyles({
            setCSSVariables: config['set_css_variables'],
            setCSSLightVariables: config['set_css_light_variables'],
            setCSSDarkVariables: config['set_css_dark_variables'],
            fonts: config['fonts'],
            breakPoints: config['break_points'],
            commonCSS: config['common_css'],
            themeMode: config['theme_mode'],
          });

          setTimeout(() => {
            console.time('fetchConfig.query');
            store.dispatch(loadConfig({ data: config }));
          }, 10);
        })
        .catch((err: any) => {
          store.dispatch(fetchConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }));
        });
    }
  } else {
    store.dispatch(fetchConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }));
  }
}
//store.dispatch(fetchAllGamesConfig({ tenantId: window.config.clientId, siteId: window.config.siteId }));

// Disabled international SMS feature, set RO to be the default timezone.
// store.dispatch(fetchAllCountriesConfig({ timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
//store.dispatch(fetchAllCountriesConfig({ timezone: 'Europe/Bucharest' }));
//store.dispatch(appInitialize());
//store.dispatch(startAppAuth());

// for inspecting state in console dev
// @ts-ignore
window._store = store;

const showErrorOverlay = (err: any) => {
  console.error('error', err);
};

import.meta.hot?.on('vite:error', showErrorOverlay);

PragmaticLive.connect();
