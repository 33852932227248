import { takeEvery, put } from 'redux-saga/effects';
import openSocket from 'socket.io-client';
import axios from 'axios';
import pako from 'pako';
import getStore from '../../store';
import { isArray, set } from 'lodash-es';
import { liveConstants, digitainConstants } from '../actions/constants';
import {
  digitainSetState,
  digitainMergeQueue,
  digitainQueueUpdates,
  digitainClearQueue,
  digitainLiveUpdates,
  digitainPrematchUpdates,
  digitainSetProgressiveState,
  digitainSetBetsCacheKey,
} from '../actions/live_digitain';
import { prematchFetchMatches } from '../actions/prematch';
import { liveSetMatch, liveSetMatches } from '../actions/live';
import { configUpdateDayMultiBets } from '../actions/config';

import { betsSlipLiveCreateTicketSuccess, betsSlipLiveCreateTicketError, betsSlipLoad } from '../actions/betsSlip';

import { appSaveErrorLog } from '../actions/app';
import { application as casinoAppConstants } from '../../../casino/store/actions/actionTypes';

import { normalizeDigitainLiveTickets } from '../../utils/normalizeDigitainTickets';
import { ticketStoreOpen, ticketWinnerFunStoreOpen, ticketWinnerFunStoreOpenPrepare } from '../actions/tickets';

import { getBetsState } from '../selectors/betData';
import { debug, uuidv4 } from '../../utils';

import { unstable_trace as trace } from 'scheduler/tracing';

import { digitainConfig, digitainWinnerFunConfig } from '../../api/config/digitain';

import { requestWallet } from '../../../casino/store/actions/wallet';

import { activeWinnerFunCampaigns } from '../../utils/winnerFunUtils';

import { getLanguage } from '../../../utils/i18n';

import * as prematch from './digitain/prematch';
import { logPlaceTicketEvent } from '@/analytics';

//const wsUrl = "https://sportsbook-public-api-staging.nsoft.com";
//const ticketsUrl = "https://services-staging.7platform.com";
//const cpv = "3657d0f3-f54d-4dc8-9077-578940875c8d";
//const productName = "LiveBetting";
//const socketCompanyUuid = "b99752b3-443c-4c80-b559-945a95c4b805";
//const companyUuid = "28ec1e93-68f0-49fd-9ab5-639d88169625";
//const product = "live";
//const protocol = "sio1";
//const productInstanceId = 1017294;
//const productInstanceUuid = "8a3f73e2-0a45-42d5-9488-9b6ec164923a";

// live
//const ticketsUrl = "https://services-ro2.7platform.com";
//const wsUrl = "https://sportsbook-public-api.nsoft.com";
//const cpv = "d8d00130-5509-4e9c-b2b9-94af43812fc8";
//const companyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";

let socket = null;
// let mergeInterval = 0;
// let keepAliveInterval = 0;
let pendingTickets = {};
let liveUpdates = [];
let prematchUpdates = [];

const DEBUG = false;

export const connect = () => {
  const bst = getBetsState(getStore().getState());

  // const { auth, app } = bst;

  debug('********** connect digitain live socket');

  if (socket !== null) {
    // check if are connected where we need
    // if (socket.isWinnerFun === app.isWinnerFun) return;

    debug('********** clearing previous digitain live socket');

    // clear previous connection
    socket.disconnect();
    socket = null;

    // clearInterval(mergeInterval);
    // mergeInterval = 0;

    // clearInterval(keepAliveInterval);
    // keepAliveInterval = 0;

    // getStore().dispatch(digitainClearQueue());

    liveUpdates = [];
    prematchUpdates = [];
  }

  /*
  mergeInterval = setInterval(() => {
    trace("merge digitain queues", performance.now(), () => {
      // return getStore().dispatch(digitainMergeQueue());
      if (liveUpdates.length > 0) {
        getStore().dispatch(digitainLiveUpdates(liveUpdates));
        liveUpdates = [];
      }
      if (prematchUpdates.length > 0) {
        getStore().dispatch(digitainPrematchUpdates(prematchUpdates));
        prematchUpdates = [];
      }
    });
  }, 10000);
  */

  let digitain = digitainConfig();
  // let authDetails = auth.details;

  // if (app.isWinnerFun) {
  //   digitain = digitainWinnerFunConfig();
  //   authDetails = auth.winnerFunDetails;
  // }

  debug(`wsUrl = ${digitain.wsUrl}`);

  const query = {};

  if (window && window.config) {
    if (window.config.oneMarketState === '1') {
      query.one_market = true;
    }

    if (window.config.progressiveLiveState === '1') {
      query.progressive = true;
    }

    if (window.config.betsCacheKey === '1') {
      query.cacheKey = bst.live.betsCacheKey;
    }
  }

  socket = openSocket(digitain.wsUrl, {
    path: digitain.wsPath,
    forceNew: true,
    transports: ['websocket'],
    query,
  });

  // socket.isWinnerFun = app.isWinnerFun;

  //debug("socket", socket);

  // const keepAlive = () => {
  //   DEBUG && debug('Digitain socket keepalive', new Date());
  //   socket.emit('message', {
  //     type: 'serviceAlive',
  //   });
  // };

  // const subscribe = () => {
  //   debug('Digitain socket subscribing...');

  //   const bst = getBetsState(getStore().getState());

  //   // socket.emit("subscribe", {
  //   // 	language: getLanguage(),
  //   // 	deliveryPlatform: "Web",
  //   // 	encoding: "deflate",
  //   // 	subscribeMode: "topMatchBet",
  //   // 	subscribeOptions: {
  //   // 		autoSubscribe: true,
  //   // 		betCount: 10,
  //   // 		excludeMeta: false,
  //   // 		fullBetMeta: true,
  //   // 		subscribeMatches: true,
  //   // 		excludeBetGroups: false,
  //   // 		betsCacheKey: bst.live.betsCacheKey,
  //   // 		betGroupsCacheKey: bst.live.betGroupsCacheKey
  //   // 	}
  //   // });

  //   if (authDetails !== null) {
  //     socket.emit('message', {
  //       type: 'login',
  //       data: {
  //         uuid: authDetails.Uuid,
  //       },
  //     });
  // 	}

  //   if (keepAliveInterval !== 0) clearInterval(keepAliveInterval);
  //   keepAliveInterval = setInterval(keepAlive, 10000);
  // };

  socket.on('connect', () => {
    debug('Digitain socket connected');

    // subscribe();
  });

  socket.on('event', function (data) {
    //debug("Socket event data", data);
  });

  socket.on('error', (error) => {
    debug('Digitain socket error', error);
    //socket.close();
    //setTimeout(connect, 0);
  });

  socket.on('connect_error', (error) => {
    debug('Digitain socket connect error', error);
    //socket.close();
    //setTimeout(connect, 0);
  });

  //const worker = new Worker(new URL('./digitain/live.js', import.meta.url), { type: 'module' });

  socket.on('message', (message) => {
    const st = performance.now();

    /*
    if (message && message.compressed && message.type === 'state') {
      worker.onmessage = e => {
        console.log('worker message', e);
        const data = e.data;
        getStore().dispatch({ type: 'DIGITAIN_LIVE_SET_PARSED_STATE', data: data });
      };
      worker.postMessage(message.data);
      return;
    }
    */

    // debug("**** digitain message", message);

    let msg;

    if (message && message.compressed) {
      try {
        msg = JSON.parse(pako.inflate(message.data, { to: 'string' }));
      } catch (e) {
        console.error('failed to inflate', e);
      }
    } else {
      msg = message.data;
    }

    if (msg === null) {
      return;
    }

    let logMessage = true;

    switch (message.type) {
      case 'state':
        for (const mId in msg?.matches) {
          if (window.config.oneMarketState === '1') {
            // mark matches as not loaded (as in they have only one market in them)
            msg.matches[mId]._loaded = false;
          } else {
            msg.matches[mId]._loaded = true;
          }
        }

        // debug("set state", msg);
        getStore().dispatch(digitainSetState(msg));
        // getStore().dispatch(betsSlipLoad());
        break;
      case 'update':
        // getStore().dispatch(digitainQueueUpdates(msg));
        break;
      default:
        debug('message not handled', message);
    }

    if (logMessage) {
      // debug("message handled", message);
    }

    debug('state took', performance.now() - st);

    if (message.betsCacheKey) {
      getStore().dispatch(digitainSetBetsCacheKey(message.betsCacheKey));
    }
  });

  socket.on('update', (data) => {
    // debug("update", data);
    // getStore().dispatch(digitainQueueUpdates(data));
    if (data.mType) {
      if (data.mType === 'live') {
        liveUpdates.push(data);
      } else if (data.mType === 'prematch') {
        prematchUpdates.push(data);
      } else {
        console.error('unknown update mType', data);
      }
    } else {
      liveUpdates.push(data);
      prematchUpdates.push(data);
    }
  });

  let time = 0;

  socket.on('cupdates', (data) => {
    const st = performance.now();

    let updates = [];
    const now = Date.now();

    try {
      updates = JSON.parse(pako.inflate(data, { to: 'string' }));
    } catch (e) {
      console.error('failed to inflate', e);
    }

    //debug('cupdates', updates);

    if (updates) {
      for (const update of updates) {
        if (update.mType) {
          if (update.mType === 'live') {
            liveUpdates.push(update);
          } else if (update.mType === 'prematch') {
            prematchUpdates.push(update);
          } else {
            console.error('unknown update mType', update);
          }
        } else {
          liveUpdates.push(update);
          prematchUpdates.push(update);
        }
      }
    }

    if (now - time < 3000 && !(location.pathname.startsWith('/bets/') || location.pathname.startsWith('/winnerfun/'))) {
      return;
    }

    if (liveUpdates.length > 0) {
      getStore().dispatch(digitainLiveUpdates(liveUpdates));
      liveUpdates = [];
    }
    if (prematchUpdates.length > 0) {
      getStore().dispatch(digitainPrematchUpdates(prematchUpdates));
      prematchUpdates = [];
    }

    DEBUG && debug('cupdates took', performance.now() - st, time - now, 'ms');
    time = now;
  });

  // day multi bet update
  socket.on('dmbUpdate', (data) => {
    debug('dmbUpdate', data);

    if (data && Array.isArray(data)) {
      debug('collect events for DMB update');

      // collect events ids
      const eventsToLoad = {};
      for (const dmb of data) {
        for (const st of dmb.stakes) {
          eventsToLoad[st.event_id] = true;
        }
      }

      debug('events to load', eventsToLoad);

      // check which events are not present and fully loaded
      const p = getBetsState(getStore().getState());

      if (!(p && p.prematch && p.prematch.matches)) {
        debug('no prematch matches');
      } else {
        for (const eventId of Object.keys(eventsToLoad)) {
          if (eventId in p.prematch.matches && p.prematch.matches[eventId]['_loaded']) {
            debug(`event ${eventId} is loaded`);
            // event is loaded
            delete eventsToLoad[eventId];
          }
        }
      }

      const evs = Object.keys(eventsToLoad);

      debug('events left to load', evs);

      if (evs.length > 0) {
        // load events
        getStore().dispatch(prematchFetchMatches(evs));
      }

      // set new DMBs
      getStore().dispatch(configUpdateDayMultiBets(data));
    }
  });

  // progressive state
  socket.on('pstate', (message) => {
    let data = message.data;

    if (message && message.compressed) {
      try {
        data = JSON.parse(pako.inflate(message.data, { to: 'string' }));
      } catch (e) {
        console.error('failed to inflate', e);
      }
    }

    if (data === null) {
      return;
    }

    //console.log('set pstate', data);
    for (const mId in data?.matches) {
      if (window.config.oneMarketState === '1') {
        // mark matches as not loaded (as in they have only one market in them)
        data.matches[mId]._loaded = false;
      } else {
        data.matches[mId]._loaded = true;
      }
    }

    getStore().dispatch(digitainSetProgressiveState(data, message.reset, message.last));

    if (message.betsCacheKey) {
      getStore().dispatch(digitainSetBetsCacheKey(message.betsCacheKey));
    }
  });
};

const handleTicketUpdate = (data) => {
  //debug("handle ticket update", data);

  const bst = getBetsState(getStore().getState());

  if (data.status.value === 'PENDING') {
    debug('need to wait some more', data);
    return;
  }

  if (data.status.value === 'OPEN') {
    getStore().dispatch(betsSlipLiveCreateTicketSuccess(''));

    const newTicket = normalizeDigitainLiveTickets([data], bst);

    if (bst.app.isWinnerFun) {
      getStore().dispatch(ticketWinnerFunStoreOpenPrepare(newTicket));
    } else {
      getStore().dispatch(ticketStoreOpen(newTicket));
    }
  } else {
    debug('error creating live ticket', data);

    // t("Error creating live ticket")
    let err = 'Error creating live ticket';

    if (data.message) {
      err = data.message;
    }

    let details = [];

    if (data.details && isArray(data.details)) {
      details = data.details.map((d) => d.message);
    }

    getStore().dispatch(betsSlipLiveCreateTicketError(err, details));
    getStore().dispatch(appSaveErrorLog('', 'Error creating live ticket', JSON.stringify(data)));
  }

  const requestUuid = data.requestUuid;

  if (requestUuid in pendingTickets) {
    //debug("clear pending interval");
    clearInterval(pendingTickets[requestUuid]);
    delete pendingTickets[requestUuid];
  }

  //getStore().dispatch(requestWallet());
};

export const liveDisconnect = () => {
  return;
  /*
  if (socket !== null) {
    socket.disconnect();
    socket = null;

    clearInterval(mergeInterval);
    mergeInterval = 0;

    clearInterval(keepAliveInterval);
    keepAliveInterval = 0;
  }
  */
};

function* liveInitializeSaga() {
  yield connect();
}

function* liveCreateTicketSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);

  const { betsSlip, app, config } = getBetsState(state);

  logPlaceTicketEvent('Placing Ticket', betsSlip);

  let digitain = digitainConfig();
  // let authDetails = auth.details;

  if (app.isWinnerFun) {
    digitain = digitainWinnerFunConfig();
    // authDetails = auth.winnerFunDetails;
  }

  const ct = betsSlip.tickets[betsSlip.currentTicket];
  const bsLive = ct.live;
  const bsPrematch = ct.prematch;
  const isWinnerFun = app.isWinnerFun ? true : false;
  let winnerFunData = activeWinnerFunCampaigns(config.winnerFunCampaigns, true);
  const free_bet_code = action.free_bet_code ? action.free_bet_code : null;
  const free_bet_name = action.free_bet_name ? action.free_bet_name : null;
  const free_bet_pid = action.free_bet_pid ? action.free_bet_pid : null;
  const free_bet_redeemable = action.free_bet_redeemable ? action.free_bet_redeemable : null;

  if (ct.useWinnerFunBoost) {
    winnerFunData['use_boost'] = true;
  }

  //debug("live create bsLive", bsLive);

  if (bsLive.selected.length === 0 && bsPrematch.selected.length === 0) {
    yield put(betsSlipLiveCreateTicketSuccess(''));
    return;
  }

  debug('state.authentication', state.authentication);

  if (!(state.authentication && ['user', 'token'].indexOf(state.authentication.auth_type) > -1)) {
    // t("You are not authenticated")
    yield put(betsSlipLiveCreateTicketError('You are not authenticated'));
    return;
  }

  let bankers = 0;

  const findOdd = (s) => {
    if (!bst) return;

    for (const mType of ['live', 'prematch']) {
      if (bst[mType] && bst[mType].matches && bst[mType].matches[s.idMatch]) {
        let m = bst[mType].matches[s.idMatch];

        if (s.match.idMatch !== s.idMatch) {
          let found = false;

          // we have a period. get the right match
          if (m && m.periods && Array.isArray(m.periods)) {
            for (const p of m.periods) {
              if (p.idMatch === s.match.idMatch) {
                m = p;
                found = true;
                break;
              }
            }
          }

          if (!found) {
            debug(`findOdd failed to find period ${s.match.idMatch} in match ${s.idMatch} in ${mType}`);
            continue;
          }
        }

        debug(`findOdd found match ${s.idMatch} in ${mType}`);

        const mb = m.matchBets.find((mb) => mb.idBet === s.idBet);

        if (mb) {
          debug(`findOdd found match bet ${s.idBet} in ${mType}/${s.idMatch}`);

          const mbo = mb.mbOutcomes.find((mbo) => mbo.idMbo === s.idMbo);

          if (mbo) {
            debug(`findOdd found match bet outcome ${s.idMbo} in ${mType}/${s.idMatch}/${s.idBet}`);

            return mbo;
          } else {
            debug(`findOdd failed to find match bet outcome ${s.idMbo} in ${mType}/${s.idMatch}/${s.idBet}`);
          }
        } else {
          debug(`findOdd failed to find match bet ${s.idBet} in ${mType}/${s.idMatch}`);
        }
      } else {
        debug(`findOdd failed to find match ${s.idMatch} in ${mType}`);
      }
    }

    return null;
  };

  const ticketBetsLive = bsLive.selected.reduce((acc, s) => {
    if (!s.valid) {
      return acc;
    }

    if (s.fixed) {
      bankers++;
    }

    let isLive = true;

    if (s.betType === 'betBuilder') {
      return acc.concat({
        eventId: s.idMatch,
        isLive,
        ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
        ticketBetWay: 0,
        mboOddValue: s.odd,
        betType: 'betBuilder',
        stakes: s.bb.bets,
      });
    }

    const mbo = findOdd(s);
    if (!mbo) {
      return acc;
    }
    if (mbo) {
      isLive = mbo.isLive;
    }

    return acc.concat({
      stakeId: s.idMbo,
      eventId: mbo.idMatch,
      isLive,
      ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
      ticketBetWay: 0,
      mboOddValue: s.odd,
      mbo,
    });
  }, []);

  const ticketBetsPrematch = bsPrematch.selected.reduce((acc, s) => {
    if (!s.valid) {
      return acc;
    }

    if (s.fixed) {
      bankers++;
    }

    let isLive = false;

    if (s.betType === 'betBuilder') {
      return acc.concat({
        eventId: s.idMatch,
        isLive,
        ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
        ticketBetWay: 0,
        mboOddValue: s.odd,
        betType: 'betBuilder',
        stakes: s.bb.bets,
      });
    }

    const mbo = findOdd(s);
    if (!mbo) {
      return acc;
    }
    if (mbo) {
      isLive = mbo.isLive;
    }

    return acc.concat({
      stakeId: s.idMbo,
      eventId: mbo.idMatch,
      isLive,
      ticketBetBank: ct.ticketType !== 'system' ? 1 : s.fixed ? 1 : 0,
      ticketBetWay: 0,
      mboOddValue: s.odd,
      mbo,
    });
  }, []);

  const ticketBets = ticketBetsLive.concat(ticketBetsPrematch);

  if (ticketBets.length === 0) {
    yield put(betsSlipLiveCreateTicketError('No valid bets on ticket'));
    return;
  }

  // const ticketType = ct.ticketType === "system" ? 2 : 1;

  // /*
  // let ticketCombinationGroups = [
  // 	{
  // 		parlays: 1,
  // 		events: ticketBets.length
  // 	}
  // ];
  // */
  let ticketCombinationGroups = [];

  if (ct.ticketType === 'system') {
    ticketCombinationGroups = [];

    bsLive.systems.forEach((sy) => {
      ticketCombinationGroups.push({
        parlays: sy,
        events: ticketBets.length - bankers,
      });
    });
  }

  const requestUuid = uuidv4();

  const createReq = {
    ticket: {
      bets: ticketBets,
      payin: ct.amount,
      ticketComment: app.isWinnerFun ? 'winner_fun' : 'winner',
      ticketType: ct.ticketType,
      ticketSystems: ct.systems,
      // ticketCombinationGroups: ticketCombinationGroups,
      autoAcceptOddChange: ct.autoAcceptOddChange ? true : false,
    },
  };

  if (ct.dayMultiBetNumber && ct.ticketType === 'single') {
    createReq.ticket.dayMultiBetNumber = ct.dayMultiBetNumber;
  }
  if (ct.shareTicketNumber) {
    createReq.ticket.shareTicketNumber = ct.shareTicketNumber;
  }

  if (isWinnerFun) {
    createReq.clientVal = JSON.stringify(winnerFunData);
  } else {
    if (free_bet_code) {
      createReq.clientVal = JSON.stringify({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    }
  }

  createReq.lang = getLanguage();

  console.log('ticket create request', createReq);

  try {
    // yield put(betsSlipLiveCreateTicketPending(betsSlip.currentTicket, requestUuid));
    // pendingTickets[requestUuid] = ptid;

    const result = yield axios.post(digitain.ticketsUrl + '/place', createReq, {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });

    debug('live ticket create result', result);

    yield put(betsSlipLiveCreateTicketSuccess(requestUuid));

    // log bet event
    logPlaceTicketEvent('Ticket placed successfully', betsSlip, result?.data?.serial);

    const newTicket = normalizeDigitainLiveTickets([result.data], bst);

    if (bst.app.isWinnerFun) {
      yield put(ticketWinnerFunStoreOpenPrepare(newTicket));
    } else {
      yield put(ticketStoreOpen(newTicket));
    }
  } catch (e) {
    //debug("live ticket error", e);
    yield put(appSaveErrorLog(JSON.stringify(createReq), e.toString(), JSON.stringify(e)));

    //let err = e.toString();
    let err = 'An error occurred: Error creating ticket';
    let details = [];

    logPlaceTicketEvent('Error placing ticket', betsSlip);

    if (e.response) {
      debug('error response', e.response);
      /*
      {
          "type": "place_status_exception",
          "data": {
              "Limit": 0,
              "MinAmount": 0,
              "MaxAmount": 0,
              "ErrorCode": 131,
              "ErrorMessage": "BetBlockedForPrematch"
          }
      }
      */

      if (e.response.data && e.response.data.type && e.response.data.type === 'place_status_exception') {
        details = [e.response.data.data];
      }
    }

    yield put(betsSlipLiveCreateTicketError(err, details));
  }
}

const checkTicket = (nsoft, authDetails, requestUuid) => {
  axios
    .get(nsoft.ticketsUrl + '/web/tickets/request/' + requestUuid + '/product/' + nsoft.productName + '/check.json', {
      headers: {
        Authorization: 'Bearer ' + authDetails.accessToken,
        'SEVEN-TP-TOKEN': authDetails.tpToken,
        //companyUuid: ticketCompanyUuid,
        'SEVEN-LOCALE': getLanguage(),
        'HTTP-X-NAB-DP': 'Web',
        'HTTP-X-SEVEN-CLUB-UUID': nsoft.companyUuid,
        'HTTP-X-NAB-PRODUCTNAME': nsoft.productName,
        'HTTP-X-NAB-PRODUCTINSTANCE-ID': nsoft.productInstanceUuid,
      },
    })
    .then(res => {
      //debug("live check ticket result", res);

      /*
      if (res.data.status.value === "OPEN") {
        getStore().dispatch(betsSlipLiveCreateTicketSuccess(""));
        getStore().dispatch(ticketStoreOpen(normalizeLiveTickets([res.data])));
      } else {
        getStore().dispatch(betsSlipLiveCreateTicketError("Error creating live ticket"));
      }

      if (requestUuid in pendingTickets) {
        debug("clear pending interval");
        clearInterval(pendingTickets[requestUuid]);
        delete pendingTickets[requestUuid];
      } else {
        debug("pending interval not found", requestUuid);
      }
      */
      handleTicketUpdate(res.data);
    })
    .catch(e => {
      //debug("live check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ requestUuid }), 'Error checking live ticket', JSON.stringify(e))
      );
    });
};

function* livePlayerLoginSaga(action) {
  //debug("action", action, socket);

  const state = getStore().getState();

  const { auth, app } = getBetsState(state);

  let authDetails = auth.details;

  if (app.isWinnerFun) {
    authDetails = auth.winnerFunDetails;
  }

  if (socket !== null) {
    // authenticate websocket
    yield socket.emit('message', {
      type: 'login',
      data: {
        uuid: authDetails.Uuid,
      },
    });
  }

  /*
  // request bonus settings
  const { nsoft } = getBetsState(getStore().getState());

  debug("nsoft", nsoft);

  try {
    const { data } = yield axios.get(nsoft.config.bonusConfigUrl, {
      params: {
        cpvUuid: nSoftLive.cpvUuid,
        playerUuid: action.uuid
      }
    });

    debug("bonus data", data);

    getStore().dispatch(nSoftSetBonus(data));
  } catch (e) {
    debug("failed to load nsoft bonus", e);
  }
  */
}

function* livePlayerLogoutSaga() {
  if (socket !== null) {
    // authenticate websocket
    yield socket.emit('message', {
      type: 'logout',
    });
  }
}

function* fetchEventSaga(action) {
  debug('fetch event', action.idMatch);

  const { data } = yield prematch.fetchEventsById(null, action.idMatch, 'live');

  debug(`fetch event ${action.idMatch}`, data.data.events[0]);

  if (data?.data?.events?.length) {
    data.data.events[0]['_loaded'] = true;
    yield put(liveSetMatch(data.data.events[0]));
  }
}

function* fetchEventsSaga(action) {
  debug(`fetch events with idRequest ${action.idRequest}`, action.idMatch);

  try {
    let matchData = [];

    if (action.idMatch && Array.isArray(action.idMatch) && action.idMatch.length > 0) {
      let marketIds = null;
      if (action.marketIds && Array.isArray(action.marketIds) && action.marketIds.length > 0) {
        marketIds = action.marketIds;
      }

      const { data } = yield prematch.fetchEventsById(null, action.idMatch, 'live', action.marketCount, marketIds);
      matchData = data.data.events;
    }

    for (const m of matchData) {
      m['_loaded'] = !action.marketCount && !action.marketIds ? true : false;
    }

    yield put(liveSetMatches(matchData, action.idRequest));
    action.cb?.(matchData, action.cbData);
  } catch (e) {
    console.error('failed to fetch live events', action.idMatch, e);
  }
}

export default function* watchLiveSaga() {
  //debug("watching live");
  yield takeEvery(liveConstants.INITIALIZE, liveInitializeSaga);
  yield takeEvery(casinoAppConstants.REINITIALIZE, liveInitializeSaga);
  yield takeEvery(liveConstants.CREATE_TICKET_REQUEST, liveCreateTicketSaga);
  yield takeEvery(digitainConstants.PLAYER_LOGIN, livePlayerLoginSaga);
  yield takeEvery(digitainConstants.PLAYER_LOGOUT, livePlayerLogoutSaga);
  yield takeEvery(liveConstants.FETCH_MATCH, fetchEventSaga);
  yield takeEvery(liveConstants.FETCH_MATCHES, fetchEventsSaga);
}
