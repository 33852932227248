import React from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './error.scss';
import WarningIcon from '../assets/icon-warning@3x.png';
import CloseIcon from '../assets/close.svg';

const Error = (props) => {
  const { t, onTryAgain } = props;

  const navigate = useNavigate();

  const handleTryAgain = () => {
    if (typeof onTryAgain === 'function') onTryAgain();
  };

  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className="wheel-error-message">
      <div className="warning-header" onClick={handleClose}>
        <div className="close-icon">
          <img src={CloseIcon} alt="" />
        </div>
      </div>
      <img src={WarningIcon} alt="" className="warning-icon" />
      <div className="warning-title">
        {t('There has been an error')}
      </div>
      <div className="warning-body">
        {t('I was unable to contact {{brand}} Wheel.', { brand: window.config.brandNameShort })}
        <br /><br />
        {t('Most likely this is a temporary problem. Please try again later.')}
      </div>
      <div className="warning-button" onClick={handleTryAgain}>
        {t('Try again')}
      </div>
    </div>
  );
};

export default withTranslation()(Error);