import React from 'react';
import {
  createBrowserRouter,
  Link,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
  redirect,
  useNavigate,
} from 'react-router-dom';
import PageLayout from './components/page-layout/page-layout';
import Page from './components/page/page';
import { useAppSelector } from './store';
import PageLoader from './components/page-loader';
import ActionUIHandler from './components/action-handler';
import AccountProblem from './components/modules/account-problem';
import Notifications from './components/notification';
import Popups from './components/popups/popups';
import RouteChangeHandler from './components/route-change-handler';
import AdminCustomization from './components/admin-customization';
import LiveAgent from '@/components/live-agent';
import ZendeskChatAgent from '@/components/zendesk-chat';
import ZohoChatAgent from '@/components/zoho-chat';
import SeamlessLogin from '@/components/modules/login/seamless-login';
import { useTranslation } from 'react-i18next';

type BaseElementProps = {
  children?: any;
};

const BaseElement = (props: BaseElementProps) => {
  const isLiveAgentEnabled = window.config.customerSupport?.liveChatEnabled === 'liveAgent';
  const isZendeskChatEnabled = window.config.customerSupport?.liveChatEnabled === 'zendesk';
  const isZohoChatEnabled = window.config.customerSupport?.liveChatEnabled === 'zoho';

  const { i18n } = useTranslation();

  React.useEffect(() => {
    const toggleTranslationKeys = (event: KeyboardEvent) => {
      // Ctrl/CMD + Shift + L
      if ((event.metaKey || event.ctrlKey) && event.shiftKey && event.key?.toLowerCase() === 'l') {
        const oldLanguage = i18n.language;
        i18n.changeLanguage('cimode');
        setTimeout(() => {
            i18n.changeLanguage(oldLanguage);
        }, 5000);
      }
    };
    // prevent multiple event listeners
    window.removeEventListener('keydown', toggleTranslationKeys);
    window.addEventListener('keydown', toggleTranslationKeys);
    return () => {
      window.removeEventListener('keydown', toggleTranslationKeys);
    };
  }, []);

  return (
    <React.Fragment>
      <Outlet />
      <AccountProblem />
      <ActionUIHandler />
      <Notifications />
      {isLiveAgentEnabled && <LiveAgent />}
      {isZendeskChatEnabled && <ZendeskChatAgent />}
      {isZohoChatEnabled && <ZohoChatAgent />}
      <ScrollRestoration />
      <Popups />
      <RouteChangeHandler />
      {window !== window.parent && <AdminCustomization />}
      <SeamlessLogin />
    </React.Fragment>
  );
};

const ErrorElement = (props: any) => {
  console.error('ErrorElement', props);
  const location = useLocation();
  console.log(location);
  return (
    <div className="d-flex flex-column align-items-center mt-4 justify-content-start w-100 h-100">
      <div>500. That&apos;s an error.</div>
      <div>The requested URL {location.pathname} was not found on this server or something is wrong with it.</div>
      <Link to="/">Go Home</Link>
    </div>
  );
};

export let storedRouter: any = null;

const Redirect = ({ url }: { url: string }) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(url, {
      replace: true,
    });
  }, []);
  return null;
};
// @ts-ignore
const RedirectMemo = React.memo(Redirect);

const Routes: React.FC = () => {
  const config = useAppSelector((state) => state.templatesConfig);

  if (!config.loaded) {
    return <PageLoader />;
  }

  const routes: any[] = [];
  Object.keys(config.setPages).forEach((url) => {
    if (url === '*') return;
    const page = config.setPages[url];

    if (page.page_layout_id) {
      routes.push({
        path: url,
        element: <PageLayout id={page.page_layout_id} pageId={page.page_id} />,
        errorElement: <ErrorElement />,
      });

      return;
    }

    routes.push({
      path: url,
      element: <Page id={page.page_id} />,
      errorElement: <ErrorElement />,
    });
  });

  if (config.reroute) {
    try {
      const extraRouting: any = JSON.parse(config.reroute);

      Object.keys(extraRouting).forEach((k: any) => {
        const destUrl = typeof extraRouting[k] === 'string' ? extraRouting[k] : extraRouting[k]?.dest;

        if (destUrl && destUrl != '*' && extraRouting[k]?.route !== false) {
          if (config.setPages[destUrl]) {
            const page = config.setPages[destUrl];

            if (page.page_layout_id) {
              routes.push({
                path: k,
                element: <PageLayout id={page.page_layout_id} pageId={page.page_id} />,
                errorElement: <ErrorElement />,
              });

              return;
            }

            routes.push({
              path: k,
              element: <Page id={page.page_id} />,
              errorElement: <ErrorElement />,
            });
          } else {
            routes.push({
              path: k,
              element: <RedirectMemo url={destUrl} />,
              errorElement: <ErrorElement />,
            });
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  if (typeof config.setPages['*'] !== 'undefined') {
    // we have a global page handler
    const page = config.setPages['*'];

    if (page.page_layout_id) {
      routes.push({
        path: '*',
        element: <PageLayout id={page.page_layout_id} pageId={page.page_id} />,
        errorElement: <ErrorElement />,
      });
    } else {
      routes.push({
        path: '*',
        element: <Page id={page.page_id} />,
        errorElement: <ErrorElement />,
      });
    }
  }

  const router = createBrowserRouter([
    {
      element: <BaseElement />,
      children: routes,
    },
  ]);

  storedRouter = router;

  return <RouterProvider router={router} />;
};

export default Routes;
